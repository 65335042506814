import { Divider, Button, Input, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PaperClipOutlined } from '@ant-design/icons';
import CommentServices from "../../../../services/services/CommentServices";
const { TextArea } = Input;
// const dummyData = [
//   {
//     name: "Marvin Mckinney",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   },
//   {
//     name: "Marvin Mckinney 1",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   },
//   {
//     name: "Marvin Mckinney 2",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   },
//   {
//     name: "Marvin Mckinney 3",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   },
//   {
//     name: "Marvin Mckinney 4",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   },
//   {
//     name: "Marvin Mckinney 5",
//     updatedAt: "03 Apr,2024, 14:30:25 PM",
//     comment:
//       "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
//   }
// ];

const CommentList = ({ setActiveTabKey, refreshData, ...props }) => {

  const [data, setData] = useState([])
  const [activeReply, setActiveReply] = useState(null);

  const getData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}comment?modulename=${props?.moduleName}&moduleid=${props?.id}`, {
        method: 'GET'
      });
      const res = await response.json();
      console.log('testing comment tab data', res);

      setData(res)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData()
    // console.log('testing refresh data', refreshData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData()
  }, [refreshData])

  const fileName = (url) => {
    const urls = new URL(url);
    return urls?.pathname.split('/').pop()
  }


  const Row = ({ item, isFirst, isLast, key, activeReply, setActiveReply, ...props }) => {
    const handleReplyClick = () => {
      setActiveReply(activeReply === item.id ? null : item.id);
    };

    function convertToLocalTime(savedTime) {
      // Create a new Date object from the saved time
      const date = new Date(savedTime);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  
      // Convert to local time using toLocaleString
      return date.toLocaleString('en-US', { timeZone: timeZone });
  }

    return (
      <div style={{padding:"0px 10px"}}>
        <div
          key={key}
          className={`flex flex-col items-start bg-[#f7f7f7] p-2 mb-2 gap-1  ${isFirst ? "mt-4" : "mt-3"
            }`}
        >
          <div className="flex items-start justify-center">
            <div style={{ fontFamily: 'sans-serif' }} className="text-sm font-bold">{item.username}</div>
            <div>
              <Divider type="vertical" className="bg-[#e3e3e3]" />
            </div>
            <div className="text-sm font-normal">{convertToLocalTime(item.updatedAt)}</div>
          </div>
          <p className="mt-2 overflow-auto text-wrap">{item.comment}</p>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 20, marginBottom: 10, marginTop: 5 }}>
            {item?.urls?.map((urlItem, index) => (
              <Link key={index} to={urlItem} >
                <PaperClipOutlined /><a style={{ color: '#2a609d' }}> {fileName(urlItem)}</a>
              </Link>


            ))}

          </div>
          <div className="w-full">
            {item.children.map((val, index) => (
              <RowNew item={val} key={index} isFirst={index === 0} />
            ))}
          </div>

          <Button className="h-auto w-26 mr-2" size="small" onClick={handleReplyClick}>Reply</Button>
        </div>
        {activeReply === item.id && <ReplyArea parentId={item.id} {...props} setActiveReply={setActiveReply}/>}
      </div>
    );
  };

  const RowNew = ({ item, key, isFirst }) => {

    function convertToLocalTime(savedTime) {
      // Create a new Date object from the saved time
      const date = new Date(savedTime);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  
      // Convert to local time using toLocaleString
      return date.toLocaleString('en-US', { timeZone: timeZone });
  }

    return (
      <div
        key={key}
        className={`flex flex-col items-start bg-[#f7f7f7] p-2 mb-2 gap-1}`}
        style={{marginLeft:50}}
      >
        <div className="flex items-start justify-center">
          <div style={{ fontFamily: 'sans-serif' }} className="text-sm font-bold">{item?.username}</div>
          <div>
            <Divider type="vertical" className="bg-[#e3e3e3]" />
          </div>
          <div className="text-sm font-normal">{convertToLocalTime(item.updatedAt)}</div>
        </div>
        <p className="mt-2 overflow-auto text-wrap">{item?.comment}</p>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 20, marginBottom: 10, marginTop: 5 }}>
          {item?.urls?.map((urlItem, index) => (
            <Link key={index} to={urlItem} >
              <PaperClipOutlined /><a style={{ color: '#2a609d' }}> {fileName(urlItem)}</a>
            </Link>


          ))}
        </div>
      </div>
    );
  };



  const ReplyArea = ({ parentId,setActiveReply, ...props }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [comments, setComments] = useState('');
    const [replyComment, setReplycomment] = useState("")
    useEffect(() => {
      console.log('testing ...props1>>>>', props?.moduleName);
      const timezoneOffset = (new Date()).getTimezoneOffset();
    console.log("testing timezone");

    }, [props])

    const propss = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);
        return false;
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        message.loading(`Uploading... ${percent}%`, 0);
      },
      fileList,
    };

    const handleUpload = async () => {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('image', file);
      });
      setUploading(true);
      console.log('testing formdata', formData);

      if(replyComment.length === 0){
        message.error("Please Enter Comment")
        setUploading(false)
        return;
      }

      try {
        let res = []
        if(fileList.length>0){
        const response = await fetch(`${process.env.REACT_APP_API_URL}etl/uploadMedia`, {
          method: 'POST',
          body: formData,
        });
        res = await response.json();
      }
        
        let userName = await localStorage.getItem("userData")
        userName = JSON.parse(userName)
        let obj = {
          "username": userName?.name,
          "comment": replyComment,
          "urls": res?.blobUrls || [],
          'parentid': parentId,
          'modulename': props?.moduleName,
          'moduleid': props?.id
        };
        await CommentServices.create(obj);
        console.log('testing uploaded urls', res?.blobUrls);
        message.success('upload successfully.');
        setFileList([]);
        setReplycomment("")
      } catch (error) {
        message.error('upload failed.');
      } finally {
        setUploading(false);
        getData()
        setActiveReply(null)
      }
    };

    useEffect(() => {
      const elements = document.getElementsByClassName('ant-upload-list')[1];
      elements.classList.add('ant-upload-list-two');

      //const customDiv = `<div>Uploaded Files</div> ` 
      const customDiv = document.createElement('div');
      customDiv.className = 'custom-upload-header1'; // Add a custom class for additional styling if needed

      // Create and add the icon
      const icon = document.createElement('span');
      icon.innerHTML = '<svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>'; // Example of directly using SVG; replace with your icon code
      icon.style.marginRight = '8px'; // Space between icon and text

      const textSpan = document.createElement('span');
      textSpan.textContent = 'Uploaded Files:';
      textSpan.style.fontWeight = 'bold'; // Make the text bold
      textSpan.style.fontFamily = 'sans-serif'

      // Append the icon and text to the div
      customDiv.appendChild(icon);
      customDiv.appendChild(textSpan);

      // Find the upload list element
      const uploadList = document.getElementsByClassName('ant-upload-list')[1];
      if (!document.querySelector('.custom-upload-header1')) {
        // Insert the custom div before the upload list
        uploadList.insertAdjacentElement('afterbegin', customDiv);
      }
    }, [])

    function convertToLocalTime(savedTime) {
      // Create a new Date object from the saved time
      const date = new Date(savedTime);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  
      // Convert to local time using toLocaleString
      return date.toLocaleString('en-US', { timeZone: timeZone });
  }



    return (
      <div className={`flex flex-col items-start bg-[#f7f7f7] p-2 mt-1}`}>
        {/* <div className="text-sm font-bold">Comments</div> */}
        <div className="txtdes">
          <h4 className="font-sans">{JSON.parse(localStorage.getItem('userData'))?.name|| "Reply Comment"}
          <div style={{display:'inline-block'}}>
              <Divider type="vertical" className="bg-[#e3e3e3]" />
            </div>
            {convertToLocalTime(new Date())}</h4>
          
          <p>(this screen shows comments and replies left by users against the current object current object)</p>
        </div>

        <div className="w-full">
          <TextArea
            placeholder="Add Comments"
            className="w-full h-3/4 border border-1 rounded-lg"
            autoSize={{ minRows: 3, maxRows: 100 }}
            value={replyComment}
            onChange={(e) => setReplycomment(e.target.value)}
          />
        </div>
        <div className="w-full mt-1">
          <Upload {...propss} >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className="h-auto w-26 mr-2" size="small">Attach Files</Button>
                <Button className="h-auto w-26 mr-2" size="small" onClick={(e) => {
                  e.stopPropagation();
                  handleUpload();                  
                }}
                  loading={uploading}>Reply</Button>
              </div>
              <div>
                <Button className="h-auto w-26 mr-2" size="small" onClick={(e)=>{
                  e.stopPropagation();
                  setActiveReply(null)
                }}>Discard</Button>
              </div>
            </div>

          </Upload>
        </div>
      </div>
    )
  }


  return (
    <div className="flex flex-col">
      <div className="text-sm font-bold font-sans">Previous Comments</div>
      <div>
        {data?.data?.length > 0 && data?.data?.map((item, index) => (
          <Row
            key={index}
            isFirst={index === 0}
            item={item}
            isLast={index === data.length - 1}
            activeReply={activeReply}
            setActiveReply={setActiveReply}
            {...props}
          />
        )) || <div className="mt-4">No items found.</div>}
      </div>
    </div>
    // className="max-h-[800px] overflow-y-auto"
  );
};

export default CommentList;
