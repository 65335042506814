import React from "react";
import { Modal, Input, Button, Spin,Select } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextArea from "antd/es/input/TextArea";

const CustomModal = ({
  visible,
  onCancel,
  onOk,
  initialValues,
  okText,
  cancelText,
  closable,
  loading,
  validationSchema,
  list
}) => {

  // console.log("List custome", list);

  return (
    <Modal
      open={visible}
      title=""
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      closable={closable}
      footer={null}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          linkTypeID:Yup.string().required("Link Type is required"),
          name:Yup.string(),
          URL: Yup.string()
            .url("Please enter a valid URL")
            .required("Value is required"),
          moduleID: Yup.number().integer().nullable(),
          moduleName: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("values-links", values);
          await onOk(values, setSubmitting);
          resetForm();
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div className="border rounded-sm p-4">
              <div>
                <label htmlFor="linkTypeID" className="font-bold">
                  Type
                  <span className="text-danger">*</span>
                </label> 
                <Field name="linkTypeID">
                            {({ field }) => (
                              <div> 
                                <Select
                                  {...field}
                                  defaultValue={null}
                                  style={{ width: '100%' }}
                                  placeholder="Select link type"
                                  onChange={(value) => {
                                    field.onChange({
                                      target: {
                                        name: field.name,
                                        value,
                                      },
                                    });
                                  }}
                                >

                                    {list.map(option => (
                                    <Select.Option   value={option.value}>{option.label}</Select.Option>
                                    ))}  
                                </Select>
                                <ErrorMessage name="linkTypeID" component="div"   className="error-message"/>
                              </div>
                            )}
                          </Field>

             
              </div>

              <div className="mt-2">
                <label htmlFor="URL" className="font-bold">
                  Value
                  <span className="text-danger">*</span>
                </label>
                <Field type="text" name="URL">
                  {({ field }) => (
                    <Input
                      {...field}
                      className={touched.URL && errors.URL ? "error-input" : ""}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="URL"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="mt-2">
                <label htmlFor="Description" className="font-bold">
                  Description 
                </label>
                <Field type="text" name="Description">
                  {({ field }) => (
                    <TextArea
                      {...field}
                      className={
                        touched.Description && errors.Description ? "error-input" : ""
                      }
                    />
                  )}
                </Field>
                {/* <ErrorMessage
                  name="Description"
                  component="div"
                  className="error-message"
                /> */}
              </div>

            </div>
            <div className="mt-4 text-right">
              <Button
                onClick={onCancel}
                className="mr-2"
                disabled={isSubmitting}
              >
                {cancelText}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={isSubmitting || loading}
              >
                {loading ? <Spin /> : okText}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CustomModal;
