/* eslint-disable react/react-in-jsx-scope */

import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import AnonymousRoutes from "./routes/AnonymousRoutes";
import UserService from "./services/UserService";
import anonymousRoutesJson from "./routes/anonymousRoutesJson";
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect,useState } from 'react';
import { message ,notification} from 'antd';
import { ConfigProvider } from 'antd';

import HomePageServices from './services/services/HomePageServices'
import { useNotificationProvider } from './Context/NotificationProvider';
import softwareinterfaceSound from '../src/assets/sound/software-interface.wav';

function App() {
	const [permission, setPermission] = useState(Notification.permission);

	const [api, contextHolder] = notification.useNotification();

	const { state: notificationDataProvider, dispatch: dispatchNotificationData } = useNotificationProvider();


	const [messageApi] = message.useMessage();
	const anonymousRoutes = anonymousRoutesJson;
	let pathname = window.location.pathname
	pathname = pathname.split("/");
	pathname = pathname.filter((f) => f !== '');
	useEffect(() => {
		if (UserService.isLoggedIn()) {
			let userDetails = UserService.getUserdetails();
			localStorage.setItem('email', userDetails.email)
			localStorage.setItem('userData', JSON.stringify({
				name: userDetails.name,
				email: userDetails.email
			}))
			UserService
				.saveUserData({
					name: userDetails.name,
					email: userDetails.email
				})
				.then((response) => {
					if (response.data.code === 200) {
						messageApi.open({
							type: 'success',
							content: response.data.message,
						});
					} else {
						messageApi.open({
							type: 'error',
							content: response.data.message,
						});
					}
				}).catch((err) => {
					// console.log(err);
				});
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [UserService.isLoggedIn()])

	useEffect(() => {

		const subscribeUser = async () => {
			if ('serviceWorker' in navigator && 'PushManager' in window) {
				try {
					// Register Service Worker
					const registration = await navigator.serviceWorker.ready;

					const convertedVapidKey = urlBase64ToUint8Array("BNyx6dIRVxlEhQT8hr_yUoHqUnhB58n1lVXqEpE03qdXrtLa-NnKas1-fnBj_eR3lACur-GbJuDec0JEBFamqXo");

					// Subscribe to Push
					const subscription = await registration.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: convertedVapidKey
					});

					const userEmailID = localStorage.getItem('email');

					const notificationToken = { email: userEmailID, token: "", subscription: subscription }

					const addSubscription = await HomePageServices.UpdateUsernotificationToken(notificationToken);


					// if (res.ok) {
					//     console.log('Subscription sent to server successfully.');
					// } else {
					//     console.error('Failed to send subscription to server.');
					// }
				} catch (error) {
					// console.error('Error subscribing to push notifications:', error);
				}
			} else {
				console.warn('Push messaging is not supported in this browser.');
			}
		};

		subscribeUser();
		const loadNotificationData = async () => {
			try {
				await getNotificationData();
			} catch (error) {
				console.error("Failed to fetch notification data", error);
			}
		};
		loadNotificationData();

		if (!('Notification' in window)) {
			alert('This browser does not support desktop notifications.');
			return;
		}

		Notification.requestPermission().then((perm) => {
			setPermission(perm);
			if (perm === 'granted') {
				// Optionally unlock audio here as well

			}
		});

	}, [])

	useEffect(() => {
		// Register the service worker
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
				// console.log('Service Worker registered with scope:-', registration.scope);
			}).catch(function (error) {
				console.error('Service Worker registration failed:', error);
			});
		}

		// Listen for messages from the service worker
		navigator.serviceWorker.addEventListener('message', event => {
			if (event.data && event.data.type === 'SHOW_CUSTOM_NOTIFICATION') {
				// Handle the custom notification logic here
				const { title, body, icon } = event.data;
				// Show your custom popup with the title, body, and icon
				showCustomNotification({ title, body, icon });
			}
		});
	}, [dispatchNotificationData]);

	const showCustomNotification = ({ title, body, icon }) => {

		//message.success(body);

		const pauseOnHover = true;


		api.open({
			message: title,
			description: body,
			showProgress: true,
			pauseOnHover,
			//icon: icon,
			 
		});

		getNotificationData();

		if (permission) {

			/*try {
				const sound = new Audio(softwareinterfaceSound);
				sound.play();
			}
			catch (err) {

			}*/
		}
	};

	const getNotificationData = async () => {
		//asda
		const email = localStorage.getItem('email')
		if (email) {
			const data = {
				"userid": email
			}
			const notificationResult = await HomePageServices.getNotificationData(data);
			if (notificationResult.status === 200) {
				await dispatchNotificationData({ type: "SET_DATA", payload: notificationResult?.data });
			}
			else {
				message.error(notificationResult.message);
			}
		}
	}


	
	return (
		<div className="App">
			{contextHolder}
			<ConfigProvider theme={{ hashed: false }}>
				<BrowserRouter>
					{anonymousRoutes.includes(pathname[0])?<AnonymousRoutes/>:(UserService.isLoggedIn() ? <AuthenticatedRoutes /> :null) }
					{/* {<AuthenticatedRoutes />} */}
				</BrowserRouter>
			</ConfigProvider>
			
		</div>
	);
}

// Utility Function to Convert VAPID Key
function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
}

export default App;
