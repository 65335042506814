import {
  Checkbox,
  Collapse,
  Button,
  Modal,
  Tag,
  Tooltip,
  Row,
  Col,
  Spin,
  Pagination,
  message,
  Input,
} from "antd";
import React, { useEffect, useState, useRef  } from "react";
import {
  PlusOutlined,
  CloseOutlined,
  CaretRightOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  default_pageSize,
  default_pageSizeOptions,
  transformText,
} from "../../../utils/Common";
import BusinessApplicationListCards from "../BusinessApplicationListCards";
import usePortfolioRelationshipModify from "../../../hooks/usePortfolioRelationshipModify";
import { usePortfolioConnection } from "../../../Context/portfolioConnectionProvider";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import PortfolioRelationshipServices from "../../../services/services/PortfolioRelationshipService";
import FilterRightSideBar from "./FilterRightSideBar";
import { useMenu } from "../../../Context/MenuProvider";

const { Panel } = Collapse;
const modalContentHeight = window?.innerHeight - 400;

const FilterRelationship = ({
  selectedFilters: filterOptions,
  setSelectedFilters: setFilterOptions,
  portfolioData = [],
  setSwimLaneSelectedFilters,
  resetRelation=false,
  setResetRelation,
  selectedRelationFilters:selectedFilters,
  setSelectedRelationFilters:setSelectedFilters,
  isRelationPopup = false,
  isSourceTargetModuleName='',
  ...props
}) => {
  // const [selectedFilters, setSelectedFilters] = useState({});
  const [tempSelectedFilters, setTempSelectedFilters] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const [moduleName, setmoduleName] = useState();
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [listApplication, setListApplication] = useState([]);
  const [tempListApplication, setTempListApplication] = useState([]);
  const [addedData, setAddedData] = useState([]);
  const [connections, setConnections] = useState(null);
  const scrollRef = useRef(null);
  const { stateMenu, dispatchMenu } = useMenu();

  const {
    addPortfolioRelationship,
    removePortfolioRelationship,
    dataLoading,
    error,
  } = usePortfolioRelationshipModify();

  const { state: portfolioConnection, dispatch: portfolioConnectionDispatch } =
    usePortfolioConnection();

  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;

  const { fetchPortfolioNodesById } = usePortfolioFetcher();
  const [search, setSearch] = useState("");
  const prevModuleNameRef = useRef();
  const [key, setKey] = useState(Date.now());
  const [prevSelectedDraw, setPrevSelectedDraw] = useState(false);
 
  const getAllConnections = async (dispatch, id) => {
    try {
      setLoading(true);
      await fetchPortfolioNodesById(dispatch, id, isRelationPopup);
    } catch (error) {
      console.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  
  const connectionHandler = (moduleName, MenuID, isPopup, portfolioData) => {
    const getPortfolioId = () => {
      if (isPopup && moduleName) {
        const portfolioItem = portfolioData.find(
          (item) => item?.moduleName === moduleName
        );
        return portfolioItem?.id || portfolioData[0]?.portfolioId;
      } else if (!isPopup && MenuID?.ID) {
        const portfolioItem = portfolioData.find(
          (item) => item?.id === MenuID.ID
        );
        return portfolioItem?.id || portfolioData[0]?.portfolioId;
      } else if(!isPopup && !MenuID?.ID && moduleName){
        const portfolioItem = portfolioData.find(
          (item) => item?.moduleName === moduleName
        );
        return portfolioItem?.id;
      }
      return null;
    };

    const portfolioId = getPortfolioId();
    if (portfolioId) {
      getAllConnections(portfolioConnectionDispatch, portfolioId);
    }
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length > 0 && (stateTag?.moduleName !== prevModuleNameRef.current)) {
      connectionHandler(
        isSourceTargetModuleName!= ''?isSourceTargetModuleName:stateTag?.moduleName,
        stateMenu?.MenuID,
        isRelationPopup,
        portfolioSettingState?.data
      );
      prevModuleNameRef.current = stateTag?.moduleName;
    }
  }, [
    isSourceTargetModuleName!= ''?isSourceTargetModuleName:stateTag?.moduleName,
    stateMenu,
    isRelationPopup,
    portfolioSettingState?.data,
  ]);

  // useEffect(() => {
  //   if (stateMenu?.MenuID || stateMenu?.MenuID != undefined) {
  //     console.log("stateMenu",stateMenu?.MenuID)
  //   }
  // }, [stateMenu]);

  const transformArrayToObject = (array) => {
 
    
    if (!Array.isArray(array)) {
      console.error("Expected an array but received:", array);
      return {};
    }

    if (array?.length === 0) {
      return {};
    }

    
    
    return array?.reduce((acc, item) => {
      
      acc[item?.moduleName] = [];
      return acc;
    }, {});
  };

  useEffect(() => {
    setLoading(false);
    const dataToUse = isRelationPopup ? portfolioConnection?.popupdata : portfolioConnection?.data;
    if (dataToUse) {
      setConnections(dataToUse);
      const transFormData = transformArrayToObject(dataToUse?.connectedNodes || []);
      const oldSelectedFilter = Object.keys(selectedFilters)?.length === Object.keys(transFormData)?.length ? selectedFilters : {};
      const newSelectedItems = Object.keys(oldSelectedFilter)?.length ? { ...transFormData, ...oldSelectedFilter } : { ...transFormData };
      setSelectedFilters(newSelectedItems);
    }
  }, [portfolioConnection?.data, portfolioConnection?.popupdata, isRelationPopup]);
   

  const handleAddRelationship = async ({ id, moduleName }) => {
    const scrollTop = scrollRef.current?.scrollTop;
    const scrollRefContainer = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollTop;
      }
    };

    const findItems = listApplication?.find((item) => item?.id === id);
    setTempSelectedFilters(tempSelectedFilters => {
      requestAnimationFrame(scrollRefContainer);
      return [...tempSelectedFilters, findItems];
    });
  };

  const handleRemoveRelationship = async ({ id, moduleName }) => {
    try {
      console.log("id", id, "moduleName", moduleName);
    } catch (error) {
      console.error("Error removing relationship:", error);
    }
  };

  const getSelectedPortfolioItemList = async (
    moduleName,
    page = 1,
    limit = default_pageSize,
    searchTerm = ""
  ) => {
    try {
      setLoading(true);
      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        page: page,
        limit: limit,
        search: searchTerm,
      });
      if (list?.data) {
        const { data, totalCount } = list?.data;

        setTotalRecords(totalCount || 0);
        setListApplication(
          data?.map((item) => ({
            ...item,
            moduleName: transformText(moduleName?.replace("_", " "), "pascal"),
          })) || []
        );
        
        setTempListApplication(data?.map((item) => ({
          ...item,
          moduleName: transformText(moduleName?.replace("_", " "), "pascal"),
        })) || []);

        const lastSelectedFiltersIds =
          selectedFilters[moduleName]?.map((item) => item?.id) || [];
        setAddedData(lastSelectedFiltersIds);
      }
    } catch (error) {
      message.error(
        "No portfolio items found." || error?.response?.data?.error
      );
      console.error("Error fetching data", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };

  useEffect(() => {
    if (Object.keys(modalType)?.length > 0) {
      getSelectedPortfolioItemList(modalType?.moduleName, 1, default_pageSize, search,);
    }
    
  }, [modalType, search]);

  useEffect(() => {
    if (listApplication?.length > 0 && search?.length > 0) {
      const filtered = listApplication?.filter((item) =>
        item?.displayname?.toLowerCase().includes(search.toLowerCase())
      );
      
      setListApplication(filtered);
    }else{
      setListApplication(tempListApplication);
    }
  }, [search]);

  useEffect(() => {
    if (resetRelation) {
      setSelectedFilters({});
      setTempSelectedFilters([]);
      setFilterOptions({
        ...filterOptions,
        ["relationshipFilter"]: {},
      });
      setSwimLaneSelectedFilters({
        ...filterOptions,
        ["relationshipFilter"]: {},
      });
      setModalType({});
      resetStates();
      setResetRelation(false)
    }
  }, [resetRelation])
  

  // useEffect(() => {
  //   if (Object.keys(modalType)?.length > 0 && page > 1) {
  //     getSelectedPortfolioItemList(modalType?.moduleName);
  //   }
  // }, [page, limit]);

  const showModal = async (type) => {
    setSearch("");
    await setModalType(type);
    setIsModalVisible(true);
    setKey(Date.now());
  };

  const transformObject = (obj) => {
    const transformed = {};

    for (const [key, value] of Object.entries(obj)) {
      if (value?.length > 0) {
        transformed[key] = value.map((item) => ({
          id: item.id,
          moduleName: item?.moduleName,
          displayname: item?.displayname,
        }));
      }
    }

    return transformed;
  };

  const relationShipFilterDataSession = (obj) =>{
    let transformed = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value?.length > 0) {
        transformed[key] = value.map((item) => ({
          id: item.id,
          item:item,
          moduleName: item?.moduleName,
        }));
      }
    }
    return transformed;
  }

  const submitFilterValues = (values) => {
    setFilterOptions({
      ...filterOptions,
      ["relationshipFilter"]: transformObject(values),
    });
    setSwimLaneSelectedFilters({
      ...filterOptions,
      ["relationshipFilter"]: relationShipFilterDataSession(values),
    });
  };

  const handleOk = async () => {
    if (tempSelectedFilters?.length === 0) {
      return message.info("At least add one item.");
    }

    const selectedFiltersValues = {
      ...selectedFilters,
      [modalType?.moduleName]: [
        ...(selectedFilters[modalType.moduleName] || []),
        ...tempSelectedFilters,
      ],
    };
    setSelectedFilters(selectedFiltersValues);
    setSearch("");
    setTempSelectedFilters([]);
    setIsModalVisible(false);
    await submitFilterValues(selectedFiltersValues);
  };

  const handleCancel = () => {
    const lastSelectedFiltersIds =
      selectedFilters[modalType?.moduleName]?.map((item) => item?.id) || [];
    setAddedData(lastSelectedFiltersIds);
    setTempSelectedFilters([]);
    setIsModalVisible(false);
    setSearch("");
  };

  const handleCheckboxChange = (type, checkedValues) => {
    setSelectedFilters({
      ...selectedFilters,
      [type]: checkedValues,
    });
  };

  const removeItem = async (type, item) => {
    const currentFilterValues = {
      ...selectedFilters,
      [type]: selectedFilters[type].filter((i) => i?.id !== item),
    };

    setSelectedFilters(currentFilterValues);

    const temp = addedData?.filter((f) => f.toString() !== item.toString());
    setAddedData(temp);
    await submitFilterValues(currentFilterValues);
  };

  const handleSearch = (value) => {
    console.log("value", value);
  };

  const renderSelectedItems = (type) => {
    return selectedFilters[type]?.map((item) => {
      const itemName = item?.displayname || item?.DisplayName;
      return (
        <Tag
          key={item?.id}
          closable
          onClose={() => removeItem(type, item?.id)}
          closeIcon={<CloseOutlined className="text-[#2A609D] text-sm" />}
          // className="m-1 mt-2 p1 text-sm flex items-center justify-between border border-gray-300 rounded-sm px-2 py-1"
          className="text-sm flex items-center justify-between"
        >
          {itemName?.length > 18 ? (
            <Tooltip title={itemName} placement="top">
              <span>{itemName.substring(0, 18)}...</span>
            </Tooltip>
          ) : (
            <span>{itemName}</span>
          )}
        </Tag>
      );
    });
  };

  const resetStates = async () => {
    await setTotalRecords(0);
    await setListApplication([]);
    await setTempListApplication([]);
    await setSearch("");
    await setPage(1);
    await setLimit(default_pageSize);
  };

  const FilterPortfolio = () => {
    return (
      <>
      <div className="modelpopup_bx">
        {/* <div className="d-flex justify-content-between"> */}
          <div id="portfolioList" className="w-100 container-fluid border-right-0 popup_leftpnlbx">
            {/* <Row gutter={[8, 8]} className="mx-0 text-left mt-2"> */}
              {/* <Col span={24} className="p-2"> */}
                <Spin className="loading_bx" size="small" spinning={loading}>
                  {!loading && (
                    <>
                      <div
                        className="overflow-y listview_bx"
                        style={{ maxHeight: modalContentHeight }}
                        ref={scrollRef}
                      >
                        {listApplication?.length > 0 ? (
                          <BusinessApplicationListCards
                            cardModuleName={modalType?.moduleName}
                            moduleName={modalType?.moduleName}
                            filterLevel={filterLevel}
                            filterText={filterText}
                            filterBy={filterBy}
                            lists={listApplication}
                            addRemove={true}
                            setAddedData={setAddedData}
                            addedData={addedData}
                            handleAddRelationship={handleAddRelationship}
                            handleRemoveRelationship={handleRemoveRelationship}
                            dataLoading={loading}
                          />
                        ) : (
                          <div>
                            {loading ? (
                              <span>Loading...</span>
                            ) : (
                              <span>No portfolio items found.</span>
                            )}
                          </div>
                        )}
                      </div>
                      {totalRecords > 0 && (
                        <Pagination
                          className="mt-2 text-right"
                          total={totalRecords}
                          showTotal={(total) => `Total ${total} items`}
                          defaultPageSize={default_pageSize}
                          defaultCurrent={page}
                          // pageSizeOptions={default_pageSizeOptions}
                          showSizeChanger={false}
                          onChange={async (p, ps) => {
                            setLimit(ps);
                            setPage(p);
                            await getSelectedPortfolioItemList(
                              modalType?.moduleName,
                              p,
                              ps,
                              search,
                            );
                          }}
                        />
                      )}
                    </>
                  )}
                </Spin>
              {/* </Col> */}
            {/* </Row> */}
          </div>
        {/* </div> */}
        </div>
      </>
    );
  };

  //Store Filter in localStorage and check old module
  useEffect(() => {
    const localStorageFiltersRel = localStorage.getItem("selectedFiltersRelation");
    if (localStorageFiltersRel && !isRelationPopup) {
      setSelectedFilters(JSON.parse(localStorageFiltersRel));
    }
  }, []);
  useEffect(() => {
    if(!isRelationPopup)
    {
        localStorage.setItem("selectedFiltersRelation", JSON.stringify(selectedFilters));
    }
  }, [selectedFilters]);

  //Collapse Open for Relation Filter if selected filter exists
  let activeCollapseKey = [];
  if(!isRelationPopup)
  {
    let getSelectedFilters = portfolioConnection?.data && portfolioConnection?.data?.connectedNodes?.length > 0 && portfolioConnection?.data?.connectedNodes?.flatMap((item) => 
      selectedFilters[item?.moduleName] !== undefined ? 
      selectedFilters[item?.moduleName]?.map((itemIn) => itemIn?.displayname || item?.DisplayName) : ''
  ).filter(item => item !== '');
  const hasSelectedFilters = getSelectedFilters && getSelectedFilters?.length > 0;

  const jsonData = JSON.parse(localStorage.getItem("selectedFiltersRelation"));
  const hasNonEmptyArray = () => {
    for (const key in jsonData) {
      if (jsonData[key]?.length > 0) {
        return true;
      }
    }
    return false;
  };
  const selectedRelationFilter = hasNonEmptyArray();
  activeCollapseKey = hasSelectedFilters || (selectedRelationFilter === true) ? [0] : [];
}

 
  return (
    <div className="my-2">
      <Spin className="loading_bx" size="small" spinning={loading}>
        <Collapse
          defaultActiveKey={activeCollapseKey}
          expandIconPosition="end"
          className="life-cycle-stages-filter"
          expandIcon={({ isActive }) =>
            isActive ? (
              <DownOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
            ) : (
              <RightOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
            )
          }
        >
          <Panel header={`Relations`}>
            {connections && connections?.connectedNodes && connections?.connectedNodes?.length ? (
              // <div className="max-h-72 overflow-y-auto p-2 filter_itembx">
              <div className="p-2 filter_itembx">
                {(connections &&
                  connections?.connectedNodes?.length > 0 &&
                  connections?.connectedNodes?.map((item) => (
                    <div className="mb-2" key={item?.id}>
                      <div className="flex flex-col gap-2 items-start fieldlist_item">
                        <div className="title_headingbx">
                        <h4 className="font-semibold">{item?.name}</h4>
                        <div className="heading_sortbtn">
                            <Button
                              type="text"
                              icon={<PlusOutlined className="text-[#2A609D]" />}
                              onClick={async () => {
                                if (
                                  item?.moduleName !== modalType?.moduleName
                                ) {
                                  await resetStates();
                                }
                                showModal(item);
                              }}
                            />
                          </div>
                        </div>
                        {/* <h4 className="font-semibold">{item?.name}</h4> */}                        
                        <div className="flex items-center fieldsec_bx">
                        <div className="fieldinput ">
                            {renderSelectedItems(item?.moduleName)}
                          </div>
                          {/* <div className="border border-[#C1C1C1] rounded-md overflow-auto shadow-sm fieldinput ">
                            {renderSelectedItems(item?.moduleName)}
                          </div> */}
                          {/* <div>
                            <Button
                              type="text"
                              icon={<PlusOutlined className="text-[#2A609D]" />}
                              onClick={async () => {
                                if (
                                  item?.moduleName !== modalType?.moduleName
                                ) {
                                  await resetStates();
                                }
                                showModal(item);
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))) || <div>Connection not found.</div>}
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </Panel>
        </Collapse>
        <Modal
          title={
            <div className="flex items-center justify-between popup_headerbx">
              <h2>{`Select ${modalType?.name}`}</h2>
              <div className="flex gap-2">
                <Input.Search
                  key={key}
                  placeholder={`Search ${modalType?.name}`}
                  // onSearch={handleSearch}
                  style={{ width: `100%` }}
                  disabled={listApplication?.length === 0 && !search}
                  // onChange={(e) => {
                  //   setSearch(e.target.value);
                  // }}
                  //value={search}
                  onSearch={(value)=>{
                    setSearch(value)
                    setKey(Date.now());
                  }}
                  defaultValue={search}
                  //value={search}
                  name="search"
                />
                <Button
                  type="primary"
                  disabled={listApplication?.length === 0}
                  onClick={() => {
                    handleOk();
                  }}
                  className="mr-8"
                >
                  Submit
                </Button>
              </div>
            </div>
          }
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={window.innerWidth - 650}
          maskClosable={false}
          footer={false}
          zIndex={10000}
        >
          <FilterPortfolio />
        </Modal>
      </Spin>
    </div>
  );
};

FilterRelationship.defaultProps = {
  setSwimLaneSelectedFilters:()=>{},
}
export default FilterRelationship;
