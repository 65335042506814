import React, { useEffect, useState, useRef } from "react";
import { Typography, Layout, Collapse, Card, message, Spin, Flex } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./Settings.scss";
import SettingsContent from "./SettingsContent";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import { normalizeString } from "../../utils/linksCommon";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { PageTitleHeading } from "../../utils/Common";
import LoadingBar from 'react-top-loading-bar'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { Panel } = Collapse;
// const menus = [
//   {
//     id: 1,
//     label: `Porfolio`,
//     children: [
//       { id: 1, name: "Application" },
//       { id: 2, name: "Business Capabilities" },
//       { id: 3, name: "Business Process" },
//       { id: 4, name: "Information" },
//       { id: 5, name: "Data Object" },
//       { id: 6, name: "Objective (OKR)" },
//       { id: 7, name: "Integration" },
//       { id: 8, name: "Technology" },
//       { id: 9, name: "Initiative" },
//       { id: 10, name: "Organization" },
//     ],
//   },
//   {
//     id: 2,
//     label: `Others`,
//     children: [{ id: 1, name: "References Type" }],
//   },
// ];

const initialMenus = [
  {
    id: 1,
    label: `Portfolio`,
    children: [],
  },
  {
    id: 2,
    label: `Others`,
    children: [
      { 
        id: 1, 
        name: "References Type" 
      }, 
      { 
        id: 2, 
        name: "Global Attributes" 
      }, 
      { 
        id: 3, 
        name: "User Guide Library" 
      }, 
      { 
        id: 4, 
        name: "Welcome Video Library" 
      },
      { 
        id: 5,
        name: "Global Currency" 
      }, 
    ],
  },
];

const Settings = (props) => {
  const ref = useRef(null)
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { state, dispatch } = usePortfolioSetting();
  const [menuItems, setMenuItems] = useState(initialMenus);
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [selectedMenuText, setSelectedMenuText] = useState("");
  const [selectedCollapse, setSelectedCollapse] = useState(-1);
  const [updatedMenu, setUpdatedMenu] = useState({ id: null, name: "" });
  const [activeTab, setActiveTab] = useState("1");

  // const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  //OLD
  // const fetchAllPortfolio = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await PortfolioSettingServices.getList({
  //       page,
  //       limit,
  //     });
  //     const newData = response.data.data;
  //     console.log("response", response.data.data);

  //     await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
  //     if (response?.data?.data?.length) {
  //       message.success("Portfolio fetched successfully.");
  //     } else {
  //       message.success("Portfolio not found.");
  //     }
  //   } catch (error) {
  //     message.error("Something went wrong.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  //New
  const fetchAllPortfolio = async () => {
    
    try {
      const response = await fetchPortfolio(dispatch, page, limit);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        // message.success("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    setUpdatedMenu({ id: null, name: "" });
    fetchAllPortfolio();
  }, []);

  
  useEffect(() => {
    if (ref.current) {ref.current.continuousStart()}
    if (state?.data && state?.data?.length) {
      const updatedMenus = [...menuItems];
      const portfolioMenuIndex = updatedMenus.findIndex(
        (menu) => menu.label === "Portfolio"
      );
 
      if (portfolioMenuIndex !== -1) {
        let othersSettingsPage = JSON?.parse(localStorage?.getItem("others-settings-page"));
        updatedMenus[portfolioMenuIndex].children = state.data.map((item) => ({
          id: item.id,
          name: item.name,
          ...item,
        })); 

        setMenuItems(updatedMenus);
         
        // setSelectedCollapse(
        //   menuItems?.findIndex((obj) => obj.label === "Portfolio")
        // );
        // setSelectedCollapse(null);
        if(othersSettingsPage){
          setSelectedCollapse(othersSettingsPage?.index);
          setSelectedMenu(othersSettingsPage?.item?.id);
          setSelectedMenuText(othersSettingsPage?.item?.name);
          setActiveTab(othersSettingsPage?.activeTab);
        }else{
          setSelectedMenu(
            updatedMenu && updatedMenu?.id ? updatedMenu?.id : state?.data[0]?.id
          );
     
          setSelectedMenuText(
            updatedMenu && updatedMenu?.name
              ? updatedMenu?.name
              : state?.data[0]?.name
          );
        }
      }
    } else {
      console.log("menuItems: ",menuItems)
      setSelectedCollapse(
        menuItems?.findIndex((obj) => obj.label === "Others")
      );
      setSelectedMenu(menuItems[1]?.children[0]?.id);
      setSelectedMenuText(menuItems[1]?.children[0]?.name);      
    }

    if (ref.current) {ref.current.complete()}
  }, [state?.data]);

  const PortfolioMenuItems = ({ item, index }) => {
    return (
      <div
        onClick={() => {
          setSelectedCollapse(index);
          setSelectedMenu(item?.id);
          setSelectedMenuText(item?.name);
          setActiveTab(1);
          setMenuOnPageRefresh({
            index,
            item,
            activeTab:1,
          })
        }}
        className={
          selectedCollapse === index && selectedMenu === item?.id
            ? `active-list cursor-pointer cu-setting-menu-nav`
            : `cursor-pointer cu-setting-menu-nav`
        }
        style={{background:item?.color}}
      >
      {item?.name?.toString()}
        {/* {normalizeString(item?.name?.toString())} */}
      </div>
    );
  };

  const PortfolioMenu = ({ item, isFirst, isLast, index }) => {
    return (
      <div key={index} className={`my-2 ${isFirst? `portfolio-menus`:``} `}>
        <Collapse
          defaultActiveKey={selectedCollapse === index && `${selectedCollapse}`}
          expandIconPosition="start"
          bordered={false}
          className="settings-menus mb-2"
        >
          <Panel header={item?.label} key={index}>
            <div key={index} className="setting_navlist">
              <DragDropContext 
                onDragEnd={(val)=>{ 
                  if(isFirst){
                    onDragEnd(val,item)
                  }
                  
                }}
              >
                <Droppable droppableId="parents" type="parent" key={Math.random()}>
                  {(provided) => (
                    <Flex 
                      {...provided.droppableProps} 
                      ref={provided.innerRef}
                      gap={2}
                      vertical
                    >
                      {(item?.children?.length &&
                        item?.children
                          ?.sort((a, b) => a.position - b.position)
                          ?.map((d, i) => (
                            <Draggable
                              key={`${d?.id}`}
                              draggableId={`${d?.id}`}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <PortfolioMenuItems item={d} index={index} />
                                </div>
                              )}
                            </Draggable>
                          ))) || (
                        <div className="text-base font-bold mt-4">No items found.</div>
                      )}
                    </Flex>
                  )}
                </Droppable>
              </DragDropContext>
              {/* <ul>
                {item?.children?.map((d, i) => (
                  <React.Fragment key={Math.random()}><PortfolioMenuItems item={d} index={index} /><PortfolioMenuItems item={d} index={index} /></React.Fragment>
                ))}
              </ul> */}
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };

  const onDragEnd = async (result,list) => {
    try {
      if (!result?.destination) {
        return;
      }

      const sourceIndex = result?.source?.index;
      const destinationIndex = result?.destination?.index;
  
      const result2 = reorder(list?.children,sourceIndex,destinationIndex)?.map((item,index)=>{
        item.position=index+1;
        return item;
      });

      const {data:response} =await PortfolioSettingServices?.updatePortfoliosOrdering(result2);

      if (response?.length>0) {
        message.success("Portfolio Updated successfully");
        fetchAllPortfolio()
      } else {
        message.error(response?.error || "Failed to update");
      }
    } catch (error) {
      console.error("error: ",error)
    }
    
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  useEffect(()=>{
    setSelectedCollapse(0)
    localStorage.removeItem("activePanelz")
  },[])

  const setMenuOnPageRefresh = (data) => {
    localStorage.setItem("others-settings-page",JSON.stringify(data));
  }

  return (
    <><LoadingBar color='#1f1f1f' ref={ref} />
    <div className="other-settings">
      <div className="headingbx">
        <PageTitleHeading text={`Settings`} />
      </div>
      <></>
      <Layout>
        <Layout.Sider className="setting_navpnl" theme={`light`}>
          {menuItems?.map((data, index) => (
            <React.Fragment key={Math.random()}>
              <PortfolioMenu
                item={data}
                index={index}
                isLast={index === data?.length - 1}
                isFirst={index === 0}
              />
            </React.Fragment>
          ))}
        </Layout.Sider>
        <Layout.Content>
          <div className="px-2 pt-2">
            <Card bordered={false}  style={{boxShadow:`${selectedCollapse == null ? "none" :""}`}}>
              <SettingsContent
                selectedMenu={selectedMenu}
                selectedCollapse={selectedCollapse}
                selectedMenuText={normalizeString(selectedMenuText)}
                label={selectedMenuText}
                items={menuItems[selectedCollapse]?.children || []}
                fetchAllPortfolio={fetchAllPortfolio}
                setUpdatedMenu={setUpdatedMenu}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                menuItems={menuItems}
              />
            </Card>
          </div>
        </Layout.Content>
      </Layout>
    </div>
    </>
  );
};

export default Settings;
