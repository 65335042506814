import React, { useState } from 'react';
import './fishbone.scss';
import Draggable from 'react-draggable';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Flex, Slider, Tooltip, Typography } from 'antd';
import { tooltip } from '@antv/g2plot/lib/adaptor/common';
import { useEffect } from 'react';

let legends = [
    {color:"#D21C21",text:"Retire"},
    {color:"#1285FD",text:"Replace"},
    {color:"#4EC628",text:"Add"},
    {color:"#BB29B5",text:"Change Version"},
];

let lists = [
    {
        title_1:"Jan",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Feb",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Mar",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Apr",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Vcats -> One Engine",
                color:"#1285FD",
            },
            {
                title:"(App) Bartender",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"May",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jun",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jul",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Profi -> V2",
                color:"#BB29B5",
            },
            {
                title:"(App) Pos -> TOCC",
                color:"#BB29B5",
            },
        ],
    },
    {
        title_1:"Aug",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Sep",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Anaqua",
                color:"#4EC628",
            },
            {
                title:"(App) Insider Log",
                color:"#4EC628",
            },
        ],
    },
    {
        title_1:"Oct",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Percipio",
                color:"#4EC628",
            },
            {
                title:"(App) Die",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"Nov",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Dec",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jan",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Tidinfo -> UKG",
                color:"#1285FD",
            },
            {
                title:"(App) Fieldglass",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"Feb",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
]

function FishboneChart({legends,lists,tbdData,...props}) {
    const [limit,setLimit] = useState([0,0]);

    useEffect(()=>{
        setLimit([0,lists?.length])
        //lists?.length-1
    },[lists])

    let fishboneChartStyle = {
        "--chart-height":props?.fishboneChartStyle?.height || `${window?.innerHeight/1.25}px`,
        "--chart-width":props?.fishboneChartStyle?.width || window?.innerWidth,
        ...props?.fishboneChartStyle,
    }

    let fishboneChartInnerStyle = {
        "--chart-height":props?.fishboneChartInnerStyle?.height || `${window?.innerHeight/1.25}px`,
        "--chart-width":props?.fishboneChartInnerStyle?.width || "100%",
        ...props?.fishboneChartInnerStyle,
    }

    let GetLinks = ({child}) =>{
        let getMenu = getMenuPortfolio({moduleName:child?.moduleName});

        return <React.Fragment>
            <a href={`${window?.location?.origin}${getMenu?.route}${props?.scenarioId?('/'+props?.scenarioId):''}/${child?.title_id}`} rel="nofollow noreferrer" target="_blank" title={`${child?.name ? `(${child?.name})`:``} ${child?.title}`}>{ child?.name ? `${child?.name}`:`` }<br/> {child?.title}</a>
            {
                child?.title_1_id && <React.Fragment>
                    <i className='text-nowrap'>&emsp;{`->`}&emsp;</i>
                    <a href={`${window?.location?.origin}${getMenu?.route}${props?.scenarioId?('/'+props?.scenarioId):''}/${child?.title_1_id}`} rel="nofollow noreferrer" target="_blank" title={`${child?.name ? `${child?.name}`:``} ${child?.title_1}`}>{ child?.name ? `(${child?.name})`:`` }<br/> {child?.title_1}</a>
                </React.Fragment>
            }
        </React.Fragment>
    }

    const sliderMarks = lists?.map(mark=>{
        return {
            style: {
                fontSize: 'x-small'
            },
            tooltip: <strong>{mark?.deadline_month}&nbsp;{mark?.deadline_year}</strong>,
            // label: <strong>{mark?.deadline_month} {mark?.deadline_year}</strong>,
        }
    });

    const CustomTooltip = ({ value }) => {
        return (
          <Tooltip title={sliderMarks[value]?.tooltip}>
            {sliderMarks[value]?.tooltip}
          </Tooltip>
        );
    };

    const GetSetTimeline = () => {

        return <>
            <Typography.Title level={5} className='mb-0 pb-0'>Set Timeline: (
                <small>
                    {
                        lists?.slice(limit[0],limit[1])?.map((list,index,rows)=>{
                            if(index===0 || index===rows?.length-1){
                                return <React.Fragment key={`list-${Math.random()}`}>
                                    <span >{list?.deadline_month} {list?.deadline_year}</span>
                                    {index===0 && " - "}
                                </React.Fragment>
                            }
                        })
                    }
                </small>
                )
            </Typography.Title>
        </>
    }

    return (
        <React.Fragment>
            <div className='fishbone-chart' style={fishboneChartStyle}>
                <div className='ml-3 slider-range' style={{width: lists?.length*30}}>
                    {
                        sliderMarks?.length>0 && <div>
                            <GetSetTimeline/>
                            <Slider range marks={sliderMarks} defaultValue={[0, lists?.length-1]} style={{width: lists?.length*30}} max={lists?.length-1} onChange={(value)=>setLimit([value[0],value[1]+1])} tooltip={{formatter:(value)=><CustomTooltip value={value} className="my-0"/>}} />
                        </div>
                    }
                </div>
                {
                    legends?.length>0 && 
                    <div className='fishbone-chart-legend'>
                        <ul>
                            {
                                legends?.map(legend=>{
                                    let styles={
                                        "--ligend-color":legend?.color
                                    }
                                    return <li key={`leg-${Math.random()}`}>
                                        <div style={styles} className='circle-dot'></div>
                                        <div>{legend?.text}</div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }
                <Draggable>
                    <div className="fishbone-chart-inner" style={fishboneChartInnerStyle}>
                        {   
                            tbdData?.length>0 && <div className='time-not-defined-data'>
                                <ol>
                                    <li>Timeline Not Defined</li>
                                    {
                                        tbdData?.map(child=>{
                                            return <li key={`list-child-${Math.random()}`} style={{"--dot-color": child?.sourceitemtypeColor}}>
                                                <span className='fishbone-link' style={{"--fishbonelink-color":child?.color}}>
                                                    <GetLinks child={child}/>
                                                </span>
                                            </li>
                                        })
                                    }
                                </ol>
                            </div>
                        }
                        {
                            lists?.length>0 && <ol className={`content-timeline`}>
                                {
                                    lists?.slice(limit[0],limit[1])?.map(list=>{
                                        return <li key={`list-${Math.random()}`}>
                                            <span className='content content-top'>{list?.deadline_month}</span>
                                            <span className='content'>{list?.deadline_year}</span>
                                            <ol>
                                                {
                                                    list?.children?.map(child=>{
                                                        return <li key={`list-child-${Math.random()}`} style={{"--dot-color": child?.sourceitemtypeColor}}>
                                                            <span className='content fishbone-link' style={{"--fishbonelink-color":child?.color}}>
                                                                <GetLinks child={child}/>
                                                            </span>
                                                        </li>
                                                    })
                                                }
                                            </ol>
                                        </li>
                                    })
                                }
                            </ol>
                        }
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
}

FishboneChart.defaultProps = {
    legends:[],
    lists:[],
    tbdData:[],
}

export default FishboneChart;
