import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography, Button, Tooltip,Input,Collapse } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import BPMLevel2, { getNewLevelId } from './BPMLevel2';
import { isDisabledRole } from '../../../utils/settingCommon';
const baseStyle = {
    justifyContent: 'center',
    margin: '10px',
}

const BPMLevel1 = ({ levelIndex,create_Duplicate, ...props }) => {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const [bcm2Index,setBcm2Index] = useState(null)
    const { moduleName: moduleNamePath } = useParams();
    const [activeKey, setAcitveKey] = useState([])    

    const navigate = useNavigate();
    let parentName;

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [dropdownRef]);


    const NewItem = ({...props}) => {
        const [inputItem,setInputItem]= useState(null)
        const inputRef = useRef(null)

        useEffect(()=>{
            inputRef.current.focus()
        },[])  

        const handleKeyPress = async(e) =>{
            if (e.key === 'Enter') {
                const obj = {
                    referenceid: 413,
                    displayname:inputItem,
                    attributes: [],
                    level:`L${levelIndex + 1}`,
                    levelid:props.newLevelId,
                    parentid: props.parentid,
                    sourceid: 1,
                }
            await create_Duplicate(obj)

            }
        }


        return (
            <>
                <Collapse
                    style={{ backgroundColor: '#fff' }}
                    onMouseEnter={(event) => event.stopPropagation()}
                    onMouseLeave={(event) => event.stopPropagation()}
                    //onChange={onChange}
                    //onClick={() => { props?.setItem(props.data); props?.setModalData({ level: (levelIndex + 1), ParentID: props?.data?.id }); }}
                    expandIconPosition={''}

                    items={[
                        {
                            key: props.key,
                            label: <>
                                <Tooltip
                                    title={"Enter Display Name"}
                                //onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                                >

                                    <Input onPressEnter={handleKeyPress} style={{ width: '100%' }} defaultValue="Enter Display Name" value={inputItem} onChange={(e) => setInputItem(e.target.value)} ref={inputRef} />
                                    {/* {props.data.levelid} {props.data.displayname} */}
                                </Tooltip>
                            </>,
                            extra: <>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                                    <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                                        e.stopPropagation();
                                        //setDropdown(!dropdown);
                                    }}>
                                        <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                                    </button>
                                    {/* {
                                        dropdown && (

                                            <ul ref={dropdownRef} style={{ padding: "0px 3px", display: 'flex', flexDirection: 'row-reverse', gap: 5, position: 'fixed', marginLeft: 70, backgroundColor: '#ffffff', borderRadius: 7, height: 32, borderColor: '#ccc', borderWidth: 1.5 }}>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button className='btn-icon'><i className="fa fa-trash-o delete" style={{ fontSize: 'large' }} /></Button></li>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }} className='btn-icon'><i className="fa fa-pencil edit" style={{ fontSize: 'large' }} /></Button></li>
                                            </ul>

                                        )
                                    } */}
                                </div>
                            </>
                        },
                    ]}
                    className='collapse-level-2 m-1'
                />
            </>
        )
    }

    return <>
        <Card
            size="small"
            style={{ ...baseStyle, backgroundColor: props?.backgroundColor }}
        >
            <div className='d-flex align-items-center justify-between'>
                <Typography.Title
                    level={5}

                    className={props?.backgroundColor ? 'text-white title-level-1 cursor-pointer w-75' : 'title-level-1 cursor-pointer  w-75'}
                    // onMouseEnter={() => setHovered(true)}
                    // onMouseLeave={() => setHovered(false)}
                    onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                > {props?.data?.levelid} {props?.data?.displayname}


                </Typography.Title>
                <div className='level-btn-1'>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={() => {
                            setDropdown(!dropdown);
                        }}>
                            <i className="fa fa-ellipsis-v edit" />
                        </button>
                        {
                            dropdown && (
                                <ul className="dropdown-menu" ref={dropdownRef}>
                                    <li><Button onClick={() => { props?.setItemDelete(props?.data, { type: 'delete' }); }} disabled={isDisabledRole(["Admin", "Editor"])} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                                    <li><Button onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }} disabled={isDisabledRole(["Admin", "Editor"])} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                props?.data?.child?.map((item, index) => {
                    return <div key={Math.random()}><BPMLevel2 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} data={item} underLevel={props?.data.displayname} underLevelID={props?.data.levelid} level={levelIndex + 1} bcm2Index={bcm2Index} setBcm2Index={setBcm2Index} create_Duplicate={create_Duplicate} activeKey={activeKey} setAcitveKey={setAcitveKey} /></div>
                })
            }
            { props?.data?.levelid === props.bcm2Index && 
                <NewItem newLevelId={getNewLevelId(props?.data?.levelid, levelIndex + 1, props.data)} parentid={props?.data?.id}/>
            }
            <br />
            <br />
            <Tooltip title="Level 2">
                <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} disabled={isDisabledRole(["Admin", "Editor"])} onClick={() => {
                    props.setBcm2Index(props?.data?.levelid)
                    //navigate(props.basePath + '/add', { state: { Level: levelIndex + 1, ParentID: props?.data?.id, ParentName: props?.data?.displayname, underLevel: props?.data?.displayname, levelID: props?.data?.levelid, newLevelId: getNewLevelId(props?.data?.levelid, levelIndex + 1, props.data), from: props.basePath, defaultActiveKey: 2 } })
                }}></Button>
            </Tooltip>
        </Card>
    </>;
}

BPMLevel1.defaultProps = {
    levelIndex: 1,
}
export default BPMLevel1;