import "./PortfolioSingleEntry.scss";
import React, { useEffect, useState, useRef } from "react";
import { Tabs,  message } from "antd";
import TabChangeLogAlerts from "./portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import TabComments from "./portfolioTabs/tabcomments/TabComments";
import TabDiagrams from "./portfolioTabs/tabdiagrams/TabDiagrams";
import TabInformations from "./portfolioTabs/tabinformations/TabInformations";
import TabLinks from "./portfolioTabs/tablinks/TabLinks";
import TabPane from "antd/es/tabs/TabPane";
import { useTabContext } from "./TabProvider";
import { useLocation, useParams } from "react-router";
import TabConnections from "../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections";
import { useBusinessApplication } from "../../Context/businessApplication";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { PageTitleHeading, addIsTextToProperties, deepFindByName } from "../../utils/Common";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import { Link } from "react-router-dom";
import {useMenu} from "../../Context/MenuProvider";
import LoadingBar from 'react-top-loading-bar'
import { GoBack } from "../../utils/GoBackHistory";
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../hooks/useFetchPortfolioTag";

const PortfolioSingleEntry = () => {
  const ref = useRef(null);
  const location = useLocation();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();

  const {
    state: businessApplicationState,
    dispatch: businessApplicationDispatch,
  } = useBusinessApplication();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const [activeTabKey, setActiveTabKey] = useState("tab_information");
  const { tabDisabled, toggleTabDisabled } = useTabContext();
  const { id = null, moduleName = null } = useParams();
  const [portfolioId, setPortfolioId] = useState("");

  const [title, setTitle] = useState("Add New Application");
  const [formItems, setFormItems] = useState({});

  //Tag
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error, fetchTagsData } = useFetchPortfolioTag();

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (!id || id?.toLowerCase() === "add") {
      const newResponse = {
        applicationName: "Add New Application",
      };
      businessApplicationDispatch({
        type: "SET_ONE_BUSINESS_APPLICATION",
        payload: newResponse || {},
      });
      setPortfolioId(null);
    } else {
      setPortfolioId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessApplicationState.getOneBusinessApplication) {
      setTitle(businessApplicationState.getOneBusinessApplication.displayname);
    } 
  }, [businessApplicationState.getOneBusinessApplication]);

  useEffect(() => {
    fetchAllPortfolio();

    if (location?.state?.activeTabKey) {
      handleTabChange(location?.state?.activeTabKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    if (portfolioSettingState?.data?.length) {
      const portfolioValues = deepFindByName(
        portfolioSettingState?.data,
        staticSettingPortfolioList?.application,
        false
      );
      // Here fetching tags for particular portfolio
      if (portfolioValues?.moduleName) {
        dispatchTag({ type: "EMPTY_TAGS" });
        dispatchTag({
          type: "SET_MODULE_NAME",
          payload: portfolioValues?.moduleName,
        });
      }
      setFormItems(addIsTextToProperties(portfolioValues));
    }
    if (ref.current) {
      ref.current.complete();
    }
  }, [portfolioSettingState?.data]);

  // Tag useEffect start
  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName]);
  // Tag useEffect end

  //   const PortfolioSingleTabs = [
  //     {
  //       label: `Information`,
  //       key: "tab_information",
  //       children: <TabInformations />,
  //     },
  //     {
  //       label: `Connections`,
  //       key: "tab_connections",
  //       children: <TabConnections />,
  //     },
  //     {
  //       label: `Links`,
  //       key: "tab_links",
  //       children: <TabLinks />,
  //     },
  //     {
  //       label: `Modeling`,
  //       key: "tab_diagram",
  //       children: <TabDiagrams />,
  //     },
  //     {
  //       label: `Survey`,
  //       key: "tab_survey",
  //       children: <TabSurveys />,
  //     },
  //     {
  //       label: `Audit`,
  //       key: "tab_logalerts",
  //       children: <TabChangeLogAlerts />,
  //     },
  //     {
  //       label: `Notes`,
  //       key: "tab_comments",
  //       children: <TabComments />,
  //     },
  //   ];

  const tabsProps = {
    itemData:formItems,
    setActiveTabKey,
    handleTabChange,
    toggleTabDisabled,
    tabDisabled,
    id: portfolioId,
    moduleName,
    formItems,
    setFormItems,
    fetchAllPortfolio,
    fetchAllPortfolioTags
  };

  const PortfolioSingleTabs = [
    {
      key: "tab_information",
      tab: "Attributes",
      content: <TabInformations {...tabsProps} />,
    },
    {
      key: "tab_connections",
      tab: "Dependecies",
      content: <TabConnections {...tabsProps} />,
    },
    { 
      key: "tab_links", 
      tab: "References", 
      content: <TabLinks {...tabsProps} /> 
    },
    {
      key: "tab_diagram",
      tab: "Modeling",
      content: <TabDiagrams {...tabsProps} titleName={title} />,
    },
    // {
    //   key: "tab_survey",
    //   tab: "Survey",
    //   content: <TabSurveys {...tabsProps} />,
    // },
    {
      key: "tab_logalerts",
      tab: "Audit",
      content: <TabChangeLogAlerts {...tabsProps} />,
    },    
    {
      key: "tab_comments",
      tab: "Notes",
      content: <TabComments {...tabsProps} />,
    },
  ];

  return (
    <>
      <LoadingBar color="blue" ref={ref} />
      <div className=" mb-0">
        <div className="d-flex justify-content-between align-items-center">
          <PageTitleHeading
            text={(() => {
              if (title) {
                return "Application: " + title;
              } else {
                return `Add New Application`;
              }
            })()}
          />
          <div className="my-3">
            <GoBack className={"pull-right ml-2"} back/>
          </div>
        </div>
        <Tabs
          type="card"
          className="portfolia-single-entry"
          // items={PortfolioSingleTabs}
          activeKey={activeTabKey}
          onChange={handleTabChange}
        >
          {PortfolioSingleTabs.map((tab) => (
            <TabPane
              tab={tab.tab}
              key={tab.key}
              disabled={tabDisabled[tab.key]}
            >
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default PortfolioSingleEntry;
