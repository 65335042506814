import React, { useEffect, useRef, useState } from "react";

import {
  Flex,
  Layout,
  message,
  Typography,
} from "antd";
import LoadingBar from "react-top-loading-bar";
import ScenarioServices from "../../../services/services/ScenarioServices";
import { useLocation } from "react-router";
import { CompareForm, CompareHeader, CompareScenarioCard, WithChangeType } from "./CompareCommon";

let keySession2 = 'compare-scenario-portfolio';
let keySession1 = 'compare-scenario';

function CompareScenarioV3() {
  const location = useLocation();
  const loadingBarRef = useRef(null);
  const [portfolioList, setPortfolioList] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [finalChartData, setFinalChartData] = useState([]);
  const [data, setData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [compareForm,setCompareForm] = useState({isChecked: "Timeline",portfolio: [],})

  useEffect(() => {
    if (!location?.state) {
      location.state = JSON.parse(sessionStorage.getItem(keySession1)) || null;
      const compareScenarioPortfolioTemp = JSON.parse(sessionStorage.getItem(keySession2)) || [];
      setSelectedPortfolio(compareScenarioPortfolioTemp);
    } else {
      sessionStorage.setItem(keySession1, JSON.stringify(location?.state));
      setSelectedPortfolio([]);
    }

    getscenariocomparison(location.state);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterDataPortfoliowise();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio]);

  useEffect(() => {
    getUniquePortFolioFromData();
    filterDataPortfoliowise();
    // updateSummaryData();
    setSummaryData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getscenariocomparison = (comparisonState={}) => {
    const integer_array = comparisonState?.compare?.compareIds?.map(Number);

    const obj = {
      scenario_ids: integer_array,
    };

    // const obj = {
    //   scenario_ids: [346, 332],
    // };

    ScenarioServices.getscenariocomparison(obj)
      .then((result) => {
        if (result.status === 200) {
          if (result.data) {
            setData(result.data);
          }
        } else {
          message.error("error occured during getting daya");
        }
      })
      .catch((err) => {});
  };

  const getUniquePortFolioFromData = () => {
    const uniquePortfolios = Array.from(
      new Set(
        data?.flatMap((scenario) =>
          scenario?.portfolio?.map((portfolio) =>
            JSON.stringify({
              portfolioname: portfolio?.portfolioname,
              id: portfolio?.portfolio_id,
              color: portfolio?.color,
            })
          )
        )
      )
    )?.map((item) => JSON.parse(item));

    setPortfolioList(uniquePortfolios);
  };

  const filterDataPortfoliowise = () => {
    const dataFinal = [];
    let totalOpex = 0;
    let totalCapex = 0;
    let totalRelations = 0;
    let totalExpenditure = 0;
    let symbol = "";
    const scenarioData1 = data?.map((scenario) => {
      const scenarioData = data?.find((event) => event.name === scenario.name);

      totalOpex = 0;
      totalCapex = 0;
      totalRelations = 0;
      totalExpenditure = 0;

      scenarioData.portfolio.forEach((portfolio) => {
        portfolio.data.forEach((milestone) => {
          if (milestone?.item_type !== 'Deleted') {
            totalOpex += parseFloat(milestone?.opex);
            totalCapex += parseFloat(milestone?.capex);
            totalExpenditure += parseFloat(milestone?.totalexp);
          }
          totalRelations += parseFloat(milestone?.total_relations);
          symbol = milestone?.currency;
        });
      });

      dataFinal.push({
        type: scenarioData?.type,
        id: scenarioData?.id,
        name: <Typography.Text ellipsis={true} title={data?.name} >{scenarioData?.name} </Typography.Text>,
        start: new Date(scenarioData?.start),
        end: new Date(scenarioData?.end),
        progress: scenarioData?.progress,
        hideChildren: scenarioData.hideChildren,
        color: scenarioData.color,
        count_items: scenarioData.count_items,
        opex: totalOpex,
        capex: totalCapex,
        totalexp: totalExpenditure,
        currency: symbol,
        //noofRelation: scenarioData?.total_relations,
        noofRelation: totalRelations,
        relation_modulename: scenarioData?.relation_modulename,
      });

      scenarioData?.portfolio?.map((item) => {
        if (selectedPortfolio.includes(item.portfolio_id)) {
          totalOpex = 0;
          totalCapex = 0;
          totalExpenditure = 0;
          totalRelations = 0;

          item.data.forEach((milestone) => {
            if (milestone?.item_type !== 'Deleted') {
              totalOpex += parseFloat(milestone?.opex);
              totalCapex += parseFloat(milestone?.capex);
              totalExpenditure += parseFloat(milestone?.totalexp);
            }
            totalRelations += parseFloat(milestone?.total_relations);
            symbol = milestone?.currency;
          });

          const {
            type,
            portfolio_id,
            portfolioname: name,
            start,
            end,
            progress,
            project,
            color,
            count_items,
            data: dataItem,
            currency,
          } = item;

          const id = portfolio_id + "_" + scenarioData.id;
          dataFinal.push({
            type: "project",
            istask: "YES",
            textColor: color,
            id,
            name,
            start: new Date(start),
            end: new Date(end),
            color,
            count_items,
            progress,
            project,
            hideChildren: true,
            opex: totalOpex,
            capex: totalCapex,
            totalexp: totalExpenditure,
            currency: currency,
            noofRelation: totalRelations,
            relation_modulename: scenarioData?.relation_modulename,
          });

          if (dataItem.length) {
            dataItem.map((item1) => {
              const {
                type,
                item_id,
                displayname,
                start,
                end,
                progress,
                project,
                opex,
                capex,
                totalexp,
                expiry,
                relation_modulename,
                total_relations,
                currency,
              } = item1;

              const id1 = item_id + "_" + portfolio_id + "_" + scenarioData.id;

              //if (!dataFinal.some((item) => item.id === id1)) {

              dataFinal.push({
                type,
                id: id1,
                name: <Flex gap={4} title={displayname}>
                  <a 
                    target="_blank"
                    href={
                      `/portfolio/` +
                      relation_modulename +
                      `/` +
                      scenarioData?.id +
                      `/` +
                      item_id
                    }
                    rel="noreferrer"
                  >
                    <Typography.Text
                      ellipsis={true}
                      title={displayname}
                      style={{fontSize:'revert',color:'revert',maxWidth: (item1?.item_type==="Modified")?250:350 }}
                    >
                      {displayname}
                    </Typography.Text>
                  </a>
                  <WithChangeType action={item1?.item_type}/>
                </Flex>,
                start: new Date(start),
                end: new Date(end),
                progress: id,
                project: id,
                opex: opex,
                capex: capex,
                totalexp: totalexp,
                link: false,
                currency: currency,
                noofRelation: total_relations,
                relation_modulename: relation_modulename,
              });
              //}
            });
          }
        }
      });
    });

    //end  Data Processing  for barchart
    setFinalChartData(dataFinal);
  };

  useEffect(()=>{
    setIsChecked(compareForm?.isChecked)
    setSelectedPortfolio(compareForm?.portfolio || [])
    sessionStorage.setItem(keySession2, JSON.stringify(compareForm?.portfolio))
  },[compareForm])

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <div className="container-fluid">
        <Layout>
          <Layout.Content className="business-capability-landscape-report">
            <CompareHeader location={location}/>
            <CompareForm portfolioList={portfolioList} setCompareForm={setCompareForm} compareForm={compareForm}/>
            <CompareScenarioCard
              finalChartData={finalChartData}
              isChecked={isChecked}
              summaryData={summaryData}
              setSummaryData={setSummaryData}
              selectedPortfolio={selectedPortfolio}
            />
          </Layout.Content>
        </Layout>
      </div>
    </React.Fragment>
  );
}

export default CompareScenarioV3;
