import React from 'react';
import './StepNavigator.css'; // Assuming you'll add some custom styles for the step flow

const StepNavigator = ({ steps, currentStep }) => {
 
  return (
    // <div className="step-container">
    //   {steps.map((step, index) => (
    //     <div
    //       key={index}
    //       className={`step ${index === currentStep ? 'active' : ''}`}
    //     >
    //       <div className="step-label">{step}</div>
    //       {index !== steps.length - 1 && <div className="arrow" />}
    //     </div>
    //   ))}
    // </div>

    <div className="container">	
        <div className="wrapper">	
        <div className="arrow-steps clearfix">  
              {steps.map((step, index) => (
                    <div className={`step ${index <= currentStep ? 'current' : ''}`} key={Math.random()}> <span> {step} </span> </div>
                ))}
        </div>
            
        </div>
        </div>


  );
};

export default StepNavigator;
