import React, { useEffect, useRef, useState } from 'react';
import { Layout, Col, Row, Button, Flex, Dropdown, Popover  } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import RDlogo from '../../../assets/images/RD-logo-01.jpeg';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import GlobalSearch from './GlobalSearch';
import "bootstrap";
import SideMenu from '../sideBar/SideMenuItems';
import SideBar from '../sideBar/SideBar';
// import SideBar from '../sideBar/SideBar';
import UserService from '../../../services/UserService';
import { BellOutlined } from '@ant-design/icons';
import NotificationHeader from './NotificationHeader';
import { auto } from '@popperjs/core';
import GlobalSearchV2 from './GlobalSearchV2';
import { usePortfolioSetting } from './../../../Context/portfolioSettingProvider';



const { Header } = Layout;



function AppHeader() {
    const { state } = usePortfolioSetting();
    const headerRef = useRef(null);
    const imgRef = useRef(null);
    // const [collapsed, setCollapsed] = useState(false);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        let menuTemp = [];
        //menuTemp= await SideMenu();

        SideMenu().then((result) => {
            menuTemp = result;
            setMenu((menuTemp?.length && menuTemp) || []);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SideMenu,state?.data]);

    let rightMenuWidth = () => {
        if(window?.innerWidth>900){
            return '320px';
        }else{
            return 'none';
        }        
    }

    return (
        <>
            <Header className="header" ref={headerRef}>
                <nav className="menuBar">
                    <Row>
                        <Col flex={`auto`} className="logo d-flex align-items-center">
                            <Flex justify='flex-start' align='center' className='w-100'>
                                <div style={{width:(imgRef?.current?.clientWidth || 0)+175}}>
                                    <Link to="/">
                                        <img src={RDlogo} alt="logo" ref={imgRef}/>
                                        <h4>Connected Architecture</h4>
                                    </Link>
                                </div>
                                <div className='w-100'>
                                    <SideBar menus={menu} />
                                </div>
                            </Flex>
                        </Col>
                        <Col flex={`${window?.innerWidth*18/100}px`} className={`${window?.innerWidth>500?'':'d-none'}`}>
                            <GlobalSearch />
                        </Col>
                        <Col flex={rightMenuWidth()}>
                            <HeaderMenus menus={HeaderMenusItems}/>
                        </Col>
                    </Row>
                </nav>
            </Header>
            <div style={{width:`100%`,minHeight:((headerRef?.current?.clientHeight/2 || 5)-5)}}></div>
        </>
    );
}

export default AppHeader;
