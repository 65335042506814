import React, { useState, useEffect } from 'react';
import { Column } from '@antv/g2plot';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';
import { useNavigate } from 'react-router';
import { getMenuPortfolio } from '../../../utils/settingCommon';

const HomePortfolioCharts = () => {
    const navigate = useNavigate();
    const [portfolioResponse, setPortfolioResponse] = useState([]);

    const getPortfolioSummary = async () =>{
        var response = await PortfolioSettingServices.getPortfolioSummary();
        var responses = response.data?.data;
        
        setPortfolioResponse(responses);
    };

    useEffect(() => {
        getPortfolioSummary();
    }, []);

    useEffect(() => {

        const barPlot = new Column('portfolio-chart-3', {
            data:portfolioResponse,
            xField: 'name',
            yField: 'count',
            // barWidthRatio: 0.4, // Adjust the ratio to control spacing
            color: ({name}) => {
                return portfolioResponse.find(f=>f.name===name)?.color;
            },
            label: {
                visible: true,
                position: 'top', // 'top', 'bottom', 'middle'
                onClick:(event)=>{
                    console.log(event);
                    
                }
            },
            interactions: [{ type: 'element-active' }],
            xAxis: {
                label: {
                    autoHide: false,
                    visible: true,
                    autoRotate: false,
                    style: {
                        fill: '#000', // Text color
                        fontSize: 12, // Font size
                        textOverflow:"hidden",
                    },
                    transform: [
                        {
                          type: 'overlapHide',
                        },
                    ],
                    formatter: (text) => {
                        if(window?.innerWidth-300 > portfolioResponse?.length*110){
                            return text;
                        }else{
                            return text.split(' ').join('\n');
                        }
                        
                    },
                    onClick:(event)=>{
                        console.log(event);
                        
                    }
                },
            },
            autoFit: false,
        });
        
        barPlot.render();

        // Add click event listener
        barPlot.on('element:click', (event) => {
            const { data } = event;
            let navData = getMenuPortfolio({name:data?.data?.name});
            navigate(navData?.route)
        });

        // Cleanup on component unmount
        return () => {
            barPlot.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioResponse]);
    

    return <React.Fragment>
        <div style={{overflow:"auto"}}>
            <div id="portfolio-chart-3" style={{minWidth:portfolioResponse?.length*100}}/>
        </div>
    </React.Fragment>
}
export default HomePortfolioCharts;
