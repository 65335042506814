import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Collapse,
  message,
  Spin,
  Tooltip,
  Switch,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  ExclamationCircleFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { normalizeString } from "../../utils/linksCommon";
import {
  changeFirstLetter,
  portfolioTypes,
  restrictedTexts,
} from "../../utils/Common";
import PortfolioSectionServices from "../../services/services/PortfolioSectionService";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import PortfolioPropertyServices from "../../services/services/PortfolioPropertyService";
import { getDynamicMessage } from "../../utils/businessApplication";
import { useDebounce } from "use-debounce";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import TemplateServices from "../../services/services/TemplateService";
import { checkRole } from "../../helper/useUserData";
const { confirm } = Modal;
const { Option } = Select;
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const GlobalTemplate = ({
  selectedMenuText,
  selectedMenu,
  item,
  fetchAllPortfolio,
  setUpdatedMenu,
  activeTab,
}) => {
  const { state, dispatch } = usePortfolioSetting();
  const [formSection] = Form.useForm();
  const [formProperty] = Form.useForm();
  const [formSectionOpen, setFormSectionOpen] = useState(false);
  const [formNewPropertyOpen, setFormNewPropertyOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sectionsData, setSectionsData] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState("");
  const [isEditSection, setIsEditSection] = useState(false);
  const [isEditProperty, setIsEditProperty] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [showSelectFields, setShowSelectFields] = useState(false);
  const [activePanel, setactivePanel] = useState([]);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [portfolioTypeOptions, setPortfolioTypeOptions] = useState(
    portfolioTypes?.map((val,index) => {
      return {
        key:index,
        value: val,
        label: normalizeString(val),
        //disabled: val !== "Multi select",
      };
    })?.sort((a, b) => (a.value === "Multi select" ? -1 : 1))
  );
  const [callParentApi, setCallParentApi] = useState(false);
  const [initialPropertyOptions, setInitialPropertyOptions] = useState([]);
  const [removedPropertyOptions, setRemovedPropertyOptions] = useState([]);
  const [list, setList] = useState([]);

  const updateMenuItems = () => {
    setUpdatedMenu({ id: selectedMenu, name: selectedMenuText });
  };

  const fetchSectionOption = (data) => {
    let options = [];
    if (data && data?.length) {
      data?.map((itemOption) => {
        const option = {
          value: itemOption?.id,
          label: normalizeString(itemOption?.name),
          portfolioId: itemOption?.portfolioId,
        };
        options.push(option);
      });
    }
    setSectionOptions(options);
  };

  useEffect(() => {
    updateMenuItems();
    setSectionsData(item?.portfolioSections || []);
    fetchSectionOption(item?.portfolioSections || []);
  }, [item]);

  useEffect(() => {
    setactivePanel([]);
  }, [selectedMenu, activeTab]);

  useEffect(() => {
    const activeWindow = localStorage.getItem("activePanelCommon");
    const splitValues = activeWindow ? activeWindow.split(",") : [];
    if (activeWindow) {
      setactivePanel(splitValues);
    }
  
    const handleUnload = () => {
      localStorage.removeItem("activePanelCommon");
      // You can perform any cleanup actions here, like clearing local storage, or sending analytics data
    };
    // Add the event listener for beforeunload
    // window.addEventListener('beforeunload', handleUnload);

    return () => {
      // console.log("Component is unmounted");
      // Remove the event listener when the component unmounts
      window.addEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    if (callParentApi) {
      fetchAllPortfolio();
      setCallParentApi(!callParentApi);
    }
  }, [callParentApi]);

  const handleSectionSubmit = async (formData) => {
    let keyz = activePanel;
    try {
      setLoadingSection(true);
      const newSection = { ...formData, portfolioId: selectedMenu };
      let response;
      if (isEditSection) {
        response = await PortfolioSectionServices.update(
          formData?.id,
          newSection
        );
        setIsEditSection(!isEditSection);
        //message.success(`Section ${formData?.name} Updated Successfully.`);
        message.success(`Updated Successfully.`);
      } else {
        response = await PortfolioSectionServices.create(newSection);
        //message.success(`Section ${formData?.name} Created Successfully.`);
        message.success(`Created Successfully.`);
      }
      console.log("response-section", response?.data);
      setFormSectionOpen(!formSectionOpen);
      setTimeout(() => {
        formSection.resetFields();
        setCallParentApi(!callParentApi);
      }, 1000);
    } catch (error) {
      message.error(error?.message);
    } finally {
      setLoadingSection(false);
      setactivePanel(keyz);
    }
  };

  const setFormNewPropertySubmit = async (formData) => {
    try {
      const newSection = {
        ...formData,
        // name: await changeFirstLetter(formData?.name, true),
        portfolioId: selectedMenu,
        propertyOptions:
          formData?.propertyOptions && removedPropertyOptions
            ? [...formData?.propertyOptions, ...removedPropertyOptions]
            : [],
      };

      setLoadingProperty(true);
      if (isEditProperty) {
        await PortfolioPropertyServices.update(formData?.id, newSection);
        setIsEditProperty(!isEditProperty);
        //message.success(`Property ${formData?.name} Updated Successfully.`);
        message.success(`Updated Successfully.`);
      } else {
        await PortfolioPropertyServices.create(newSection);
        //message.success(`Property ${formData?.name} Created Successfully.`);
        message.success(`Created Successfully.`);
      }
      setFormNewPropertyOpen(!formSectionOpen);
      setTimeout(() => {
        setRemovedPropertyOptions([]);
        formProperty.resetFields();
        setCallParentApi(!callParentApi);
      }, 1000);
    } catch (error) {
      message.error(
        getDynamicMessage(error?.response?.data?.error) || error?.message
      );
    } finally {
      setLoadingProperty(false);
    }
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  const getTemplateList = async () => {
    const list = await TemplateServices.getTemplateList();
    setList(list?.data?.data);
    console.log("List", list);
  };

  const SaveTemplateData = async (formData) => {
    try {
      const newSection = {
        ...formData,
        templateOption:
          formData?.propertyOptions && removedPropertyOptions
            ? [...formData?.propertyOptions, ...removedPropertyOptions]
            : [],
      };

      setLoadingProperty(true);

      if (isEditProperty) {
        await TemplateServices.updateTemplate(formData?.id, newSection);
        setIsEditProperty(!isEditProperty);
        //setIsEditing(false);

        message.success(`Updated Successfully.`);
      } else {
        await TemplateServices.createTemplate(newSection);
        message.success(`Created Successfully.`);
      }
      //setFormNewPropertyOpen(!formSectionOpen);
      setTimeout(() => {
        setRemovedPropertyOptions([]);
        formProperty.resetFields();
        setCallParentApi(!callParentApi);
        getTemplateList();
      }, 1000);
    } catch (error) {
      //message.error("Error Occured " + error);
      message.error( "Attribute name already exists");

      console.log("Error", error);
      //   message.error(
      //     getDynamicMessage(error?.response?.data?.error) || error?.message
      //   );
    } finally {
      setLoadingProperty(false);
    }
  };

  const DeleteButtons = ({ item, isProperty = false }) => {
    const disable = isProperty && item?.isRestricted;
    const iconColor = isProperty && item?.isRestricted ? "#A9A9A9" : "#207dff";

    return (
      <div>
         
        <Tooltip
          title={
            isProperty && item?.isRestricted
              ? `Property ${item?.name} is restricted your not able to delete`
              : ""
          }
          trigger="hover"
        >
          <Button
            icon={
              <DeleteTwoTone
                className={`cursor-thumb`}
                twoToneColor={iconColor}
                onClick={() => {
                  isProperty ? showConfirm(item, false) : showConfirm(item);
                }}
              />
            }
            type="text"
            disabled={disable}
            className="border-none"
          />
        </Tooltip>
      </div>
    );
  };

  const EditSection = (item) => {
    setFormSectionOpen(true); // Open the edit section modal
    setIsEditSection(true);
    formSection.setFieldsValue({
      id: item?.id,
      name: item?.name,
    });
    // Prevent the collapse by setting the collapse state to true
    // when opening the edit section modal
    setCollapsedSections((sections) => ({ ...sections, [item.id]: false }));
  };

  const EditProperty = async (item) => {
 
    setShowSelectFields(
      item?.type === "Single select" || item?.type === "Multi select"
    );
    setFormNewPropertyOpen(true); // Open the edit property modal
    setIsEditProperty(true);
    formProperty.setFieldsValue({
      id: item?.id, 
      type: item?.type,
      //name: await changeFirstLetter(item?.name, false),
      templatename: item?.templatename,
      propertyOptions: item?.templateOption,
      templateOption: item?.templateOption, 

    });
    // Prevent the collapse by setting the collapse state to true
    // when opening the edit property modal
    // setCollapsedSections((sections) => ({
    //   ...sections,
    //   [item.sectionId]: false,
    // }));
  };

  const showConfirm = (item, Issection = true) => {
    confirm({
      title: `Are you sure want to delete "${item?.templatename}" ${"attribute"} once deleted it can't be recovered?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let result = await TemplateServices.deleteTemplate(item.id);
          if (result) {
            getTemplateList();
          } else {
            message.error(result?.data?.msg);
          } 
 
        } catch (error) {
          message.error(error?.response?.data?.error || error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showConfirmPropertyOption = ({ item, remove, name }) => {
    confirm({
      title: `Are you sure want to delete "${item?.option}" once deleted it can't be recovered?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          setRemovedPropertyOptions((prev) => [...prev, item]);
          remove(name, item);
        } catch (error) {
          console.error("error", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const EditDeleteButtons = ({ item, isProperty = false }) => {
    const disable = isProperty && item?.isRestricted;
    const iconColor = isProperty && item?.isRestricted ? "#A9A9A9" : "#207dff";

    return (
      <div>
        <Tooltip
          title={
            isProperty && item?.isRestricted
              ? `Property ${item?.templatename} is restricted your not able to modified.`
              : ""
          }
          trigger="hover"
        >
          <Button
            icon={
              <EditTwoTone
                twoToneColor={iconColor}
                className={`cursor-thumb`}
                onClick={() => {
                 // isProperty ? EditProperty(item) : EditSection(item);
                 EditProperty(item);
                }}
              />
            }
            type="text"
            disabled={disable}
            className="border-none"
          />
        </Tooltip>
        <Tooltip
          title={
            isProperty && item?.isRestricted
              ? `Property ${item?.templatename} is restricted your not able to delete`
              : ""
          }
          trigger="hover"
        >
          <Button
            icon={
              <DeleteTwoTone
                className={`cursor-thumb`}
                twoToneColor={iconColor}
                onClick={() => {
                  isProperty ? showConfirm(item, false) : showConfirm(item);
                }}
              />
            }
            type="text"
            disabled={disable}
            className="border-none"
          />
        </Tooltip>
      </div>
    );
  };

  const PropertyCard = ({ item }) => {
    return (
      <div className="card card-body p-2 mb-2 setting_carditem" key={item?.id}>
        <div className="d-flex justify-content-between">
          {/* <div>{normalizeString(item?.displayName)}</div> */}
          <div>{item?.option}</div>

          <div className="d-flex justify-content-end">
            {/* <EditTwoTone
              className={`cursor-thumb`}
              onClick={() => {
                EditProperty(item);
              }}
            /> */}
            &emsp;
            {/* <DeleteTwoTone
              className={`cursor-thumb`}
              onClick={() => {
                showConfirm(item, false);
              }}
            /> */}
            {/* <EditDeleteButtons item={item} isProperty={true} /> */}
            {/* <DeleteButtons item={item} isProperty={true} /> */}
          </div>
        </div>
      </div>
    );
  };

  const SectionCard = ({ item, id, isFirst, index }) => {
    const [sortProperties, setSortProperties] = useState("ASC");
    const [searchText, setSearchText] = useState("");
    const [currentTabItem, setcurrentTabItem] = useState(null);
    const [newCurrentTabItem, setNewCurrentTabItem] = useDebounce(
      currentTabItem,
      200
    );
 
    useEffect(() => { 
      
      if (item?.templateOption) { 
        setcurrentTabItem(item.templateOption);
      }
    }, [item]);

    const searchByTextOrder = (value) => {
        console.log("testONe", value);
        console.log("testONe", item.templateOption);
      if (value === undefined || value === null || value === "") {
        // If no value is provided, reset to the original list
        setcurrentTabItem(item.templateOption); // Assuming you have an originalTabItem to reset to
      } else {
        // Filter items by name including the value
        
        //const filteredItems = currentTabItem.filter((item) =>
          const filteredItems = item.templateOption.filter((it) =>
            it.option?.toLowerCase().includes(value?.toLowerCase())
        );
        setcurrentTabItem(filteredItems);
 
      }
    };

    const aceOrder = () => {
      setcurrentTabItem(
        [...currentTabItem]?.sort((a, b) => {
          const nameA = a?.option?.toUpperCase();
          const nameB = b?.option?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      );
    };

    const deceOrder = () => {
      setcurrentTabItem(
        [...currentTabItem]?.sort((a, b) => {
          const nameA = a?.option?.toUpperCase();
          const nameB = b?.option?.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        })
      );
    };

    return (
      <div className="my-2" key={id}>
        <Collapse
          activeKey={activePanel}
          onChange={(activeKeys) => {
            // setCollapsedSections(
            //   activeKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {})
            // );
            setactivePanel(activeKeys);
            localStorage.setItem("activePanelCommon", activeKeys);
            console.log("activePanelCommon", activeKeys);
          }}
        >
          <Panel
            header={
              <div onClick={(e) => e.stopPropagation()}>
                {sortProperties === "DESC" ? (
                  <SortAscendingOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setSortProperties("ASC");
                      aceOrder();
                    }}
                  />
                ) : (
                  <SortDescendingOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setSortProperties("DESC");
                      deceOrder();
                    }}
                  />
                )}
                &nbsp;{ item?.templatename }
              </div>
            }
            key={id}
            extra={
              <div
                className="d-flex justify-content-end"
                onClick={(e) => e.stopPropagation()}
              >
                <EditDeleteButtons item={item} isProperty={false} />
                &emsp;
                <Input.Search
                  style={{
                    width: "150px",
                    marginLeft: "20px",
                  }}
                  placeholder="Search"
                  onChange={(e) => {
                    if(e.target.value.length === 0){
                      searchByTextOrder(e.target.value);
                    }
                  }}
                  onSearch={(value) => {
                    searchByTextOrder(value);
                    setSearchText(value);
                  }}
                  onPressEnter={(e) => {
                    e.stopPropagation()
                    searchByTextOrder(e.target.value);
                  }}
                ></Input.Search>
              </div>
            }
          >
            <div
              className={`overflow-y p-2 mb-2`}
              style={{ height: window.innerHeight / 3 }}
            >
              <Droppable
                key={id}
                droppableId={`${id}`}
                index={index}
                type="child"
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {/* {(newCurrentTabItem &&
                      newCurrentTabItem?.length &&
                      newCurrentTabItem
                        ?.sort((a, b) => a.position - b.position)
                        ?.map((val, index) => (
                          <Draggable
                            key={`${val?.id}`}
                            draggableId={`${val?.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <PropertyCard item={val} key={index} />
                              </div>
                            )}
                          </Draggable>
                        ))) || (
                      <div className="text-base font-bold mt-4">
                        No items found.
                      </div>
                    )} */}

                    {(currentTabItem &&
                      currentTabItem?.length &&
                      currentTabItem
                        ?.sort((a, b) => a.position - b.position)
                        ?.map((val, index) => (
                        <PropertyCard item={val} key={index} />
                      ))) || (
                      <div className="text-base font-bold mt-4">
                        No items found.
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };

  // Custom validation rule to check if the input value matches the restricted text
  const validateRestrictedText = (_, value) => {
    if (restrictedTexts.includes(value?.toLowerCase())) {
      return Promise.reject(`This ${value} is not allowed`);
    }
    return Promise.resolve();
  };

  const PropertyOption = ({
    field,
    fields,
    index,
    remove,
    propertyOptionsItem,
    isEditProperty,
  }) => {
    const { key, name } = field;

    // Custom validation rule to check if the name starts with a number or special character
    const validateName = (_, value) => {
      if (!/^[A-Za-z]/.test(value)) {
        return Promise.reject("Name should start with a letter");
      }
      return Promise.resolve();
    };

    const handleRemove = () => {
      const hasId = propertyOptionsItem?.id;
      if (!hasId) {
        if (fields.length > 1) {
          remove(name);
        } else {
          message.error("At least one option is required");
        }
      } else {
        // Update form data with action "delete"
        const newFormData = {
          ...propertyOptionsItem,
          action: "delete",
        };
        showConfirmPropertyOption({ item: newFormData, remove, name });
      }
    };

    return (
      <Form.Item
        {...formItemLayoutWithOutLabel}
        required={false}
        key={`${key}-${index}`}
      >
        <Form.Item
          {...field}
          //name={[name, "name"]}
          name={[name, "option"]}
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            { required: true, message: "Please enter!" },
            // { validator: validateName },
          ]}
          noStyle
          key={`${key}-name-${index}`}
        >
          <Input
            defaultValue=""
            style={{ width: "90%", marginRight: "10px" }}
          />
        </Form.Item>
        {fields.length > 1 && (
          <MinusCircleOutlined
            className="dynamic-delete-button"
            onClick={handleRemove}
          />
        )}
        <Form.Item
          {...field}
          name={[name, "portfolioId"]}
          initialValue={selectedMenu}
          style={{ display: "none" }}
          key={`${key}-action-${index}`}
        >
          <Input />
        </Form.Item>
        {isEditProperty && propertyOptionsItem?.id ? (
          <Form.Item
            {...field}
            name={[name, "action"]}
            initialValue="update"
            style={{ display: "none" }}
            key={`${key}-action-${index}`}
          >
            <Input />
          </Form.Item>
        ) : isEditProperty ? (
          <Form.Item
            {...field}
            name={[name, "action"]}
            initialValue="add"
            style={{ display: "none" }}
            key={`${key}-action-${index}`}
          >
            <Input />
          </Form.Item>
        ) : (
          <></>
        )}
      </Form.Item>
    );
  };

  const PropertiesOptionsList = ({ propertyOptionsValues, isEditProperty }) => {
    const handleAdd = (add) => {
      add();
    };

    return (
      <Form.List
        name="propertyOptions"
        rules={[
          {
            validator: async (_, propertyOptions) => {
              if (!propertyOptions || propertyOptions.length < 1) {
                return Promise.reject(new Error("At least 1 field required"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item>
              <label className="fw-500">Options</label>
              <Button
                type="link"
                onClick={() => handleAdd(add)}
                className="pull-right border-0"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
            {fields.map((field, index) => (
              <PropertyOption
                key={`${field.key}-${index}`}
                field={field}
                fields={fields}
                index={index}
                remove={remove}
                propertyOptionsItem={propertyOptionsValues[index]}
                isEditProperty={isEditProperty}
              />
            ))}
          </>
        )}
      </Form.List>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    return result2;
  };

  const handleReorderSectionSubmit = async (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    result2.map((sec, index) => {
      sec.position = index + 1;
      delete sec.properties;
      delete sec.createdAt;
      delete sec.isDeleted;
      delete sec.updatedAt;
    });
    // let formdata = removed;
    let response = null;
    setLoadingSection(true);
    response = await PortfolioSettingServices.updateSectionOrdering(result2);
    // message.success(`Section ${removed?.name} Updated Successfully.`);
    message.success(`Updated Successfully.`);
    const res = await PortfolioSettingServices.getList({ page: 1, limit: 100 });
    const newData = res.data.data;
    await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
  };
  const handleReorderPropertiesSubmit = async (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    result2.map((prop, index) => {
      prop.position = index + 1;
      delete prop.createdAt;
      delete prop.isDeleted;
      delete prop.updatedAt;
    });
    // let formdata = removed;
    let response = null;
    setLoadingSection(true);
    response = await PortfolioSettingServices.updatePropertiesOrdering(result2);
    // message.success(`Section ${removed?.name} Updated Successfully.`);
    message.success(`Updated Successfully.`);
    const res = await PortfolioSettingServices.getList({ page: 1, limit: 100 });
    const newData = res.data.data;
    await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;

    if (
      sourceDroppableId === destinationDroppableId &&
      sourceDroppableId != "parents"
    ) {
      // Reordering items within the same list
      const list = sectionsData.find((p) => p.id == sourceDroppableId);
      const reorderedList2 = await reorder(
        list?.properties,
        sourceIndex,
        destinationIndex
      );
      const reorderedItems = await sectionsData.map((p) =>
        p.id == sourceDroppableId ? { ...p, properties: reorderedList2 } : p
      );
      // setSectionsData(reorderedItems);
      handleReorderPropertiesSubmit(
        list?.properties,
        sourceIndex,
        destinationIndex
      );
    } else {
      // Moving items between parent lists
      const reorderedItems = await reorder(
        sectionsData,
        result.source.index,
        result.destination.index
      );
      setSectionsData(reorderedItems);
      handleReorderSectionSubmit(
        sectionsData,
        result.source.index,
        result.destination.index
      );
    }
  };

  return (
    <div key={selectedMenu}>
      {/* <div className="h5 mb-0">{selectedMenuText} Properties</div> */}
      <div>
        (Here you can create attributes that will appear in {selectedMenuText}{" "}
        creation screen)
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="parents" type="parent">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {(list &&
                list?.length &&
                list?.map((value, index) => (
                  <SectionCard
                    item={value}
                    id={value?.id}
                    //   isLast={index === sectionsData?.length - 1}
                    //   isFirst={index === 0}
                    //   index={index}
                  />
                ))) || (
                <div className="text-base font-bold mt-4">No items found.</div>
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-between items-center">
        {/* <Button
          type="primary"
          icon={<PlusOutlined />}
          className="pull-left"
          onClick={() => {
            setFormSectionOpen(!formSectionOpen);
          }}
        >
          Add New Section
        </Button> */}
        {checkRole("save") && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={item?.portfolioSections?.length === 0}
            onClick={() => {
              console.log("item?.id", item);
              formProperty.setFieldsValue({
                type: portfolioTypes[0],
              });
              setDefaultOption(normalizeString(item?.name) || "");
              setFormNewPropertyOpen(!formSectionOpen);
            }}
            className="my-2"
          >
            {isEditProperty ? "Update Attributes" : "Add New Attributes"}
          </Button>
        )}
      </div>

      <Modal
        closable
        title={`${isEditSection ? "Update Section" : "Add Section"}`}
        destroyOnClose
        open={formSectionOpen}
        onCancel={() => {
          setIsEditSection(false);
          setFormSectionOpen(!formSectionOpen);
          formSection.resetFields();
        }}
        footer={false}
        requiredMark={false}
        maskClosable={false}
      >
        <Form
          form={formSection}
          onFinish={handleSectionSubmit}
          autoComplete="off"
          footer={false}
        >
          <div className="card card-body">
            {isEditSection && (
              <Form.Item
                {...formItemLayout}
                className="my-3"
                label="ID"
                name="id"
                style={{ display: "none" }}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              {...formItemLayout}
              className="my-3"
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <Row className="mt-3">
            <Col span={24}>
              <Button
                type={`primary`}
                className={`w-100`}
                htmlType="submit"
                disabled={formSection.submitting || loadingSection}
              >
                {formSection.submitting || loadingSection ? (
                  <Spin spinning={loadingSection} />
                ) : (
                  "Save"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        closable
        title={`${isEditProperty ? "Update Attributes" : "Add New Attributes"}`}
        destroyOnClose
        open={formNewPropertyOpen}
        onCancel={() => {
          setRemovedPropertyOptions([]);
          setIsEditProperty(false);
          setFormNewPropertyOpen(!formNewPropertyOpen);
          setShowSelectFields(false);
          formProperty.resetFields();
        }}
        footer={false}
        maskClosable={false}
      >
        <Form
          form={formProperty}
          onFinish={SaveTemplateData}
          initialValues={{
            propertyOptions: isEditProperty ? initialPropertyOptions : [],
            isRequired: false,
          }}
          autoComplete="off"
          footer={false}
          requiredMark={false}
        >
          <div className="card card-body">
            {isEditProperty && (
              <Form.Item
                {...formItemLayout}
                className="my-3"
                label="ID"
                name="id"
                style={{ display: "none" }}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Name"
              name="templatename"
              rules={[
                {
                  required: true,
                  message: "Please input attribute name!",
                },
                { validator: validateRestrictedText },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Type"
              name="type"
              // rules={[
              //   { required: true, message: "Please select!" },
              //   ({ getFieldValue }) => ({
              //     validator(_, value) {
              //       if (value === "Multi select") {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(
              //         new Error("Only 'Multi select' is allowed.")
              //       );
              //     },
              //   }),
              // ]}
            >
              <Select
                defaultValue={`${normalizeString("Multi select")}`}
                options={portfolioTypeOptions}
                // Need to update the condition in feature
                disabled={isEditProperty}
                onChange={(value) => {
                  setShowSelectFields(
                    value === "Single select" || value === "Multi select"
                  );
                }}
              />
            </Form.Item>
            {showSelectFields === true && (
              <>
                <PropertiesOptionsList
                  propertyOptionsValues={
                    formProperty?.getFieldValue()?.propertyOptions || []
                  }
                  isEditProperty={isEditProperty}
                />
              </>
            )}
          </div>
          <Row className="mt-3">
            <Col span={24}>
              <Button
                type={`primary`}
                className={`w-100`}
                htmlType="submit"
                disabled={formProperty.submitting || loadingProperty}
              >
                {formProperty.submitting || loadingProperty ? (
                  <Spin spinning={loadingProperty} />
                ) : (
                  "Save"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default GlobalTemplate;
