
import ScenarioSvg from './../../assets/Scenario.svg';
import CreatingDiagramSvg from './../../assets/FlowDiagram.svg';
import BusinessCapabilityMappingSvg from './../../assets/BusinessCapabilityMapping.svg';
import SettingSvg from './../../assets/Setting.svg';
import DashboardSvg from './../../assets/Dashboard.svg';

import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { PageTitleHeading } from '../../utils/Common';
const HomeQuickLink = ( {person}) => {
    // const encode = plantumlEncoder.encode(props.src);
  

    return (
    <> 
        <div className='quickGuid home-quick-links-intro'>
            <PageTitleHeading text={"Quick Links"} className={`my-1`} />
            <Link to="/portfolio/BusinessApplication">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={CreatingDiagramSvg} alt={`Application Portfolio Report`} className="iconimg"/></Col> */}
                    <Col span={20}>Application</Col>
                </Row>
            </Link>
            <Link to="/portfolio/BusinessCapability">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={BusinessCapabilityMappingSvg} alt={`Business Capability`} className="iconimg"/></Col> */}
                    <Col span={20}>Business Capability</Col>
                </Row>
            </Link>
            <Link to="/portfolio/BusinessProcesses">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={CreatingDiagramSvg} alt={`Application Integration Landscape`} className="iconimg"/></Col> */}
                    <Col span={20}>Business Process</Col>
                </Row>
            </Link>
            <Link to="/start-new-scenario">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={ScenarioSvg} alt={`Create Scenario`} className="iconimg"/></Col> */}
                    <Col span={20}>Create Scenario</Col>
                </Row>
            </Link>
            {/* {<Link to="/others-settings">
                <Row className="align-items-center">
                    <Col span={4}><img src={SettingSvg} alt={`Settings`} className="iconimg"/></Col>
                    <Col span={20}>Settings</Col>
                </Row>
            </Link>} */}
            <Link to="/ca-meta-model">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={DashboardSvg} alt={`Settings`} className="iconimg ml-1"/></Col> */}
                    <Col span={20}>CA Metamodel</Col>
                </Row>
            </Link>
			<Link to="/apis-list">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={DashboardSvg} alt={`Settings`} className="iconimg ml-1"/></Col> */}
                    <Col span={20}>Rest APIs</Col>
                </Row>
            </Link>
			<Link to="/graph-ql-apis-list">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={DashboardSvg} alt={`Settings`} className="iconimg ml-1"/></Col> */}
                    <Col span={20}>GraphQL APIs</Col>
                </Row>
            </Link>
            <Link to="/industry-type">
                <Row className="align-items-center">
                    {/* <Col span={4}><img src={DashboardSvg} alt={`Settings`} className="iconimg ml-1"/></Col> */}
                    <Col span={20}>Industry Type</Col>
                </Row>
            </Link>
        </div>
        </>
    );
};
export default HomeQuickLink;