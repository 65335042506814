import './Home.scss';
import { Col, Collapse, Flex, Layout, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import HomeNotifications from './home-v2/HomeNotifications';
import HomeQuickLink from './HomeQuickLink';
import HomeShortVideoGuide from './home-v2/HomeShortVideoGuide';
import HomePageServices from '../../services/services/HomePageServices';
import { useNotificationProvider } from '../../Context/NotificationProvider';
import { PageTitleHeading } from '../../utils/Common';
import HomePortfolioCharts from './home-v2/HomePortfolioCharts';
import LifeCycleStatusChart from './home-v2/LifeCycleStatusChart';
import ScenarioChart from './home-v2/ScenarioChart';
import RecentlyViewedV2 from './home-v2/RecentlyViewedV2';
import HomeMyReports from './home-v2/HomeMyReports';

const HomeV3 = ({...props}) => {

    const [notificationData, setNotificationData] = useState([]);
    const [lifeCyclechartData, setLifeCycleChartData] = useState([]);
    const [scenarioChartData, setScenarioChartData] = useState([]);

    const { state: notificatioDataProvider, dispatch: dispatchNotificatioData } = useNotificationProvider();

    const getNotificationData = async () => {
        //asda
        const email = localStorage.getItem('email')
        if (email) {
            const data = {
                "userid": email
            }
            const notificationResult = await HomePageServices.getNotificationData(data);
            if (notificationResult.status === 200) {
                setNotificationData(notificationResult?.data)
                await dispatchNotificatioData({ type: "SET_DATA", payload: notificationResult?.data });
            }
            else {
                message.error(notificationResult.message);
            }
        }
    }
 
    const getApplicationLifeCycle_ChartData = async () => {
        const response = await HomePageServices.getApplicationLifeCycleChartData({});

        if (response?.data?.length) {
            setLifeCycleChartData(response?.data);
        } else {

        }
    }
 
    const getScenario_ChartData = async () => {
        const response = await HomePageServices.getScenarioChartData({});

        if (response?.data?.length) {
            setScenarioChartData(response?.data);
        } else {

        }
    } 

    

    useEffect(() => {
        getNotificationData();
        getApplicationLifeCycle_ChartData();
        getScenario_ChartData();
        //Welcome Video
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Layout className='home-v2'>
                <Layout.Content>
                    <div className="container-fluid pt-2 pb-5">
                        <PageTitleHeading text={"Welcome to  Connected Architecture"} className={`intro-welcome-text my-2`}/>
                        <Flex gap={8} vertical>
                            <div className='homePortfolioSummary'>
                                <Collapse defaultActiveKey={['1']} expandIconPosition={'end'} size="small" bordered>
                                    <Collapse.Panel header={<PageTitleHeading text={"Portfolio Summary"} className={`my-0`}/>} key="1">
                                        <HomePortfolioCharts />
                                    </Collapse.Panel>
                                </Collapse>
                            </div>
                            <Row gutter={[8,8]}>
                                <HomeNotifications notificationData={notificationData} getNotificationData={getNotificationData} />
                                <HomeMyReports/>
                                <Col span={12} className='home-application-status-summary-intro'>
                                    <div className='homelifecyclestatus'>
                                        <Collapse defaultActiveKey={['1']} expandIconPosition={`end`} size="small" bordered>
                                            <Collapse.Panel header={<PageTitleHeading text={"Application Status Summary"} className={`my-0`}/>} key="1">
                                                <LifeCycleStatusChart lifeCyclechartData={lifeCyclechartData} />
                                            </Collapse.Panel>
                                        </Collapse>
                                    </div>
                                </Col>
                                <Col span={12} className='home-scenarios-status-summary-intro'>
                                    <div className='homescenarios'>
                                        <Collapse defaultActiveKey={['1']} expandIconPosition={`end`} size="small" bordered>
                                            <Collapse.Panel header={<PageTitleHeading text={"Scenarios Status Summary"} className={`my-0`}/>} key="1" >
                                                <ScenarioChart scenarioChartData={scenarioChartData} />
                                            </Collapse.Panel>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Flex>
                    </div>
                </Layout.Content>
                <Layout.Sider theme='light' className='border-left px-4 pt-2' style={{ minHeight: window?.innerHeight - 50 }} width={300}>
                    <HomeQuickLink />
                    <HomeShortVideoGuide />
                    <RecentlyViewedV2/>
                    {/* <HomeQuickStart /> */}
                </Layout.Sider>
            </Layout>
        </React.Fragment>
    )
}

export default HomeV3
