import { Card, Flex, Form, Select, Slider, Switch, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import { GoBack } from '../../../utils/GoBackHistory'
import moment from 'moment'
import CompareChart from './CompareChart'
import SummaryChart from './SummaryChart'
import { FrownOutlined, SmileOutlined } from '@ant-design/icons'

export const CompareCommon = () => {
  return <></>
}

export const CompareHeader = ({location}) => {
    return (
        <section>
            <div className="headingbx">
                <Flex justify='space-between' align='center' gap={4} className='w-100'>
                    <PageTitleHeading text={"Compare Scenario"} />
                    <Flex gap={8}>
                        <GoBack className={`ml-2 pull-right`} path={location?.state?.from} state={location?.state} back />
                    </Flex>
                </Flex>
            </div>
        </section>
    )
}

export const CompareForm = ({portfolioList,setCompareForm,compareForm}) =>{
    const [filterForm] = Form.useForm();
    const selectDefaultProps = {
        showSearch: true,
        style: { minWidth: "150px" },
        size: "medium",
        allowClear: true,
    };
    return (
        <>
            <section>
              <Flex className="overflow-x pb-2">
                <Form
                    form={filterForm}
                    layout="inline"
                    style={{ maxWidth: "none" }}
                    className="mt-2"
                    onValuesChange={(changedValues, allValues)=>{
                        setCompareForm(allValues)
                    }}
                    initialValues={compareForm}
                >
                  <Form.Item name="isChecked" >
                    <Switch
                        checkedChildren="Timeline"
                        unCheckedChildren="Summary"
                        defaultChecked
                        style={{
                            backgroundColor: compareForm?.isChecked ? "#52c41a" : "#ff4d4f",
                        }}
                    />
                  </Form.Item>

                  <Form.Item name="portfolio" label="Select Portfolio">
                    <Select
                        {...selectDefaultProps}
                        mode="tags"
                        placeholder={"Select Portfolio"}
                        allowClear={true}
                    >
                        {portfolioList?.map((item) => {
                            return (
                            <Select.Option value={item.id} key={Math.random()}>
                                <span className="portfolio-option-color" style={{ "--color": item?.color }} ></span>
                                {item.portfolioname}
                            </Select.Option>
                            );
                        })}
                    </Select>
                  </Form.Item>
                </Form>
              </Flex>
            </section>
        </>
    )
}

export const CustomTooltip = ({ sliderMarks,value }) => {
    return (
        <Tooltip title={ moment(sliderMarks[value]).format('MMM YYYY')}>
            { moment(sliderMarks[value]).format('MMM YYYY')}
        </Tooltip>
    );
}

export const WithChangeType = ({action,}) => { 
    return (
        <>
            <div className="fw-medium scenario_opt">
            {action === "New" ? (
                <span className="scenario-new" title="New">New</span>
            ) : action === "Deleted" ? (
                <span className="scenario-removed" title="Remove">Remove</span>
            ) : action === "Modified" ? (
                <>
                <div className="app-buttons">
                    <span className="scenario-modified" title="Modified">Modified</span>
                </div>
                </>
            ) : action === "No Change" ? (
                <span className="scenario-nochange" title="AS IS">AS IS</span>
            ) : (
                ""
            )}
            </div>
        </>
    );
};

export const CompareScenarioCard = ({finalChartData,isChecked,summaryData,setSummaryData,selectedPortfolio}) => {
    const [limit,setLimit] = useState([0,100]);
    const [sliderMarks,setSliderMarks] = useState([]);

    useEffect(()=>{
        getSliderMarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[summaryData])

    const getSliderMarks = () => {
        let list = [];
        if(summaryData?.length>0){
            summaryData?.forEach((rowData, index) => {
                rowData?.portfolio?.forEach((data)=>{
                    data?.data?.forEach(d=>{
                        list.push(moment(d?.start).format('YYYY-MM'));
                        list.push(moment(d?.end).format('YYYY-MM'));
                    })
                })
            })
        }
        list = [...new Set(list)].sort();

        setSliderMarks(list);
        setLimit([0,list?.length-1])
    }
    const handleIcon1 = <SmileOutlined />;
    const handleIcon2 = <FrownOutlined />;
    return (
        <section>
            <Card size="small">
            {
                sliderMarks?.length>0 && <div>
                    <Typography.Title level={5} className='mb-0 pb-0'>Set Deadline 
                    (
                        <small>
                            {
                                limit?.map((d,index)=>{
                                    return <React.Fragment key={`list-${Math.random()}`}>
                                            <span >{moment(sliderMarks[d]).format('MMM YYYY')}</span>
                                            {index===0 && " - "}
                                        </React.Fragment>
                                })
                            }
                        </small>
                    )
                    </Typography.Title>
                    <Slider 
                        range 
                        marks={
                        sliderMarks?.map(mark=>{
                            return {
                                style: {
                                    fontSize: 'x-small'
                                },
                                tooltip: moment(mark).format('MMM YYYY'),
                            }
                        })} 
                        min={0}
                        max={sliderMarks?.length-1}
                        defaultValue={[0, sliderMarks?.length-1]}
                        style={{width: sliderMarks?.length*35}}
                        tooltip={{formatter:(value)=><CustomTooltip value={value} sliderMarks={sliderMarks} className="my-0"/>}}
                        onChange={(value)=>{
                            setLimit(value)
                        }}
                        handleStyle={[
                            { backgroundColor: '#1890ff', borderColor: '#1890ff',  },
                            { backgroundColor: '#52c41a', borderColor: '#52c41a',  },
                        ]}
                    />
                </div>
            }
            {
                (()=>{
                    if(finalChartData && finalChartData.length>0){
                        if(isChecked){
                            return <CompareChart 
                                limit={limit}
                                sliderMarks={sliderMarks}
                                events={finalChartData} 
                                WithChangeType={WithChangeType}
                            />
                        }else{
                            return <SummaryChart 
                                limit={limit}
                                sliderMarks={sliderMarks}
                                summaryData={summaryData} 
                                setSummaryData={setSummaryData} 
                                selectedPortfolio={selectedPortfolio} 
                                WithChangeType={WithChangeType}
                            />
                        }
                    }else{
                        return "Data not found";
                    }
                })()
            }
            </Card>
        </section>
    )
}