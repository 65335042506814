import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import AppHeader from "../shared/appHeader/AppHeader";
import SideBar from "../shared/sideBar/SideBar";
import SideMenu from "../shared/sideBar/SideMenuItems";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { bottom } from "@popperjs/core";
import LayoutSteps from "./LayoutSteps";
const { Content, Sider } = Layout;

const styleBar = {
    background: "#1f1f1f",
    overflow: "auto",
    //height: window.innerHeight,
    position: "sticky",
    top: 0,
    left: 0,
    // height: "100vh",
};

const AdminLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [menu, setMenu] = useState([]);

    useEffect(()=>{
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/6731bb194304e3196ae005fb/1icd45nig';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
    },[])
    
    useEffect(() => {
        let menuTemp = [];
        //menuTemp= await SideMenu();

        SideMenu().then((result) => {
            menuTemp = result;
            setMenu((menuTemp?.length && menuTemp) || []);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SideMenu]);

    return (
        <>
            {/* <LayoutSteps/> */}
            <Layout>
                <AppHeader />
                <Layout className="master_wrapper">
                    {/* <Sider
                        id="cu-left-sidebar"
                        width="200px"
                        collapsible
                        style={{ ...styleBar }}
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        trigger={ !collapsed ? ( <DoubleLeftOutlined /> ) : ( <DoubleRightOutlined /> ) }
                    >
                        <SideBar menus={menu} collapsed={collapsed} />
                    </Sider> */}
                    <Content>
                        <div className="container-fluid main-wrapper">{children}</div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default AdminLayout;