import React, { useEffect, useRef, useState } from "react";
import { PageTitleHeading } from "../../utils/Common";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Collapse,
  Flex,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import ScenarioServices from "../../services/services/ScenarioServices";
import TextWithTooltip from "./../../utils/TextWithTooltip";
import { useLocation, useNavigate } from "react-router";
import { ClearOutlined, CloseCircleOutlined, ExclamationCircleFilled, MinusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { getScenarioType } from "../../utils/ScenarioCommon";
import LoadingBar from "react-top-loading-bar";
import { RemoveRecentViewed, } from "../../utils/ResentViewedPortfolio";
import { checkRole } from "../../helper/useUserData";
import { SaveRecentlyView_Scenario } from "../../utils/Common";
import ScenarioAddModal from './ScenarioAddModal';
import CompareScenario from './CompareScenario';
import { getMenuPortfolio, isDisabledRole } from "../../utils/settingCommon";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";

const ListScenario = (props) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  //let openedScenarios = sessionStorage.getItem("recentlyOpenScenarios");
  let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
  openedScenarios = JSON.parse(openedScenarios);

  const recentlyOpenScenariosRef = useRef(null);
  const location = useLocation();
  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCurrentSize, setCurrentPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [recentlyOpenScenarios, setRecentlyOpenScenarios] = useState(
    openedScenarios || []
  );
  const [scenarioType, setScenarioType] = useState([]);
  const [emptyText, setemptyText] = useState("Fetching Data");
  const [inputSearch, setInputSearch] = useState('');
  const [searchData, setSearchData] = useState({
    intiativeId: "",
    visibility: "",
    scenarioTypeID: "",
    search: "",
    page: 1,
    limit: pageCurrentSize,
    isScenarioType: true,
    isUser: true,
    approvalstatus: location?.state?.filter?.approvalstatus || null
  });
  const [loading, setLoading] = useState(false);
  const [userDataList, setUserDataList] = useState([]);
  const [addScenarioModal, setAddScenarioModal] = useState(false);
  const [compareScenarioData, setCompareScenarioData] = useState([]);
  const [compareScenarioModal, setCompareScenarioModal] = useState(false);

  useEffect(() => {
    getScenarioType(loading, setLoading, setScenarioType);
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllScenarios({ ...searchData, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  const getAllScenarios = async (filter) => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    setLoading(true);
    let obj = {
      ...filter,
      approvalstatus: filter?.approvalstatus === null ? "" : filter?.approvalstatus, 
    };

    try {
      let response = await ScenarioServices?.getAllScenariosV2(obj);
      const finalResponse = response.data.data;

      if (finalResponse === undefined || null || finalResponse.length === 0) {
        setemptyText("Data Not Found");
        setScenarios([]);
        setTotalRecords(0);
      } else if (finalResponse.length) {
        setScenarios(response?.data?.data);
        setTotalRecords(response?.data?.totalCount);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
    if (ref.current) {
      ref.current.complete();
    }
  };

  const validateUserRight = (collaboratorID, createdBy) => {
    const userEmailID = localStorage.getItem("email") || "";

    if (userDataList && userDataList.length) {
      const CollaboratoremailID = userDataList
        .filter((item) => collaboratorID.includes(item.id))
        .map((item) => ({ id: item.id, email: item.email }));

      if ( userEmailID===createdBy || CollaboratoremailID.some(item => item.email === userEmailID) ){ 
      //if (CollaboratoremailID.some((item) => item.email === userEmailID)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return <Flex gap={4} justify="flex-start" align="center">
          <Checkbox 
            value={record?.id} 
            onChange={(value)=>{ 
              if(value?.target?.checked){
                setCompareScenarioData(prev=>([...prev,record])) 
              }else{
                setCompareScenarioData(prev=>(prev.filter(f=>f.id !==record?.id))) 
              }
            }} 
            {...{checked:compareScenarioData?.find(f=>f.id===record?.id)?true:false}}
          />
          <Typography.Paragraph
            ellipsis={true}
            className="mb-0 pb-0 pointer"
            title={text}
            onClick= {() => {
              SaveRecentlyView_Scenario(record?.id, record?.name);
  
              localStorage.setItem("scenarioName", record?.name);
              setRecentOpenScenario(record);
              navigation("/scenario-list-v2/" + record?.id);
            }}
          >
            {text}
          </Typography.Paragraph>
          {/* <TextWithTooltip text={text} characterLimit={50} /> */}
        </Flex>;
      },
    },
    {
      title: "Initiative",
      //className: "text-center",
      dataIndex: "intiativeId",
      key: "intiativeId",
      render: (text, record, index) => {
        return (
          <TextWithTooltip
            text={record?.initiative?.displayname}
            characterLimit={50}
          />
        );
      },
    },
    {
      title: "Status",
      className: "text-center",
      dataIndex: "approvalstatus",
      key: "approvalstatus",
      width: 150,
      render: (text, record, index) => {
        return <TextWithTooltip text={text} characterLimit={50} />;
      },
    },
    {
      title: "Owner",
      dataIndex: "createdBy",
      key: "createdBy",
      className: "text-center",
      width: 150,
      render: (text, record, index) => {
        if (record?.user?.name) {
          return record?.user?.name;
        }
        return "";
      },
    },
    {
      title: "Collaborator",
      dataIndex: "collaborators_names",
      key: "collaborators_names",
      className: "text-left",
      width: 150,
      render: (text, record, index) => {
        if (text?.length>0) {
          return text?.split(",")?.map((name,i,all)=>{
            return <span className="text-nowrap" key={Math.random()}>{name}{all?.length-1===i ? "" : ","}</span>
          });
        }
        return null;
      },
    },
    {
      title: "Approver",
      dataIndex: "approvers_names",
      key: "approvers_names",
      className: "text-left",
      width: 150,
      render: (text, record, index) => {
        if (text?.length>0) {
          return text?.split(",")?.map((name,i,all)=>{
            return <span className="text-nowrap" key={Math.random()}>{name}{all?.length-1===i ? "" : ","}</span>
          });
        }
        return null;
      },
    },
    {
      title: "Statistics",
      dataIndex: "statistics",
      key: "statistics",
      className: "text-left",
      width: 175,
      render: (text, record, index) => {
        if (text && Object?.keys(text)?.length>0) {
          let total = Object.values(text)
          return <Collapse defaultActiveKey={[{index}]} ghost>
            <Collapse.Panel header={<Flex gap={4}><div>Statistics&nbsp;:</div><div>{total?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</div></Flex>} key={index}>
              {
                Object.keys(text).map(moduleName=>{
                  let menu = getMenuPortfolio({moduleName});
                  return <Tag key={Math.random()} color={menu?.color} className="mx-2 mt-1 w-100" title={`${menu?.name} : ${text[moduleName]}`}>{`${menu?.name} : ${text[moduleName]}`}</Tag>
                })
              }
            </Collapse.Panel>
          </Collapse>
        }
        return null;
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "text-center",
      hidden:true,
      width: 150,
      render: (text, record, index) => {
        if (record?.updatedAt) {
          //return record?.updatedAt;
          return new Date(record?.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
        }
        return "";
      },
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      className: "text-center",
      hidden:true,
      width: 150,
      render: (text, record, index) => {
        if (record?.updatedAt) {
          //return record?.updatedAt;
          return new Date(record?.updatedAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
        }
        return "";
      },
    },
    {
      title: "Change Log",
      dataIndex: "id",
      key: "id",
      className: "text-center",
      width: 100,
      render: (text, record, index) => {
        return <Link to={`/reports/user-activity/${text}`} target="_blank" className="btn btn-sm btn-primary py-0 px-2 fs-small">Log</Link>;
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      className: "text-center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Flex gap={0} justify="center">
            <Tooltip title="View scenario item list">
              <Button
                icon={<i className="fa fa-pencil text-primary"></i>}
                type="text"
                onClick={() => {
                  SaveRecentlyView_Scenario(text, record?.name);

                  localStorage.setItem("scenarioName", record?.name);
                  setRecentOpenScenario(record);
                  navigation("/scenario-list-v2/" + text);
                }}
              />
            </Tooltip>
            {/* {checkRole("update") && (
              <Tooltip title="Edit scenario">
                <Button
                  icon={<i className="fa fa-pencil text-primary"></i>}
                  type="text"
                  onClick={() => {
                    //Check User

                    const validation = validateUserRight(
                      record.collaborators,
                      record.createdBy
                    );
                    if (validation) {
                      navigation("/start-new-scenario/" + record?.id);
                    } else {
                      message.error(
                        "you have not right to edit the selected scenario"
                      );
                    }
                  }}
                />
              </Tooltip>
            )} */}
            {checkRole("delete") && (
              <Tooltip title="Delete scenario">
                <Button
                  icon={<i className="fa fa-trash-o text-primary"></i>}
                  type="text"
                  onClick={async () => {
                    const validation = validateUserRight(
                      record.collaborators,
                      record.createdBy
                    );
                    if (validation || UserService?.hasRole(['Admin'])) {
                        await deleteScenario(record);
                    }else {
                      message.error(
                        "you have not right to delete the selected scenario"
                      );
                    }
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
  ];

  const deleteScenario = async (item) => {
    let obj = {};
    obj.scenarioId = item?.id;
    obj.isForce = 0;
    Modal.confirm({
      title: (
        <div className="font-normal">
          <p style={{ fontWeight: 600, fontSize: 16 }}>
            Are you sure want to delete?
          </p>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let result;
          result = await ScenarioServices.deleteScenariosV2(item?.id);
          if (result) {
            if (result?.data?.code === 200) {
              message.success(
                result?.data?.message ||
                  result?.data?.msg ||
                  "Deleted Successfully"
              );

              //item?.id

              let updatedScenarios = recentlyOpenScenarios.filter(
                (scenario) => scenario.id !== item?.id
              );
              updatedScenarios = JSON.stringify(updatedScenarios);
              //sessionStorage.setItem("recentlyOpenScenarios", updatedScenarios);

              localStorage.setItem("recentlyOpenScenarios", updatedScenarios);

              //let openedScenarios = sessionStorage.getItem("recentlyOpenScenarios");
              let openedScenarios = localStorage.getItem(
                "recentlyOpenScenarios"
              );

              openedScenarios = JSON.parse(openedScenarios);
              setRecentlyOpenScenarios(openedScenarios);

              //Remove From Home Screen
              RemoveRecentViewed({ modelName: "Scenario", modelID: item?.id });

              //getAllScenarios(1,pageCurrentSize,"");
              await getAllScenarios(searchData);
            } else {
              message.error(result?.data?.message || result?.data?.msg);
            }
          } else {
            message.error(result?.data?.msg);
          }
        } catch (error) {
          message.error(error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const setRecentOpenScenario = (scenario) => {
    if (recentlyOpenScenarios?.length === 10) {
      recentlyOpenScenarios.shift();
    }
    let tempScenarios = recentlyOpenScenarios.filter(
      (f) => f?.id?.toString() !== scenario?.id?.toString()
    );
    tempScenarios.push(scenario);
    tempScenarios = JSON.stringify(tempScenarios);
    //sessionStorage.setItem("recentlyOpenScenarios", tempScenarios);
    localStorage.setItem("recentlyOpenScenarios", tempScenarios);
  };

  const recentlyOpenScenariosTags = () => {
    let scenarios = [...recentlyOpenScenarios].reverse();
    return scenarios.splice(0, Math?.ceil(window?.innerWidth/400)*2)?.map((scenario) => {
      return (
        <Tooltip key={Math.random()} title={scenario?.name || ""}>
          <Button
            className="bg-none"
            type="default"
            varient="outlined"
            onClick={() => {
              localStorage.setItem("scenarioName", scenario?.name);
              SaveRecentlyView_Scenario(scenario?.id, scenario?.name);
              setRecentOpenScenario(scenario);
              navigation(`/scenario-list-v2/${scenario?.id}`);
            }}
            style={{ width: (recentlyOpenScenariosRef?.current?.clientWidth-100)/Math?.ceil(window?.innerWidth/400) }}
          >
            <Flex gap={8} justify="space-between" className="w-100">
              <Typography.Paragraph 
                ellipsis={true}
                title={scenario?.name || ""}
                className="mb-0 pb-0"
              >
                {scenario?.name || ""}
              </Typography.Paragraph>
              <div>
                <i className="fa fa-eye text-primary"></i>
              </div>
            </Flex>
          </Button>
        </Tooltip>
      );
    });
  };

  const onRecallScenarios = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const searchDataa = (values) => {
    // const results = scenarios.filter((value) =>
    //   value.name.toLowerCase().includes(values.toLowerCase())
    // );
    setSearchData({ ...searchData, page: currentPage, search: values });

    // getAllScenarios({ ...searchData, page: currentPage , search:values});

    // if (scenarios.length > 0 && !results.length) {
    //   setemptyText("Data Not Found");
    // }
    // setScenarios(results);
  };

  const userList = async () => {
    const userList = await ScenarioServices.userList();
    if (userList?.data?.code === 200) {
      setUserDataList(userList?.data?.data);
    } else {
    }
  };

  return (
    <div className="container-fluid">
      <LoadingBar color="#1f1f1f" ref={ref} />
      <Spin className="loading_bx" size="small" spinning={loading}>
        <div className="list-scenario-page">
          <div className="headingbx">
            <Flex justify='space-between' align='center' gap={4} className='w-100'>
              <PageTitleHeading text={`Scenario Overview`} />
              <Flex justify="flex-end" gap={4} className="mb-1">
                <Tooltip title={`Add Scenario`}>
                  <Button 
                    icon={<PlusOutlined style={{color:'#2a609d'}}/>} 
                    onClick={()=>{ setAddScenarioModal((t)=>!t) }}
                    disabled={isDisabledRole(["Admin", "Editor"])}
                  />
                </Tooltip>
                <Tooltip title={`Compare Scenario`}>
                  <Button 
                    onClick={()=>{
                      if(compareScenarioData?.length>0){
                        setCompareScenarioModal(prev=>!prev)
                      }else{
                        message.error("Nothing to compare")
                      }
                    }}
                  >
                    Compare Scenario
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </div>
          <div>
            {/* <Flex gap={8} vertical className="scenario_listbx">
              {recentlyOpenScenarios?.length > 0 && (
                <Card
                  title={`Recently Open`}
                  bordered={false}
                  size="small"
                  ref={recentlyOpenScenariosRef}
                >
                  <Flex gap={8} className="flex-wrap scenario_recentitem">
                    {recentlyOpenScenariosTags()}
                  </Flex>
                </Card>
              )}
            </Flex> */}
            {/* <hr className='my-2'/> */}
            <Card
              className="scenario_viewitem"
              bordered={false}
              size="small"
              extra={
                <>
                  <Flex gap={8}>
                    <div>
                      <Select
                        showSearch={true}
                        allowClear={true}
                        value={searchData.approvalstatus}
                        style={{ width: 175 }}
                        placeholder={`Search Status`}
                        tooltip={`Search Status`}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(v)=>{
                          setSearchData({
                            ...searchData,
                            approvalstatus: v || "",
                          })
                        }}
                        options={[
                          { value: 'Approval Pending', label: 'Approval Pending' },
                          { value: 'Approved', label: 'Approved' },
                          { value: 'In Progress', label: 'In Progress' },
                          { value: 'Rejected', label: 'Rejected'},
                          { value: 'Cancel', label: 'Cancel'},
                        ]}
                      />
                    </div>
                    <div>
                      <Space.Compact block>
                        <Input placeholder="Search Here..." value={inputSearch} onChange={(e)=>{ setInputSearch(e?.target?.value || "") }}/>
                        <Button icon={<SearchOutlined />} onClick={()=>{
                          setSearchData({
                            ...searchData,
                            search: inputSearch || "",
                          })
                        }}/>
                      </Space.Compact>
                    </div>
                    <div>
                      <Button 
                        htmlType="reset" 
                        icon={<ClearOutlined />}
                        onClick={()=>{ 
                          delete searchData.approvalstatus;
                          setInputSearch("");
                          setSearchData({
                            ...searchData,
                            search:"",
                            approvalstatus: null,
                          })
                        }}
                      >Clear All</Button>
                    </div>
                  </Flex>
                </>
              }
            >
              <Table
                rowKey={(record) => record?.id}
                columns={columns}
                bordered={true}
                size={`small`}
                dataSource={scenarios}
                pagination={{
                  defaultPageSize: pageCurrentSize,
                  pageSize: pageCurrentSize,
                  showSizeChanger: true,
                  total: totalRecords,
                  pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
                  onChange: (page, pageSize) => {
                    // eslint-disable-next-line no-useless-computed-key
                    setSearchData(prev=>({ ...prev, ["page"]:page,["limit"]:pageSize}));
                    setCurrentPage(page);
                    setCurrentPageSize(pageSize);
                  },
                }}
                scroll={{
                  x: window?.innerWidth - 450,
                  y:
                    window?.innerHeight - 300 -
                    (recentlyOpenScenariosRef?.current?.offsetHeight || 0),
                }}
                locale={{
                  emptyText: emptyText,
                }}
              />
            </Card>
          </div>
        </div>
      </Spin>
      <ScenarioAddModal 
        open={addScenarioModal}
        setOpen={setAddScenarioModal}
        scenarioData={{}}
        scenarioId={{}}
      />
      <Modal
        open={compareScenarioModal}
        onCancel={()=>{ setCompareScenarioModal(prev=>!prev) }}
        onOk={()=>{  
          if(compareScenarioData?.length>0){
            navigate("/compare-scenario-v2/compare",{state:{compare:{compareIds:compareScenarioData?.map(item=>item?.id)}}})
          }
        }}
      >
        <Typography.Title level={5}>Selected Scenarios for Comparison</Typography.Title>
        <Flex vertical>
          {
            compareScenarioData?.map((item,index)=>{
              return <Flex justify="space-between" align="center" gap={4} key={Math.random()}>
                <Typography.Paragraph 
                  ellipsis={true}
                  title={item?.name}
                  className="pb-0 mb-0"
                >{index+1}: {item?.name}</Typography.Paragraph>
                <div><CloseCircleOutlined onClick={()=>{
                  setLoading(prev=>!prev)
                  setCompareScenarioData(prev=>prev?.filter(f=>f.id!==item?.id))
                  setLoading(prev=>!prev)
                }} style={{color:"red"}}/></div>
              </Flex>
            })
          }
        </Flex>
      </Modal>
    </div>
  );
};

export default ListScenario;
