import React, { useEffect } from 'react';
import { Column } from '@antv/g2plot';
import { useNavigate } from 'react-router';

const ScenarioChart = ({ scenarioChartData }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const convertedData = scenarioChartData.filter((f)=>f.status!=='Total')?.map(item => {
            return {
                ...item,
                value: parseInt(item.value, 10)  // Convert string to integer
            }
        });
        
        const sum = convertedData.map(o => o.value).reduce((a, b) => a + b, 0);
        const base = (sum / convertedData?.length) || 0;

        const barPlot = new Column('scenario-chart-3', {
            data:convertedData,
            xField: 'status',
            yField: 'value',
            columnWidth: 0.5, // Set the width of the bars (0 to 1, where 1 is the full available width)
            // barWidthRatio: 0.5, // Adjust the ratio to control spacing
            maxColumnWidth:60,
            barStyle:{
                padding:20,
            },
            color: ({status}) => {
                return convertedData.find(f=>f.status===status)?.color;
            },
            label: {
                position: (datum) => 'top', // Dynamically force position to // 'top', 'bottom', 'middle'
                offsetY: 5, // Adjust vertical position (negative = upward)
            },
            columnStyle: {
                marginRatio: 0.05, // Default is 0.1 (10% gap). Increase for more space.
            },
            yAxis: {
                type: 'log', // Logarithmic scale
                base: parseInt(base),
            },
            interactions: [{ type: 'element-active' }],
        });
        
        barPlot.render();

        // Add click event listener
        barPlot.on('element:click', (event) => {
            const { data } = event;
            if(data?.data?.status){
                navigate('/list-scenario',{ state: {filter:{ "approvalstatus": data?.data?.status}} })
            }
        });

        barPlot.on('element:label:click', (event) => {
            const { data } = event;
            if(data?.data?.status){
                navigate('/list-scenario',{ state: {filter:{ "approvalstatus": data?.data?.status}} })
            }
        });

        // Cleanup on component unmount
        return () => {
            barPlot.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioChartData]);

    return <div id="scenario-chart-3"  style={{maxHeight:`350px`}}/>;
}

export default ScenarioChart
