import { encodeQueryData, removeEmptyKeys } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

// filterOrder
async function getList(filterBy, filter_ByText, filter_levels,filterOrder) {
    const url = `${API_BASE_URL}businesscapability/filter`;
    let filter;

    if (filterOrder === "Level,ASC"){
        filter = [["Level","ASC"]]
    }else if (filterOrder === "Level,DESC"){
        filter = [["Level","DESC"]]
    }else if (filterOrder === "name,ASC"){
        filter = [["DisplayName","ASC"]]
    }else if (filterOrder === "name,DESC"){
        filter = [["DisplayName","DESC"]]
    }else {
        // console.log("wrong input");
    }

    let requestData = {
        [filterBy]: filter_ByText,
        "level": filter_levels,
        "sortBy":filter
    };

    requestData = removeEmptyKeys(requestData);

    const response = await http.post(url, requestData);
    return response;
}


async function create(data) {
    const url = `${API_BASE_URL}businesscapability`;
    let response = await http.post(url, data);
    return response;
}

async function update(data) {
    const url = `${API_BASE_URL}businesscapability`;
    let response = await http.put(url, data);
    return response;
}

async function deleteBusinessCapability(id) {
    const url = `${API_BASE_URL}businesscapability/`;
    let response = await http.delete(url + id);
    return response;
}

async function getListByID(id) {
    const url = `${API_BASE_URL}businesscapability/`;
    let response = await http.get(url + id);
    return response;
}

async function getPortfolioSummaryReport(data) {
    const url = `${API_BASE_URL}reporting/create-summary-report`;
    let response = await http.post(url,data);
    return response;
}


async function getmaxlevelid(data) {
    const url = `${API_BASE_URL}businesscapability/getmaxlevelid`;
    let response = await http.post(url, data);
    return response;
}

async function getPortfolioSummaryReportData(filter,page=1,limit=100) {
    let params = {
      page,
      limit,
    }
    params = removeEmptyKeys(params);
    const url = `${API_BASE_URL}reporting/getReports?`+encodeQueryData(params);
    const response = await http.post(url, filter);
    return response;
  }

const BusinessCapabilityService = {
    getList,
    create,
    update,
    deleteBusinessCapability,
    getListByID,
    getPortfolioSummaryReport,
    getmaxlevelid,
    getPortfolioSummaryReportData,
}


export default BusinessCapabilityService;
