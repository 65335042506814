import { Typography } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import NewScenarioServices from "../services/services/NewScenarioServices";

export const default_pageSize = 100;
export const default_limit = 20;
export const default_pageSizeOptions = [10, 20, 50, 100, 200, 500, 1000];

export const encodeQueryData = (data) => {
  // encoding query strings
  const ret = [];
  for (let d in data) {
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  }
  return ret?.join("&");
};

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({ width, height });
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);
  return [dimensions, translate, containerRef];
};

export const portfolioTypes = [
  "Free text",
  "Single select",
  "Multi select",
  "Date",
  "Time",
  "Date time",
  "Numeric",
  "Long text",
];
export const restrictedTexts = [
  "name",
  "id",
  //"description",
  "created date",
  "updated date",
  "created by",
  "updated by",
];
export const deepFindByName = (arr, name, deep = false) => {
  // console.log("testing arr", arr);
  // console.log("testing name", name);
  for (const obj of arr) {
    if (obj?.name?.toLowerCase() === name?.toLowerCase()) {
      return obj;
    }
    if (deep) {
      if (obj?.portfolioSections) {
        const foundInSection = deepFindByName(obj?.portfolioSections, name);
        if (foundInSection) {
          return foundInSection;
        }
      }
      if (obj?.properties) {
        for (const prop of obj?.properties) {
          if (prop?.name === name) {
            return prop;
          }
        }
      }
    }
  }
  return null;
};

export const addIsTextToProperties = (data, name = "") => {
  if (data) {
    for (const section of data?.portfolioSections) {
      for (const property of section?.properties) {
        if (section?.properties.length > 0) {
          property.isText =
            property?.name?.toLowerCase() === name?.toLowerCase() || false;
        }
      }
    }
  }
  return data;
};

export const transformText = (text, caseType) => {
  switch (caseType) {
    case "snake":
      return text.toLowerCase().replace(/\s+/g, "_");
    case "camel":
      return text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    case "kebab":
      return text.toLowerCase().replace(/\s+/g, "-");
    case "pascal":
      return (" " + text)
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
          return chr.toUpperCase();
        });
    default:
      return text;
  }
};
export const isPascalCase = (text) => {
  return /^[A-Z][a-zA-Z0-9]*$/.test(text);
};

export const isCamelCase = (text) => {
  return /^[a-z][a-zA-Z0-9]*$/.test(text);
};

export const PageTitleHeading = ({ text, size=4, className="", ...props }) => {
  let style = {};
  if (props?.width) {
    style.width = props?.width + "ch";
  }
  if (props?.wordBreak) {
    style.wordBreak = "break-all";
  }
  
  return (
    <Typography.Title
      level={size}
      className={`mb-0 text-primary ${className}`}
      title={text}
      {...{style,...props}}
    >
      {text}
    </Typography.Title>
  );
  return (
    <>
      <div
        className={`text-start h${!size ? 2 : `${size}`} ${
          !className ? `my-2` : className
        }`}
        style={style}
      >
        {text}
      </div>
    </>
  );
};

export const pascalToNormal = (pascalString) => {
  const stringWithSpaces = pascalString
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters that follow a lowercase letter
    .trim(); // Remove leading/trailing spaces

  return (
    stringWithSpaces.charAt(0).toUpperCase() +
    stringWithSpaces.slice(1).toLowerCase()
  );
};

export const pascalToSnake = (pascalCase) => {
  return pascalCase
    .replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`)
    .replace(/^_/, '');
};

const isIdExisting = (str, id) => {
  // Convert both the string and ID to lowercase for case-insensitive comparison
  const lowerCaseStr = str.toLowerCase();
  const lowerCaseId = id.toLowerCase();

  // Check if the lowercased string contains the lowercased ID
  return lowerCaseStr.includes(lowerCaseId);
};

export const getSelectProperties = (portfolioSections, isSingleSelect) => {
  const selectProperties = [];
  portfolioSections?.forEach((section) => {
    section.properties.forEach((property) => {
      if (!isSingleSelect) {
        selectProperties.push(property);
      } else if (
        isSingleSelect &&
        (property.type === "Single select" ||
          property.type === "Multi select" ||
          property?.type === "Date time" ||
          property?.type === "Date" ||
          property?.type === "Free text" ||  property?.type === "Long text")
      ) {
        selectProperties.push(property);
      } else {
        if (
          property?.type === "Numeric" &&
          !isIdExisting(property.name, "id")
        ) {
          selectProperties.push(property);
        }
      }
    });
  });
  return selectProperties;
};

export const changeFirstLetter = (text, toLowerCase) => {
  const firstLetter = text.charAt(0);
  const restOfText = text.slice(1);

  return toLowerCase
    ? firstLetter.toLowerCase() + restOfText
    : firstLetter.toUpperCase() + restOfText;
};

export const dateYearValidation = 50;
export const debounceTimeOut = 500;

export const numberRangeMin = 1;
export const numberRangeMax = 1000000;

export const COLORS = [
  "#2596be",
  "#20ac9c",
  "#0c1c3c",
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
  "#250662",
  "#cb5bea",
  "#228916",
  "#ac3e1b",
  "#df514a",
  "#539397",
  "#880977",
  "#f697c1",
  "#ba96ce",
  "#679c9d",
  "#c6c42c",
  "#5d2c52",
  "#48b41b",
  "#e1cf3b",
  "#5be4f0",
  "#57c4d8",
  "#a4d17a",
  "#be608b",
  "#96b00c",
  "#088baf",
  "#f158bf",
  "#e145ba",
  "#ee91e3",
  "#05d371",
  "#5426e0",
  "#4834d0",
  "#802234",
  "#6749e8",
  "#0971f0",
  "#8fb413",
  "#b2b4f0",
  "#c3c89d",
  "#c9a941",
  "#41d158",
  "#fb21a3",
  "#51aed9",
  "#5bb32d",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#9cb64a",
  "#996c48",
  "#9ab9b7",
  "#06e052",
  "#e3a481",
  "#0eb621",
  "#fc458e",
  "#b2db15",
  "#aa226d",
  "#792ed8",
  "#73872a",
  "#520d3a",
  "#cefcb8",
  "#a5b3d9",
  "#7d1d85",
  "#c4fd57",
  "#f1ae16",
  "#8fe22a",
  "#ef6e3c",
  "#243eeb",
  "#dd93fd",
  "#3f8473",
  "#e7dbce",
  "#421f79",
  "#7a3d93",
  "#635f6d",
  "#93f2d7",
  "#9b5c2a",
  "#15b9ee",
  "#0f5997",
  "#409188",
  "#911e20",
  "#1350ce",
  "#10e5b1",
  "#fff4d7",
  "#cb2582",
  "#ce00be",
  "#32d5d6",
  "#608572",
  "#c79bc2",
  "#00f87c",
  "#77772a",
  "#6995ba",
  "#fc6b57",
  "#f07815",
  "#8fd883",
  "#060e27",
  "#96e591",
  "#21d52e",
  "#d00043",
  "#b47162",
  "#1ec227",
  "#4f0f6f",
  "#1d1d58",
  "#947002",
  "#bde052",
  "#e08c56",
  "#28fcfd",
  "#36486a",
  "#d02e29",
  "#1ae6db",
  "#3e464c",
  "#a84a8f",
  "#911e7e",
  "#3f16d9",
  "#0f525f",
  "#ac7c0a",
  "#b4c086",
  "#c9d730",
  "#30cc49",
  "#3d6751",
  "#fb4c03",
  "#640fc1",
  "#62c03e",
  "#d3493a",
  "#88aa0b",
  "#406df9",
  "#615af0",
  "#2a3434",
  "#4a543f",
  "#79bca0",
  "#a8b8d4",
  "#00efd4",
  "#7ad236",
  "#7260d8",
  "#1deaa7",
  "#06f43a",
  "#823c59",
  "#e3d94c",
  "#dc1c06",
  "#f53b2a",
  "#b46238",
  "#2dfff6",
  "#a82b89",
  "#1a8011",
  "#436a9f",
  "#1a806a",
  "#4cf09d",
  "#c188a2",
  "#67eb4b",
  "#b308d3",
  "#fc7e41",
  "#af3101",
  "#71b1f4",
  "#a2f8a5",
  "#e23dd0",
  "#d3486d",
  "#00f7f9",
  "#474893",
  "#3cec35",
  "#1c65cb",
  "#5d1d0c",
  "#2d7d2a",
  "#ff3420",
  "#5cdd87",
  "#a259a4",
  "#e4ac44",
  "#1bede6",
  "#8798a4",
  "#d7790f",
  "#b2c24f",
  "#de73c2",
  "#d70a9c",
  "#88e9b8",
  "#c2b0e2",
  "#86e98f",
  "#ae90e2",
  "#1a806b",
  "#436a9e",
  "#0ec0ff",
  "#f812b3",
  "#b17fc9",
  "#8d6c2f",
  "#d3277a",
  "#2ca1ae",
  "#9685eb",
  "#8a96c6",
  "#dba2e6",
  "#76fc1b",
  "#608fa4",
  "#20f6ba",
  "#07d7f6",
  "#dce77a",
  "#77ecca",
];

export const removeEmptyKeys = (obj) => {
  for (const key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
};

export const objectToQueryParams = (obj) => {
  const queryParams = Object.keys(obj)
    .map((key) => {
      // Handle arrays separately to format them correctly
      if (Array.isArray(obj[key])) {
        // Convert array values to string with comma-separated format
        const arrayValue = encodeURIComponent(JSON.stringify(obj[key]));
        return `${encodeURIComponent(key)}=${arrayValue}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
  return queryParams;
};

export const removeEmptyArrObjOrval = (obj) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value && typeof value === "object") {
        if (
          value.isRange &&
          Array.isArray(value.value) &&
          value.value.length === 0
        ) {
          // Skip this key if it has isRange true and an empty value array
          return acc;
        }

        const newValue = removeEmptyArrObjOrval(value);
        if (newValue && Object.keys(newValue).length !== 0) {
          acc[key] = newValue;
        }
      } else if (value !== undefined && value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
};

export const convertObjectToArray = (obj) =>
  Object.values(obj).flat().map(Number);

export const groupedData = (data) => {
  return data?.reduce((acc, obj) => {
    const { item_id: itemId, portfolioSettings } = obj;
    const name = portfolioSettings?.name;
    if (itemId && portfolioSettings?.portfolios) {
      acc[name] = acc[name] || [];
      acc[name].push(obj);
    }
    return acc;
  }, {});
};

export const transformLevelString = (inputString) => {
  // Ensure the inputString is a valid string
  if (typeof inputString !== "string" || inputString.trim() === "") {
    return "L1"; // Default return value if input is not a valid string
  }

  // Use a regular expression to match the first word and the number
  const match = inputString.match(/(\b\w)\w*\s+(\d+)/i);

  if (match) {
    // Extract the first letter of the first word and the number part
    const firstLetter = match[1].toUpperCase();
    const number = match[2];
    return `${firstLetter}${number}`;
  } else {
    // Extract the first letter of the first word
    const firstLetter = inputString.match(/\b\w/)[0].toUpperCase();
    return `${firstLetter}1`;
  }
};

// Asset to Image
export const AssetSVGtoImgComponent = ({
  width,
  height,
  color,
  src,
  alt,
  className,
}) => {
  let parameters = {};
  if (width) {
    parameters.width = width;
  }
  if (height) {
    parameters.height = height;
  }
  if (color) {
    parameters.color = color;
  }
  if (className) {
    parameters.className = className;
  }
  return (
    <React.Fragment>
      <img src={src} alt={alt || Math.random()} {...parameters} />
    </React.Fragment>
  );
};

export const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (text?.length <= maxLength) {
    return <p>{text}</p>;
  }

  if (text?.length > maxLength) {
    return (
      <div>
        <p>{isExpanded ? text : `${text?.substring(0, maxLength)}...`}</p>

        <button
          onClick={toggleExpand}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </button>
      </div>
    );
  }
};

export const sortArray = (arr, key = null) => {
  if (key === null) {
    // If no key is provided, assume it's an array of strings
    return arr.sort((a, b) => a.localeCompare(b));
  } else {
    // If a key is provided, assume it's an array of objects
    return arr.sort((a, b) => {
      if (a[key] && b[key]) {
        return a[key].localeCompare(b[key]);
      } else {
        return 0;
      }
    });
  }
};

export const allowedTypes = [
  "Single select",
  "Multi select",
  "Date time",
  "Date",
  "Numeric",
];

export const filterPortfolioData = (dataArray, allowedTypes) => {
  return dataArray.filter((data) => allowedTypes.includes(data.type)) || [];
};

export const constantFilterSearch = "displayname";

export const dataAndInfoObjIntLand = [
  { id: "dataobject", displayName: "Data Object" },
  {
    id: "informationobject",
    displayName: "Information Object",
  },
];

export const statusColors = [
  {
    status: "Old",
    bgColor: "#B0B0B0",
    textColor: "#000000",
    noBgColor: "#B0B0B0",
  }, // Black text on gray background
  {
    status: "New",
    bgColor: "#00FF00",
    textColor: "#FFFFFF",
    noBgColor: "#00FF00",
  }, // White text on green background
  {
    status: "Modified",
    bgColor: "#FFA500",
    textColor: "#000000",
    noBgColor: "#FFA500",
  }, // Black text on orange background
];

export const exportWitStatic = ["integration"];

export const initialDefaultSortBy = [
  {
    value: "displayname",
    title: "Name",
  },
];

export const initialApplicationDefaultSortBy = [
  {
    value: "id",
    title: "ID",
  },
  {
    value: "displayname",
    title: "Name",
  },
];

export const initialCapabilityAndProcessSortBy = [
  { value: "level", title: "Level" },
  { value: "displayname", title: "Name" },
];

export const portfolioSortingValues = (modelName) => {
  switch (modelName) {
    case "business_application":
      return initialApplicationDefaultSortBy;
    case "business_capability":
    case "business_processes":
      return initialCapabilityAndProcessSortBy;
    default:
      return initialDefaultSortBy;
  }
};

export const baseUrl = process.env.DOWNLOAD_URL;

export const formatUrl = (relativeUrl) => {
  // Replace backslashes with forward slashes
  const formattedUrl = relativeUrl.replace(/\\/g, "/");
  return formattedUrl;
};

export const SaveRecentlyView_Scenario = async (scenarioId, scenarioName) => {
  //Using Session Storage
  try {
    let recentScenarioArr = [];
    let recentScenario_Save = localStorage.getItem("recent_viewed_scenario");
    if (recentScenario_Save) {
      let arrList = JSON.parse(recentScenario_Save);
      recentScenarioArr.push(...arrList);
    }

    //Search ID and Delete
    recentScenarioArr = await deleteObjectsById(recentScenarioArr, scenarioId);

    recentScenarioArr.push({
      scenario_id: scenarioId,
      scenarioName: scenarioName,
    });

    localStorage.setItem(
      "recent_viewed_scenario",
      JSON.stringify(recentScenarioArr)
    );
  } catch (err) {
    console.log(err);
  }
};

export const getRecentlyView_ScenarioName = async (scenarioId) => {
  //Using Session Storage
  try {
    let recentPortfolio = localStorage.getItem("recent_viewed_scenario");
    let recentPortfolio_json = JSON?.parse(recentPortfolio)?.filter(
      (item) => item?.scenario_id == scenarioId
    );
    // console.log(
    //   "scenarioName............recentPortfolio_json ",
    //   recentPortfolio_json
    // );
    return recentPortfolio_json;
  } catch (err) {
    console.log(err);
  }
};

const deleteObjectsById = (recentPortfolioArr, scenarioId) => {
  const newData = recentPortfolioArr.filter(
    (obj) => obj.scenario_id !== scenarioId
  );
  return newData;
};

export const SavePortfolioItemsCheckModified = async (
  {moduleName,
  sourceItemId,
  targetItemId}
) => {

  // console.log("object",moduleName,
  //   sourceItemId,
  //   targetItemId)
  try {
    await NewScenarioServices.CheckModified({
      moduleName,
      sourceItemId,
      targetItemId,
    });
  } catch (err) {
    console.log(err);
  }
};



export const snakeToPascal = (snakeCase) => {
  return snakeCase
    .replace(/_([a-z])/g, (match, letter) => {
      return letter.toUpperCase();
    })
    .replace(/^([a-z])/, (match, letter) => {
      return letter.toUpperCase();
    });
};