import React, { useEffect, useState } from "react";
import { Badge, Button, Tooltip, Flex } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { getDynamicRoutesLink } from "../../utils/portfolioRelationshipCommon";
import { PortfolioItemDetails } from "../portfolio/common/PortfolioCommon";
import { Spin } from "antd";
import "./ApplicationList.scss";
import Delayed from "../../utils/Delayed";

const BusinessApplicationListCards = ({
    freeText,
    ...props
}) => {
    const [lists,setLists] = useState([]);
    
    useEffect(()=>{
        setLists(props?.lists);
    },[props?.lists])

    return (
        <>
            <Delayed>
                {
                    (()=>{
                        if(lists?.length===0){
                            return <Spin
                                size="small"
                                spinning={
                                    freeText === "Data Not Found" || freeText === "No items found."
                                    ? false
                                    : true
                                }
                            >
                                <div style={{ fontSize: 16, fontWeight: 600 }}>{freeText}</div>
                            </Spin>
                        }else{
                            return lists?.map(list=>{
                                return <React.Fragment key={Math.random()}     >
                                    <GetCard 
                                        item={list} 
                                        {...props}
                                    />
                                </React.Fragment>
                            })
                        }
                    })()
                }
            </Delayed>
        </>
    );
};

export default BusinessApplicationListCards;

const GetCard = ({item,cardModuleName,moduleName, ...props}) => {
    const navigate = useNavigate();
    const { BadgeData, displayname, description } = getCardDescription(item,cardModuleName);
    const [addedData] = useState(props?.addedData?.map(e=>Number(e)));

    const handleAddOrRemove = async (data) => {
        try {
            if (addedData.includes(Number(item?.id))) {
                // If item is already added, make API call to remove it
                props.setAddedData(prev=>([...props.addedData].filter(f=>Number(f)===Number(item?.id))));
                await props?.handleRemoveRelationship({
                    //id: item?.relationShipId || item.id ,
                    id: item?.id,
                    moduleName: item?.moduleName,
                });
            } else {
                // If item is not added, make API call to add it
                props.setAddedData(prev=>([...props.addedData, Number(item?.id)]));
                await props?.handleAddRelationship({
                    id: item?.id,
                    moduleName: item?.moduleName,
                });
            }
        } catch (error) {
            console.error("error: ",error)
        }
        
    }

    return <React.Fragment>
        <div key={item?.id} className="w-100 border-bottom">
            <table width={`100%`} className="my-2">
                <tbody>
                    <tr>
                        <td>
                            <PortfolioItemDetails
                                moduleName={moduleName}
                                item={item}
                                // basePath={basePath}
                                defaultActiveKey={1}
                                badgeValue={BadgeData}
                            />
                        </td>
                        <td width="10%">
                            <Flex gap={4} justify="flex-end">
                                {props?.showButtons?.view && (
                                    <EyeOutlined
                                        className="cursor-pointer ml-2"
                                        onClick={() => {
                                            navigate("/business-capability-master/" + item?.id);
                                        }}
                                        style={{ fontSize: "20px" }}
                                    />
                                )}
                                {props?.showButtons?.edit && (
                                    <EditOutlined
                                        className="cursor-pointer ml-2 text-blue-500"
                                        onClick={() => {
                                            navigate("/business-capability-master/" + item?.id);
                                        }}
                                        style={{ fontSize: "20px" }}
                                    />
                                )}
                                {props?.showButtons?.delete && (
                                    <DeleteOutlined
                                        className="cursor-pointer ml-2 text-danger"
                                        onClick={() => {
                                            props?.setItem(item, { type: "delete" });
                                            props?.setModalData({
                                            level: 1,
                                            ParentID: item?.id,
                                            DisplayName: item?.displayname,
                                            });
                                        }}
                                        style={{ fontSize: "20px" }}
                                    />
                                )}
                                {props?.addRemove && (
                                    <Button
                                        {...(() => {
                                            if (addedData?.includes(Number(item?.id))) {
                                                return { 
                                                    type: `default`,
                                                    disabled: true, 
                                                    onClick: ()=>handleAddOrRemove(item),
                                                };
                                            } else {
                                                return { 
                                                    type: `primary`,
                                                    disabled: props?.dataLoading ,
                                                    onClick: ()=>handleAddOrRemove(item),
                                                    icon: <Tooltip title={`Select`}><PlusOutlined /></Tooltip>
                                                };
                                            }
                                        })()}
                                    >
                                        {props?.addRemove &&
                                            addedData?.includes(Number(item?.id)) &&
                                        `Added`}
                                    </Button>
                                )}
                                {props?.addRemoveScenaioPortfolio && (
                                    <>
                                    <div className="app-buttons pull-right">
                                        {!props?.removedData?.includes(Number(item?.id)) ? (
                                        <Button
                                            loading={props?.loadingButtonState}
                                            style={{ width: 90 }}
                                            type={`default`}
                                            onClick={() => handleAddOrRemove(item)}
                                            {...(() => {
                                            if (props?.addedData?.includes(Number(item?.id))) {
                                                return { disabled: true };
                                            } else {
                                                return { disabled: props?.dataLoading };
                                            }
                                            })()}
                                            icon={
                                            props?.addedData?.includes(Number(item?.id)) ? (
                                                "Imported"
                                            ) : (
                                                <Tooltip title={`Import`}>Import</Tooltip>
                                            )
                                            } //<Tooltip title={`Remove`}><DeleteOutlined /></Tooltip>
                                        >
                                            {props?.addRemove &&
                                            props?.addedData?.includes(Number(item?.id)) &&
                                            `Imported`}
                                        </Button>
                                        ) : (
                                        ""
                                        )}
                
                                        {!props?.addedData?.includes(Number(item?.id)) ? (
                                        <Button
                                            loading={props?.loadingButtonState}
                                            type={`primary`}
                                            style={{ width: 90 }}
                                            onClick={async () => {
                                            props.setRemovedData([
                                                ...props.removedData,
                                                item.id,
                                            ]);
                                            await props?.handleRemoveRelationship({
                                                id: item.id,
                                                moduleName: item?.moduleName,
                                            });
                                            }}
                                            {...(() => {
                                            if (props?.removedData?.includes(Number(item?.id))) {
                                                return { disabled: true };
                                            } else {
                                                return { disabled: props?.dataLoading };
                                            }
                                            })()}
                                            icon={
                                            props?.removedData?.includes(Number(item?.id)) ? (
                                                "Removed"
                                            ) : (
                                                <Tooltip title={`Remove`}>
                                                {/* <DeleteOutlined /> */}
                                                Remove
                                                </Tooltip>
                                            )
                                            } //<Tooltip title={`Remove`}><DeleteOutlined /></Tooltip>
                                        >
                                            {props?.addRemove &&
                                            props?.removedData?.includes(Number(item?.id)) &&
                                            `Removed`}
                                        </Button>
                                        ) : (
                                        ""
                                        )}
                                    </div>
                                    </>
                                )}
                            </Flex>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </React.Fragment>
}

const getCardDescription = (item,cardModuleName) => {
    let obj = { ...item };
    obj.BadgeData = item.referenceid;
    if (
      cardModuleName === "BusinessCapability" ||
      cardModuleName === "business_capability"
    ) {
      obj.BadgeData = item.levelid;
    } else if (
      cardModuleName === "BusinessProcess" ||
      cardModuleName === "business_processes"
    ) {
      obj.BadgeData = item.levelid;
    }

    return { ...obj };
};
