import { Flex, Slider, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { portfolioRoute } from './../../shared-multitheme/sideBar/SideMenuItems';
import { getMenuPortfolio } from '../../../utils/settingCommon';

const SummaryChart = ({limit,sliderMarks,summaryData,setSummaryData,selectedPortfolio,WithChangeType}) => {
    let navigate = useNavigate();
    
    const updateChartData = (cData) => {
        let tempData = summaryData?.map(sData=>{
            if(cData?.type===sData.type && cData?.id===sData?.id){
                sData.visible = !cData?.visible;
            }
            sData.portfolio = sData?.portfolio?.map(pData=>{
                if(cData?.type===pData.type && cData?.portfolio_id===pData?.portfolio_id){
                    pData.visible = !cData?.visible;
                }
                return pData
            })
            return sData
        })
        setSummaryData(tempData);
    }

    const getTitle = ({type,data}) => {
        switch (type) {
            case "project": return <Typography.Text
                                    ellipsis={true}
                                    title={data?.name}
                                    style={{maxWidth:window?.innerWidth/2}}
                                >
                                    {data?.name}
                                </Typography.Text>
                        //         return <details onClick={(e)=>updateChartData(data)} open={data?.visible || false}>
                        //     <summary className="text-nowrap">
                                
                        //     </summary>
                        // </details>;
            case "task": return <Typography.Text
                                    ellipsis={true}
                                    title={data?.portfolioname}
                                    className='px-2 rounded'
                                    style={{background:data?.color,maxWidth:window?.innerWidth/2}}
                                >
                                    {data?.portfolioname}
                                </Typography.Text>
                        // <details onClick={()=>updateChartData(data)} open={data?.visible || false}>
                        //     <summary className="text-nowrap">
                        //         <Typography.Text
                        //             ellipsis={true}
                        //             title={data?.portfolioname}
                        //             className='px-2 rounded'
                        //             style={{background:data?.color,maxWidth:250}}
                        //         >
                        //             {data?.portfolioname}
                        //         </Typography.Text>
                        //     </summary>
                        // </details>;
            case "milestone": return <React.Fragment>
                <Flex gap={8}>
                    <Typography.Text
                        ellipsis={true}
                        title={data?.displayname}
                        className='text-nowrap pointer'
                        style={{maxWidth:window?.innerWidth/2}}
                        onClick={()=>{
                            if(data?.item_id){
                                let portfolioRoute = getMenuPortfolio({"routeModuleName": data?.relation_modulename,getRouteByRouteModule:true})
                                navigate(portfolioRoute+"/"+data?.item_id)
                            }
                        }}
                    >
                        {data?.displayname}
                    </Typography.Text>
                    <WithChangeType action={data?.item_type}/>
                </Flex>
            </React.Fragment>;
            default: return "";
        }
    }

    return (
        <React.Fragment>
            <table className='table table-bordered table-hover table-sm'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th >Deadline</th>
                        <th className='text-right'>Opex</th>
                        <th className='text-right'>Capex</th>
                        <th className='text-right'>Total</th>
                        <th className="text-nowrap text-right"># Impacted Objects</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ((()=>{
                            if(summaryData?.length>0){
                                return summaryData?.map((rowData, index) => {
                                    let total ={
                                        currency: "",
                                        opex:0,
                                        capex:0,
                                        totalexp:0,
                                        total_relations:0,
                                        end:[],
                                        start:[],
                                    };
                                    return (
                                        <React.Fragment key={index}>
                                            <tr kay={Math.random()} className="table-active">
                                                <th>
                                                    {getTitle({type:rowData?.type,data:rowData})}
                                                </th>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                            </tr>
                                            {
                                                ((()=>{
                                                    if(selectedPortfolio?.length>0 && rowData?.portfolio?.length > 0){
                                                        return rowData?.portfolio?.filter(f=>selectedPortfolio?.includes(f.portfolio_id))?.map(portfolio=>{
                                                            return <React.Fragment key={index+Math.random()}>
                                                                <tr kay={Math.random()}>
                                                                    <td>
                                                                        {getTitle({type:portfolio?.type,data:portfolio})}
                                                                    </td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                </tr>
                                                                {
                                                                    ((()=>{
                                                                        if(portfolio?.data?.length > 0){
                                                                            return portfolio?.data?.map(data=>{
                                                                                if(limit?.length>0){
                                                                                    var endDate = (new Date(data?.end)).getTime();
                                                                                    var date1 = (new Date(sliderMarks[limit[0]])).getTime();
                                                                                    var date2 = (new Date(sliderMarks[limit[1]+1])).getTime();

                                                                                    if(date1>endDate || date2<endDate){
                                                                                        return <React.Fragment key={Math.random()}></React.Fragment>
                                                                                    } 
                                                                                }

                                                                                total.currency = data?.currency;
                                                                                let opex = Number(data?.opex) || 0;
                                                                                let capex = Number(data?.capex) || 0;
                                                                                let totalexp = Number(data?.totalexp) || 0;
                                                                                let total_relations = Number(data?.total_relations) || 0;
                                                                                
                                                                                total.opex += opex>0 ? opex : 0;
                                                                                total.capex += capex>0 ? capex : 0;
                                                                                total.totalexp += totalexp>0 ? totalexp : 0;
                                                                                total.total_relations += total_relations>0 ? total_relations : 0;
                                                                                total.end.push(data?.end)
                                                                                total.start.push(data?.start)
                                                                                return <React.Fragment key={index+Math.random()}>
                                                                                    <tr kay={Math.random()}>
                                                                                        <td>{getTitle({type:data?.type,data:data})}</td>
                                                                                        <td className='text-nowrap'>{data?.end && moment(data?.end).format('DD-MM-YYYY')}{}</td>
                                                                                        <td className='text-right text-nowrap'>{data?.currency} {data?.opex}</td>
                                                                                        <td className='text-right text-nowrap'>{data?.currency} {data?.capex}</td>
                                                                                        <td className='text-right text-nowrap'> {data?.currency} {data?.totalexp}</td>
                                                                                        <td className='text-right text-nowrap'>{data?.total_relations}</td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            })
                                                                        }
                                                                        return <></>
                                                                    })())
                                                                }
                                                            </React.Fragment>
                                                        })
                                                    }
                                                    return <></>
                                                })())
                                            }
                                            {
                                                selectedPortfolio?.length>0 && <React.Fragment>
                                                    <tr kay={Math.random()}>
                                                        <th className='bg-primary text-white text-nowrap'>{}</th>
                                                        <td className='bg-primary text-white text-nowrap'>{total?.end?.length>0 && moment(Math.max(...total?.end.map(e => new Date(e)))).format('DD-MM-YYYY')}</td>                                                
                                                        <td className='text-right bg-primary text-white text-nowrap'>{total.currency} {total.opex}</td>
                                                        <td className='text-right bg-primary text-white text-nowrap'>{total.currency} {total.capex}</td>
                                                        <td className='text-right bg-primary text-white text-nowrap'>{total.currency} {total.totalexp}</td>
                                                        <td className='text-right bg-primary text-white text-nowrap'>{}{total.total_relations}</td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                            
                                        </React.Fragment>
                                    );
                                });
                            }else{
                                return <></>
                            }
                        })())
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default SummaryChart
