import {
  Button,
  Card,
  Dropdown,
  Flex,
  Layout,
  message,
  Popover,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import BusinessProcessHeader from "../businessProcess/BusinessProcessHeader";
import {
  getAllMenuPortfolio,
} from "../../utils/settingCommon";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import DCLineChart from "./data-completeness/DCLineChart";
import { useCompletenessReport } from "../../Context/completenessReportProvider";
import DCViewDetails2 from "./data-completeness/DCViewDetails2";
import { DownloadOutlined, FileExcelOutlined, FileImageOutlined, InfoCircleOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx';
import * as htmlToImage from 'html-to-image';
import { HeadingInfo } from "./ReportsInfo";

const DataCompleteness = ({ routeModuleName, ...props }) => {
  const dataCompletenessCardRef = useRef();
  const loadingBarRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);
  // State to store the previous selection
  const [previousTags, setPreviousTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const [exportImgLoading, setExportImgLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [completenessData, setCompletenessData] = useState([]);
  const [completenessExcelData, setCompletenessExcelData] = useState({});
  const { state, dispatch } = useCompletenessReport();

  useEffect(() => {
    const initialPortfolio = getAllMenuPortfolio()[0];
    dispatch({
      type: "EMPTY_COMPLETENESS_REPORT",
    });
    const fetchData = async () => {
      setSelectedTags([initialPortfolio?.moduleName]);
      setPreviousTags([initialPortfolio?.moduleName]);
      await fetchCompletenessReport(initialPortfolio?.moduleName, "add");
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCompletenessData(state?.data);
  }, [state]);

  // Handler for onChange event
  const handleChange = (value) => {
    value = Array.isArray(value) ? value : [value];
    // Determine which tags were added or removed
    const addedTags = value.filter((tag) => !previousTags.includes(tag));
    const removedTags = previousTags.filter((tag) => !value.includes(tag));

    // Update state with the new selection
    setSelectedTags(value);
    setPreviousTags(value);
    // Call API if new tags were added

    // Function to call API
    const processTags = async (url, tag) => {
      try {
        setLoading(true);
        setDisabled(true);
        if (addedTags.length > 0) {
          const newTag = addedTags[addedTags.length - 1];
          await fetchCompletenessReport(newTag, "add");
        }

        if (removedTags.length > 0) {
          const removedTag = removedTags[removedTags.length - 1];
          await fetchCompletenessReport(removedTag, "remove");
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        setLoading(false);
        setDisabled(false);
      }
    };

    processTags();
  };
  const handleClear = () => {
    setSelectedTags([]);
    setPreviousTags([]);
    dispatch({
      type: "EMPTY_COMPLETENESS_REPORT",
    });
  };

  const handleGetDetail = async (id, page, pageSize) => {
    try {
      const findPortfolio = getAllMenuPortfolio()?.find(
        (item) => item?.id === id
      );

      if (!findPortfolio) {
        return message.error("Portfolio not found.");
      }

      const payload = {
        moduleName: findPortfolio?.routeModuleName,
        id: findPortfolio?.id,
        details: true,
        page,
        limit: pageSize,
      };

      const response = await PortfolioSettingServices?.getCompletenessReport(
        payload
      );
      if (response.data.code === 200) {
        setCompletenessExcelData(prev => ({ ...prev, [findPortfolio?.moduleName]: response?.data?.data }));
        return response?.data?.data;
      } else {
        message.error(response.data.message || "Internal server error.");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      return [];
    }
  };

  const fetchCompletenessReport = async (tag, type) => {
    try {
      setLoading(true);
      setDisabled(true);
      const findPortfolio = getAllMenuPortfolio()?.find(
        (item) => item?.moduleName === tag
      );
      if(!tag){
        setLoading(false);
        setDisabled(false);
        return;
      }else if (!findPortfolio) {
        setLoading(false);
        setDisabled(false);
        return message.error(`Portfolio not found.`);
      }
      const payload = {
        moduleName: findPortfolio?.routeModuleName,
        id: findPortfolio?.id,
        details: false,
      };
      if (type === "add") {
        const response = await PortfolioSettingServices?.getCompletenessReport(
          payload
        );
        if (response.data.code === 200) {
          // message.success(`${findPortfolio?.name} fetch successfully.`);
          await dispatch({
            type: "ADD_COMPLETENESS_REPORT",
            payload: response.data.data,
          });
        } else {
          message.error(response.data.message || "Internal server error.");
        }
      } else {
        await dispatch({
          type: "DELETE_COMPLETENESS_REPORT",
          payload: payload,
        });
        // message.success(`${findPortfolio?.name} removed successfully.`);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

   const dropdownItems = [
      {
        key: '1',
        label: (
          <Tooltip title={`Export Image`}><a href="#/" onClick={()=>{ exportImage(); }} rel="noopener noreferrer"><FileImageOutlined /> SVG</a></Tooltip>
        ),
      },
      {
        key: '2',
      //   disabled: moduleName==="Integration"? true:false,
      //   hidden: moduleName==="Integration"? true:false,
        label: (
          <Tooltip title={`Export Xlsx`}><a href="#/"   onClick={()=>{ exportExcel(); }} rel="noopener noreferrer"><FileExcelOutlined /> Xlsx</a></Tooltip>
        ),
      },
  ];

  const HeaderButton = <React.Fragment>
    <Dropdown menu={{ items:dropdownItems }} placement="bottomLeft">
        <Button icon={<DownloadOutlined />}></Button>
    </Dropdown>
  </React.Fragment>

  const exportExcel = () => {
    
    if(selectedTags?.length>0){
      selectedTags.forEach(moduleName => {
        let record = completenessExcelData[moduleName];
        record = record?.map(d=>{
          delete d?.id;
          return d;
        })
        const worksheet = XLSX.utils.json_to_sheet(record);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Write the workbook to a file and download
        XLSX.writeFile(workbook, `${moduleName}.xlsx`);
      });
    }
  }

  const exportImage =async () => {
    let content = dataCompletenessCardRef?.current;
    try {      
      setExportImgLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));      
      await htmlToImage.toSvg(content, { 
        quality: 1,
        cacheBust: false,
        filter: (node) => {
          return (node.tagName !== 'i');
        },
      })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = selectedTags?.toString()+".svg";
        link.href = dataUrl;
        link.click();
      }).catch((error)=>{
        setExportImgLoading(false);  
      }).finally(()=>{
        setExportImgLoading(false); 
      }); 
    } catch (error) {
      console.log("error:",error);
      setExportImgLoading(false);
    } finally {
    }
  }

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef || loading} />
      <Layout className="container-fluid">
        <Layout.Content className="data-completeness-landscape-report">
          <section>
            <BusinessProcessHeader
              activePage={0}
              setActivePage={() => {}}
              heading={<HeadingInfo heading={props?.heading} moduleName="DataCompletenessReport"/>}
              matrix={false}
              grid={false}
              list={false}
              addBtn={false}
              bulk_delete={false}
              showActionBtn={false}
              Buttons={HeaderButton}
            />
          </section>
          <section>
            <Flex gap={16} justify="flex-start" align="center" className="mt-2">
              <Flex gap={16} justify="space-between" align="center">
                <div className="h3 pb-0 mb-0">Select Portfolio</div>
                <div>
                  <Select
                    // mode="tags"
                    style={{
                      minWidth: "175px",
                      maxWidth: window?.innerWidth / 2,
                    }}
                    placeholder={"Select Portfolio"}
                    allowClear
                    onChange={handleChange}
                    onClear={handleClear}
                    value={selectedTags}
                    disabled={disabled}
                  >
                    {getAllMenuPortfolio()?.map((item, index) => {
                      return (
                        <Select.Option value={item.moduleName} key={index}>
                          <Flex align="center">
                          <span
                            className="portfolio-option-color"
                            style={{ "--color": item?.color,marginBottom:5 }}
                          ></span>
                          <span>{item.name}</span>
                          </Flex>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </Flex>
              
            </Flex>
          </section>
          <section className="data-completeness-card pb-5" ref={dataCompletenessCardRef}>
            <Card className="mt-2" size="small">
              <Spin size="small" spinning={loading}>
                {(completenessData?.length > 0 &&
                  completenessData?.map((item, index) => {
                    const data1 = [
                      {
                        labelName: "",
                        value: Number(item?.completePercentage) || 0,
                      },
                    ];
                    
                    const propertiesData =
                      item?.properties
                        ?.map((p) => ({
                          type: p?.label,
                          value: Number(p?.value) || 0,
                        }))
                        .filter((prop) => prop.value > 1) || [];

                    const relationsData =
                      item?.relations
                        ?.map((r) => ({
                          type: r?.displayName,
                          value: Number(r?.missingRelations) || 0,
                        }))
                        .filter((prop) => prop.value > 1) || [];

                    return (
                      <React.Fragment key={index}>
                        <Flex justify="center">
                          <div>
                            <Typography.Title level={5} className="mb-0">Data Quality Report: {item?.name || ""} </Typography.Title>
                            {/* <Typography.Title level={5} className="mb-0">{item?.name || "Data Quality"} Completeness Report</Typography.Title> */}
                            {/* <DCLineChart portfolioData={item} data={data1} /> */}
                          </div>
                        </Flex>
                        <DCViewDetails2
                          data={propertiesData}
                          portfolioData={item}
                          dataRelations={relationsData || []}
                          handleGetDetail={handleGetDetail}
                          exportImgLoading={exportImgLoading}
                        />
                      </React.Fragment>
                    );
                  })) || (
                    !loading && <div className="no-results-message">No record found!</div>
                  )
                }
              </Spin>
            </Card>
          </section>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
};

DataCompleteness.defaultProps = {
  routeModuleName: "Dataobject",
};

export default DataCompleteness;

const randomIntFromInterval = (min, max) => {
  // min and max included
  return parseInt(Math.floor(Math.random() * (max - min + 1) + min));
};
