import React, { useEffect, useState } from "react";
import { Button, Flex, Typography, Checkbox,Tooltip } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined, CopyOutlined, CheckOutlined,LinkOutlined,SnippetsOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Delayed from "../../../utils/Delayed";
import { getDynamicRoutesLink } from "../../../utils/portfolioRelationshipCommon";
import { PortfolioItemDetails } from "../../portfolio/common/PortfolioCommon";
import { getMenuPortfolio, isDisabledRole } from "../../../utils/settingCommon";
import { checkRole } from "../../../helper/useUserData";

const BusinessCapabilityListCards = ({
  page,
  limit,
  handleAddRelationship,
  handleRemoveRelationship,
  dataLoading,
  loading,
  basePath,
  create_duplicate,
  ...props
}) => {
  const [ListData, setListData] = useState(null)
  const { moduleName:moduleNamePath } = useParams();
  const [activeRowId, setActiveRowId] = useState(null);

  useEffect(() => {
    setListData(props.lists)
  }, [props.lists])

  const navigate = useNavigate();
  const levelText = (l) => {
    if (l === "L1") {
      return "Level 1";
    }
    if (l === "L2") {
      return "Level 2";
    }
    if (l === "L3") {
      return "Level 3";
    }
  };

  const handleAddOrRemove = async (item) => {
    console.log("item handleAddOrRemove", item);
    if (props.addedData.includes(item.id)) {
      // If item is already added, make API call to remove it
      await handleRemoveRelationship({
        id: item?.relationShipId || null,
        moduleName: item?.moduleName,
      });
      removeItemFromServer(item.id);
    } else {
      // If item is not added, make API call to add it
      await handleAddRelationship({
        id: item?.id,
        moduleName: item?.moduleName,
      });
      addItemToServer(item.id);
    }
  };

  const addItemToServer = (itemId) => {
    props.setAddedData([...props.addedData, itemId]);
    console.log("temp", [...props.addedData, itemId]);
  };

  const removeItemFromServer = (itemId) => {
    const temp = props.addedData.filter(
      (f) => f.toString() !== itemId.toString()
    );
    console.log("temp", temp);
    props.setAddedData(temp);
  };

  const getCard = (item) => {
    return (<>
      <div className="portfolio-listbx">
        <table width={`100%`}>
          <tbody>
            <tr>
              {props?.isListCheckbox && checkRole('checkbox') && <td>
                <Checkbox value={item?.id} onChange={props?.onCheckboxChange} />
              </td>
              }
              <td width={`95%`}>
                <PortfolioItemDetails
                  routeModuleName={moduleNamePath}
                  item={item}
                  basePath={basePath}
                  defaultActiveKey={1}
                  badgeValue={item?.levelid}
                  page={page}
                  limit={limit}
                  onClick={(item)=>{ props?.setEditPortfolio(item);setActiveRowId(item?.id) }} 
                />
              </td>
              <td width={`5%`} className="text-nowrap">
                {
                  <Flex gap={4} className="btnbx" align="center">
                    {props?.showButtons?.view && (
                      <EyeOutlined
                        className="cursor-pointer ml-2"
                        onClick={() => {
                          navigate('/business-capability-master/' + moduleNamePath + '/' + item?.id)
                        }}
                        style={{ fontSize: "20px" }}
                      />
                    )}
                    {props?.showButtons?.edit && checkRole('delete') && (
                      <EditOutlined
                        className="cursor-pointer ml-2 text-blue-500"
                        onClick={() => {
                          navigate('/business-capability-master/' + moduleNamePath + '/' + item?.id)
                        }}
                        style={{ fontSize: "20px" }}
                      />
                    )}
                    <Tooltip title={"Duplicate Item"} className="cursor-pointer ml-2 text-danger">
                      <Button 
                        color="default" 
                        variant="link" 
                        icon={<SnippetsOutlined style={{ fontSize: "20px" }}/>} 
                        disabled={isDisabledRole(["Admin", "Editor"])}
                        onClick={() => {
                          create_duplicate(item)
                        }}
                      />
                    </Tooltip>
                    <Typography.Text className="cursor-pointer ml-2 text-danger"
                      copyable={{
                        text: window.location.origin + getMenuPortfolio({ routeModuleName: moduleNamePath, getRouteByRouteModule: true }) + "/" + item?.id,
                        icon: [<LinkOutlined style={{ fontSize: 20 }} />, <CheckOutlined style={{ fontSize: 20 }} />],
                        tooltips: ['Copy Url', 'Copied'],
                      }}
                    />
                    {props?.showButtons?.delete && checkRole('delete') && (
                      <Tooltip title="Delete">
                        <DeleteOutlined
                          className="cursor-pointer ml-2 text-danger"
                          onClick={() => {
                            props?.setItem(item, { type: "delete" });
                            props?.setModalData({
                              level: 1,
                              ParentID: item?.id,
                              DisplayName: item?.displayname,
                            });
                          }}
                          style={{ fontSize: "20px" }}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                }
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Divider className="my-2 bg-[#f7f7f7]" /> */}
        {/* <hr className="my-2"/> */}
        {/* {isLast && <Divider className="my-4 bg-[#f7f7f7]" />} */}
      </div>
      <div key={item?.id} className="d-flex justify-between ">
        <div className="w-100">
          <div className=" d-flex justify-content-between">
            {/* <div>
                <div>
                  <Badge count={item?.LevelID} style={{background:"#00000040",color:"#000",minWidth:"25px",borderRadius:"8px"}} className="fw-small"/>
                  <span className="px-1 fw-medium">{levelText(item?.Level)}</span>
                  <span className="fw-medium text-primary px-1">{item?.DisplayName}</span>
                </div>
                <div className="my-1">
                  <small className="fs-small">{item?.Description}</small>{" "}
                </div>
              </div> */}
            {/* <legend className="h7">{levelText(item?.Level)}</legend> */}

          </div>

        </div>

        {props?.addRemove && (
          <Button
            type={`primary`}
            onClick={() => handleAddOrRemove(item)}
            disabled={dataLoading}
          >
            {props?.addedData?.includes(item.id) ? "Remove" : "Add"}
          </Button>
        )}
      </div>
    </>
    );
  };

  const sortBCMData = (lists) => {
    return lists?.sort((a, b) => {
      return a.levelid.localeCompare(b.levelid, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  };



  return (
    <>
      <div style={{ width: '100%' }}>
        {ListData?.length > 0 && !loading ? ListData?.map((element, i) => {
          let children = [element];

          element?.child?.forEach((element2) => {
            children.push(element2);
            element2?.child?.forEach((element3) => {
              children.push(element3);
              element3?.child?.forEach((element4) => {
                children.push(element4);
              });
            });
          });

          if (props.filterText.length > 0) {
            if (props.filterBy === "displayName") {
              children = children.filter((item) =>
                item?.displayname.includes(props.filterText)
              );
            } else if (props.filterBy === "level") {
              children = children.filter((item) =>
                item?.level.includes(props.filterText)
              );
            } else if (props.filterBy === "description") {
              children = children.filter((item) =>
                item?.displayname.includes(props.filterText)
              );
            }
          }

          return sortBCMData(children)?.map((item) => {
            if (
              props.filterLevel.includes(item?.level) ||
              props.filterLevel.length === 0
            ) {
              return <React.Fragment key={Math.random()}>{getCard(item)}</React.Fragment>;
            } else {
              return <div key={item?.id}></div>;
            }
          });
        }) : <Delayed waitBeforeShow={3500}><div className={`text-base font-bold mt-4 ${loading ? 'd-none' : ''}`}>No items found.</div></Delayed>
        }
      </div>
    </>
  );
};

export default BusinessCapabilityListCards;
