import { Card, Col, Collapse, Flex, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import { Link, useNavigate } from 'react-router-dom'
import PersonalServices from '../../../services/services/PersonalServices'
import ApplicationLandscapePng from "../../../assets/reports/ApplicationLandscape.png";
import BusinessCapabilityPng from "../../../assets/reports/BusinessCapability.png";
import BusinessProcessPng from "../../../assets/reports/BusinessProcess.png";
import IntegrationLandscapePng from "../../../assets/reports/IntegrationLandscape.png";
import ObjectiveOKRLandscapePng from "../../../assets/reports/Objective(OKR)Landscape.png";
import TechnologyLandscapePng from "../../../assets/reports/TechnologyLandscape.png";
import { getMenuPortfolio } from '../../../utils/settingCommon'
import { RecentlyViewedReport } from '../../reports/personal/PersonalFn'
import moment from 'moment'
import { UserOutlined } from '@ant-design/icons'

const HomeMyReports = () => {
    const [loginUserEmail] = useState(localStorage.getItem('email'));
    const [reports,setReports] = useState([]);
    const [total,setTotal] = useState(0);
    const [cardWidth] = useState(150);
    const navigate = useNavigate();
    const myReportsRef = useRef(null)

    useEffect(()=>{
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchData = async () => {
        try {
            let width = myReportsRef?.current.clientWidth-20;
            let w= Math.floor(width/cardWidth)
            
            let params = {
                limit:w*2,
                page: 1,
                search: "",
                userid: loginUserEmail,
            }
            const {data:response} = await PersonalServices?.getReports(params);
            if(response?.data){
                setReports(response?.data)
                setTotal(response?.totalCount)
            }else{
                setReports([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const navigateTo = (report) => {
        report.report_url = report?.report_url?.replace(window?.location.origin,"") || "";
        navigate(`${report?.report_url}?id=${report?.id}`);
    }

    return (
        <React.Fragment>
            <Col span={12} className='home-my-reports-intro'>
                <Collapse defaultActiveKey={['1']} size="small" bordered={true}  expandIconPosition="end">
                    <Collapse.Panel header={<PageTitleHeading text={"My Reports"} className={`my-0 text-primary`}/>} key="1" showArrow={true} ref={myReportsRef}>
                        <Flex className='p-2 ant-flex-wrap-wrap' justify='flex-start' gap={4}>
                            {   
                                reports?.map((report) => {
                                    let moduleData = getMenuPortfolio({moduleName:report?.payload?.moduleName})

                                    return <Card size="small" className={`text-left text-dark`} style={{maxWidth:cardWidth}} key={Math.random()}>
                                            <div className='pointer w-100 text-left d-flex align-items-start' onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report) }} >
                                                <Typography.Text
                                                    ellipsis={{
                                                        tooltip: report?.name || "Report",
                                                    }}
                                                    className='h4 mb-0 text-dark'
                                                >
                                                    {report?.name || "Report"}
                                                    <br/>
                                                    {moduleData?.name && `(${moduleData?.name})`}
                                                </Typography.Text>
                                            </div>
                                            <img 
                                                src={((()=>{
                                                    switch (report?.payload?.moduleName) {
                                                        case "business_application": return ApplicationLandscapePng;
                                                        case "business_capability": return BusinessCapabilityPng;
                                                        case "business_processes": return BusinessProcessPng;
                                                        case "integration": return IntegrationLandscapePng;
                                                        case "objective_okr": return ObjectiveOKRLandscapePng;
                                                        case "technology": return TechnologyLandscapePng;
                                                        default: return BusinessProcessPng;
                                                    }
                                                })())} 
                                                style={{
                                                    width:'100%',
                                                }}
                                                alt={`Report ${report?.name}`}
                                                className='pointer img-fluid'
                                                onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report); }}
                                            />
                                            <Flex justify='space-between' align='center' className='mt-1'>
                                                <div className='pointer w-100 text-left' onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report) }} >
                                                    <UserOutlined />&nbsp;
                                                    <Typography.Text
                                                        ellipsis={{
                                                            tooltip: report?.created_by || "",
                                                        }}
                                                        className='h4 mb-0 pb-0 text-dark'
                                                    >
                                                        {report?.user?.name}
                                                    </Typography.Text>
                                                    <h5 className='mb-0 text-dark'>({moment(report?.createdAt).format('DD-MM-YY H:mm:ss')})</h5>
                                                </div>
                                            </Flex>
                                    </Card>
                                })
                            }
                        </Flex>
                        <Link to='/personal/my-reports' className='btn btn-sm btn-primary m-2'>View All ({total})</Link>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </React.Fragment>
    )
}

export default HomeMyReports
