import React from 'react'
import { useParams } from 'react-router'
import Plant from '../components/portfolio/plant/Plant';
import PlantEntry from '../components/portfolio/plant/PlantEntry';
// import NoMatch from '../components/NoMatch';
import Initiative from '../components/portfolio/initiative/Initiative';
import InitiativeEntry from '../components/portfolio/initiative/InitiativeEntry';
import Integration from '../components/portfolio/integration/Integration';
import IntegrationEntry from '../components/portfolio/integration/IntegrationEntry';
// import { transformText } from '../utils/Common';
import BusinessApplication from '../components/portfolio/application/BusinessApplication';
import BusinessApplicationEntry from '../components/portfolio/application/BusinessApplicationEntry';
import  Dataobject   from '../components/portfolio/dataObject/DataObject';
import DataobjectEntry from '../components/portfolio/dataObject/DataObjectEntry';
import Informationobject from '../components/portfolio/informationObject/InformationObject';
import InformationobjectEntry from '../components/portfolio/informationObject/InformationObjectEntry';
import IntegrationBulkEntry from '../components/portfolio/integration/IntegrationBulkEntry';
import Technology from '../components/portfolio/technology/Technology';
import TechnologyEntry from '../components/portfolio/technology/TechnologyEntry';
import ObjectiveOkr from '../components/portfolio/objective(okr)/ObjectiveOkr';
import ObjectiveOkrEntry from '../components/portfolio/objective(okr)/ObjectiveOkrEntry'
import BusinessProcesses from '../components/portfolio/businessProcesses/BusinessProcesses';
import BusinessProcessesEntry from '../components/portfolio/businessProcesses/BusinessProcessesEntry';
import BusinessCapability from '../components/portfolio/businessCapability/BusinessCapability';
import BusinessCapabilityEntry from '../components/portfolio/businessCapability/BusinessCapabilityEntry';
import ApqcsProcesses from '../components/portfolio/apqc/ApqcProcesses';
import ApqcsProcessesEntry from '../components/portfolio/apqc/ApqcProcessesEntry';
import Resource from '../components/portfolio/resource/Resource';
import ResourceEntry from '../components/portfolio/resource/ResourceEntry';
import NotFound from '../components/NotFound';


const PortfolioPage = ({page}) => {
    const params = useParams();

    const components = {
        BusinessApplication,
        BusinessApplicationEntry,
        BusinessCapability,
        BusinessCapabilityEntry,
        BusinessProcesses,
        BusinessProcessesEntry,
        Plant,
        PlantEntry,
        Initiative,
        InitiativeEntry,
        Integration,
        IntegrationEntry,
        IntegrationBulkEntry,
        Dataobject,
        DataobjectEntry,
        Informationobject,
        InformationobjectEntry,
        Technology,
        TechnologyEntry,
        ObjectiveOkr,
        ObjectiveOkrEntry,
        ApqcsProcesses,
        ApqcsProcessesEntry,
        Resource,
        ResourceEntry,
    };

    const getComponentData = (moduleName) =>{
        let componentName = moduleName;
        let DynamicComponent;
        if(page==="index"){
            // console.log('testing components', components);
            // console.log('testing componentname', componentName);
            
            
            DynamicComponent = components[componentName];
        }
        if(page==="update" && params?.id==="bulkentry"){
            DynamicComponent = components[componentName+'BulkEntry'];
        }else if(page==="update"){
            DynamicComponent = components[componentName+'Entry'];
        }
        if(DynamicComponent){
            return <DynamicComponent {...params}/>; 
        }
        
        return <NotFound />; 
    }

    return (
        <>
            {getComponentData(params?.moduleName)}
        </>
    )
}

export default PortfolioPage