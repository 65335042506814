import { BellOutlined, ContainerOutlined, LogoutOutlined, RightOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import UserService from "../../../services/UserService";
import GlobalSearch from './GlobalSearch';
import { Button, Popover } from 'antd';
import NotificationHeader from './NotificationHeader';
import UserSvg from "../../../assets/Profile.svg";
const HeaderMenusItems = () => {
	// const userRole = UserService?.hasRole(['app-manager']);
	const userRole = 'admin';
	
	const getRoleName = () => {
		if(UserService.hasRole(['Admin'])){
			return "Admin";
		}else if(UserService.hasRole(['Editor'])){
			return "Editor";
		}else if(UserService.hasRole(['Viewer'])){
			return "Viewer";
		}else{
			return "Viewer";
		}
	}
	
	const headerMenus = [
		// {
		// 	// 'icon': <UserOutlined />,
		// 	'label': <GlobalSearch />,
		// 	'key': '#',
		// 	'navigate': 'false',
		// },
		{
			// 'icon': <UserOutlined />,
			'label': <Popover placement="bottomLeft" content={NotificationHeader}>
				<Button icon={<BellOutlined />} type='text' className='p-2'></Button>
			</Popover>,
			'key': 'notifications',
			'navigate': 'false',
		},
		{
			'icon':  <UserOutlined style={{color:`#fff`,filter:'none'}}/>,
			'label': "Hello, "+ UserService.getUserFullName().split(' ')[0] + `(${getRoleName()})`,
			'key': process.env.REACT_APP_KEY_CLOCK_USER,
			'navigate': 'false',
			// 'children': [
			// 	{
			// 		key: process.env.REACT_APP_KEY_CLOCK_USER,
			// 		navigate: false,
			// 		label: `Profile`,
			// 		icon: <RightOutlined />,
			// 	},
			// 	{
			// 		key: "personal",
			// 		navigate: false,
			// 		label: <>Reports <mark className="sidemenu-new-blink">new</mark></>,
			// 		icon: <ContainerOutlined style={{color:`#fff`,filter:'none'}}/>,
			// 		children: [
			// 			{
			// 				key: "personal/share-with-me",
			// 				navigate: false,
			// 				label: `Shared with Me`,
			// 				icon: <RightOutlined />,
			// 			},
			// 			{
			// 				key: "reports/recently-used",
			// 				navigate: false,
			// 				label: `Recently Used`,
			// 				icon: <RightOutlined />,
			// 			},
			// 			{
			// 				key: "personal/my-reports",
			// 				navigate: false,
			// 				label: `My Reports`,
			// 				icon: <RightOutlined />,
			// 			},
			// 		],
			// 	},
			// ],
		},
		{
			'icon': <LogoutOutlined />,
			'label': `Logout`,
			'key': 'logout',
			'navigate': 'false',
		}
	]
	return (headerMenus);
}

export default HeaderMenusItems;
