import { Button, Flex, Input, message, Modal, Pagination, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PageTitleHeading } from '../../utils/Common';
import Reports from './personal/Reports';
import PersonalServices from '../../services/services/PersonalServices';
import { RecentlyViewedReport } from './personal/PersonalFn';
import ChnageLogsServices from '../../services/services/ChangeLogsServices';
import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import {default_pageSizeOptions} from './../../utils/Common';

const MyReports = () => {
    const [loading,setLoading] = useState(true);
    const [reports,setReports] = useState([]);
    const [users,setUsers] = useState([]);
    const [loginUserEmail] = useState(localStorage.getItem('email'));
    const [open,setOpen] = useState(false);
    const [openShareWith,setOpenShareWith] = useState(false);
    const [inputSearch, setInputSearch] = useState('');
    const [defaultPageSize,setDefaultPageSize] = useState(default_pageSizeOptions);
    const [totalRecords,setTotalRecords] = useState(0);
    const [filter,setFilter] = useState({
        limit:10,
        page: 1,
        search: "",
    });
    
    useEffect(()=>{
        fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter])

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            let response = await ChnageLogsServices.getUsers();
            if(response?.data?.data){
                setUsers(response?.data?.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []) 

    const fetchData = async () => {
        try {
            setLoading(true);
            let params = {
                ...filter,
                userid: loginUserEmail,
            }
            const {data:response} = await PersonalServices?.getReports(params);
            let pageSize = [...default_pageSizeOptions,response?.totalCount];
            setDefaultPageSize(pageSize)
            setTotalRecords(response?.totalCount || 0)
            if(response?.data){
                setReports(response?.data)
            }else{
                setReports([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteReport = async (id) => {
        try {
            setLoading(true);
            Modal.confirm({
                centered: true,
                title: "Do you want to delete this report?",
                className: "modal-justify-start",
                icon: <ExclamationCircleFilled />,
                onOk: async () => {
                  try {
                    const {data:response} = await PersonalServices?.deleteReport(id);
                    if(response?.id){
                        if(response?.isDeleted){
                            message.success("Report deleted successfully");
                            fetchData()
                        }else{
                            message.success("Failed to delete Report");
                        }
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                },
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    const shareReport = async (report) => {
        try {
            const {data: response} = await PersonalServices.updateReport({...report,id:report?.id});
            if(response?.id){
                message.success("Report Updated Successfully");
                RecentlyViewedReport({type:'set',report:response})
                fetchData()
                setOpenShareWith(false);
            }else{
                message.error("Failed to update report");
            }
        } catch (error) {
            console.log("error: ",error)
        }
    }

    const updateReport = async (report) => {
        try {
            const {data: response} = await PersonalServices.updateReport({...report,id:report?.id});
            if(response?.id){
                message.success("Report Updated Successfully");
                RecentlyViewedReport({type:'set',report:response})
                fetchData()
                setOpen(false);
            }else{
                message.error("Failed to update report");
            }
        } catch (error) {
            console.log("error: ",error)
        }
    }

    return (
        <React.Fragment>
            <Spin spinning={loading} >
                <div className="container-fluid">
                    <Flex vertical gap={8}>
                        <div className="headingbx">
                            <Flex justify='space-between' align='center' gap={4} className='w-100'>
                                <PageTitleHeading text={`Personal Reports`} />
                            </Flex>
                        </div>
                        <Flex justify='flex-end'>
                            <div>
                                <Space.Compact block>
                                    <Input placeholder="Search Here..." value={inputSearch} onChange={(e)=>{ setInputSearch(e?.target?.value || "") }}/>
                                    <Button icon={<SearchOutlined />} onClick={()=>{
                                        setFilter({
                                            ...filter,
                                            search: inputSearch
                                        })
                                    }}/>
                                </Space.Compact>
                            </div>
                        </Flex>
                        <Reports
                            open={open}
                            setOpen={setOpen}
                            openShareWith={openShareWith}
                            setOpenShareWith={setOpenShareWith}
                            loading={loading}
                            reports={reports}
                            deleteReport={deleteReport}
                            shareReport={shareReport}
                            loginUserEmail={loginUserEmail}
                            updateReport={updateReport}
                            users={users}
                        />
                        <Flex justify='flex-end'>
                            <Pagination 
                                defaultPageSize={defaultPageSize}
                                showSizeChanger
                                defaultCurrent={1}
                                pageSize={filter?.limit}
                                current={filter?.page || 1} 
                                total={totalRecords} 
                                onChange={(page, pageSize)=>{
                                    setFilter(prev=>({
                                        ...prev,
                                        limit:pageSize,
                                        page,
                                    }))
                                }}
                            />
                        </Flex>
                    </Flex>
                </div>
            </Spin>
        </React.Fragment>
    )
}

export default MyReports
