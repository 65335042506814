import React, { useEffect, useState, useRef  } from "react";
import { useLocation  } from "react-router-dom";
import { Input,AutoComplete, Typography, Flex, Tooltip } from "antd";
import GlobalSearchServices from "../../../services/services/GlobalSearchServices";
import { CheckCircleOutlined, CopyOutlined, ExportOutlined, SearchOutlined } from "@ant-design/icons";

function GlobalSearchV2() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamSearch = queryParams.get('search');

    
    const searchContainerRef = useRef(null);
    const [copiedUrl, setCopiedUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchTearm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [visibleChange,setVisibleChange] = useState(false);

    useEffect(() => {
        console.log("inputRef",searchContainerRef)
    }, [queryParamSearch]);

    const handleSearch = async (searchText) => {
        setSearchTerm(searchText);
        setLoading(true);

        try {
            if(searchText != '')
            {
                const {data:response} = await GlobalSearchServices.getList(searchText);
                setResults(response.data);
            }
            else setResults("");
        } catch (error) {
            console.error("Error searching:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyUrl = (item) => {
        const url = `${window.location.origin}/${item.routeurl}/${item.id}`;
        navigator.clipboard.writeText(url)
            .then(() => {
                setCopiedUrl(item.id);
                setTimeout(() => {
                    setCopiedUrl(null);
                }, 2000);
            })
            .catch((error) => {
                console.error('Failed to copy URL to clipboard:', error);
            });
    };
    const handleOpenDiagram = (diagram_id) => {
        const url = `${window.location.origin}/diagram-editor/${diagram_id}`;
        window.open(url, '_blank');
    };
    const handleOpenInNewTab = (item) => {
        const url = `${window.location.origin}/${item.routeurl}/${item.id}`;
        window.open(url, '_blank');
    };

    const options = (options) => {

        return Object.entries(options)?.map(([label,values])=>{
            if(values?.length>0){
                return {
                    label:<div className="p-1" style={{backgroundColor:values[0]?.backgroundcolor}}>{label}</div>,
                    options: values?.map(item=>{
                        return {
                            value: item?.displayname,
                            label: (
                                <Flex gap={8} className="w-100" justify="space-between">
                                    <div>
                                        <Typography.Text
                                            ellipsis={true}
                                            style={{width:searchContainerRef?.current?.clientWidth-70}}
                                            title={item?.displayname}
                                        >
                                            {item?.displayname}
                                        </Typography.Text>
                                    </div>
                                    <Flex jusify="flex-end" align="center">                                                
                                        <Tooltip title={copiedUrl === item.id ? "Copied!" : "Copy Portfolio URL"}>
                                            {copiedUrl === item.id ? (
                                                <CheckCircleOutlined style={{ paddingLeft: '0.5rem', color: 'green' }} />
                                            ) : (
                                                <CopyOutlined style={{ paddingLeft: '0.5rem' }} onClick={() => handleCopyUrl(item)} />
                                            )}
                                        </Tooltip>
                                        {item?.diagram_id ? (
                                            <Tooltip title="Open diagram in new tab">
                                                <ExportOutlined style={{ marginLeft: '0.5rem' }} onClick={() => handleOpenDiagram(item?.diagram_id)} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Open in new tab">
                                                <ExportOutlined style={{ marginLeft: '0.5rem' }} onClick={() => handleOpenInNewTab(item)} />
                                            </Tooltip>
                                        )}   
                                    </Flex>
                                </Flex>
                            )
                        }
                    })
                }
            }else{
                return null
            }
        }).filter(f=>f!==null);
        
    }
    
    return (
        <div ref={searchContainerRef}>
            <AutoComplete
                allowClear
                options={visibleChange && options(results)}
                onDropdownVisibleChange={(open)=>setVisibleChange(open)}
                dropdownStyle={{ maxHeight: window?.innerHeight }}
                overlayStyle={{ maxHeight: window?.innerHeight }}
                className="w-100"
                popupClassName="global-search"
                onSelect={(value)=>{ setSearchTerm(""); }}
                onClear={()=>{ setSearchTerm("");}}
            >
                <Input 
                    value={searchTearm}
                    prefix={<SearchOutlined className="searchIcon" />}
                    placeholder="Search"
                    onChange={(e)=>handleSearch(e?.target?.value)}
                    style={{width:window?.innerWidth*20/100}}
                />
            </AutoComplete>
        </div>
    );
}

export default GlobalSearchV2;
