import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HomeOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Flex, Modal,Tooltip } from "antd";
import Delayed from "../../utils/Delayed";
import GlobalSearchServices from "../../services/services/GlobalSearchServices";

function ShortVideoList({
  data,
  setAddModel,
  setInitialValue,
  setModelHeaderText,
  fetchShortGuideVideo,
}) {
  const handleDelete = async (item) => {
    const response = await GlobalSearchServices.DeleteShortGuideVideo(item);
    if (response.status === 200) {
      fetchShortGuideVideo({});
    }
  };

  const handleAddRemoveHomeVideo = async (item) => {
    const response = await GlobalSearchServices.UpdateAndRemoveHomeVideo(item);
    if (response.status === 200) {
      fetchShortGuideVideo({});
    }
  };

  const Row = ({ item }) => {
    return (
      <div className="portfolio-listbx" style={{ marginBottom: 5 }}>
        <table width={`100%`}>
          <tbody>
            <tr>
              <td width={`20%`}>
                 <div className="embed-responsive embed-responsive-21by9">
                  <video width="60" height="40" controls>
                    <source
                      src={item.linkUrl}
                      type="video/mp4"
                      className="embed-responsive-item mt-0 pt-0"
                    />
                    <source
                      src={item.linkUrl}
                      type="video/ogg"
                      className="embed-responsive-item mt-0 pt-0"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                
              </td>

              <td width={`75%`}>
                <div style={{ padding: 10 }}>
                  <div className="des-bx" style={{ width: "100%" }}>
                    <h4 className="text-primary">{item.caption}</h4>
                  </div>
                  <div className="des-bx" style={{ width: "100%" }}>
                    <h4 className="text-primary"> {item?.tags?.join(",")} </h4>
                  </div>
                  <div className="des-bx" style={{ width: "100%" }}>
                    <ExpandableText text={item?.description} maxLength={250} />
                  </div>
                </div>
              </td>
              <td width={`5%`} className="text-nowrap">
                <Flex gap={8}>
                  <EditOutlined
                    className="cursor-pointer ml-2 text-danger"
                    onClick={() => {
                      setInitialValue(item);
                      setAddModel(true);
                      setModelHeaderText("Edit");
                    }}
                    style={{ fontSize: "20px" }}
                  />
                  <DeleteOutlined
                    className="cursor-pointer ml-2 text-danger"
                    onClick={() => {
                      Modal.confirm({
                        centered: true,
                        title: "Do you want to delete this video?",
                        icon: <ExclamationCircleFilled />,
                        // content: 'Some descriptions',
                        async onOk() {
                          await handleDelete(item);
                        },
                        onCancel() {
                          // console.log('Cancel');
                        },
                      });
                    }}
                    style={{ fontSize: "20px" }}
                  />

                  {((!item?.showonhome || item?.showonhome == null) && (
                    <Tooltip title="Show">
                      <HomeOutlined
                        className="cursor-pointer ml-2 text-danger"
                        onClick={async () => {
                          await handleAddRemoveHomeVideo(item);

                          // Modal.confirm({
                          //     centered: true,
                          //     title: "Do you want to delete this video?",
                          //     icon: <ExclamationCircleFilled />,
                          //     // content: 'Some descriptions',
                          //     async onOk() {
                          //        await handleDelete(item);
                          //     },
                          //     onCancel() {
                          //       // console.log('Cancel');
                          //     },
                          //   });
                        }}
                        style={{ fontSize: "20px" }}
                      />
                    </Tooltip>
                  )) || (
                    <Tooltip title="Hide">
                      <CloseCircleOutlined
                        className="cursor-pointer ml-2 text-danger"
                        style={{ fontSize: "20px" }}
                        onClick={async () => {
                          await handleAddRemoveHomeVideo(item);
                        }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ExpandableText = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    if (text?.length <= maxLength) {
      return <p>{text}</p>;
    }

    if (text?.length > maxLength) {
      return (
        <div>
          <p>{isExpanded ? text : `${text?.substring(0, maxLength)}...`}</p>
          {/* <button onClick={toggleExpand} style={{ color: "blue", cursor: "pointer" }}>
              {isExpanded ? 'Show Less' : 'Show More'}
            </button> */}
        </div>
      );
    }
  };

  return (
    <div
      //className="overflow-y pr-3 listview_bx"
      className="overflow-y pr-1 listview_bx"
      style={{ maxHeight: window?.innerHeight - 100, width: "100%" }}
    >
      {(data?.length > 0 &&
        data?.map((item, index) => (
          <Row key={index} item={item} isLast={index === data.length - 1} />
        ))) || <Delayed waitBeforeShow={2000}>No items found.</Delayed>}
    </div>
  );
}

export default ShortVideoList;
