import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function selectedIndustry(data) {
    const url = `${API_BASE_URL}portfoliosetting/industry`;
    let response = await http.post(url, data);
    return response;
}
export default {
    selectedIndustry,
}; 