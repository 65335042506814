import React, { useEffect, useState } from "react";
// import { GanttOriginal, Task, ViewMode } from "react-gantt-chart";
import { Gantt, Task, ViewMode } from "gantt-task-react-awa";
import "gantt-task-react-awa/dist/index.css";
import { Slider, Tooltip, Typography } from "antd";
import moment from "moment";


function CompareChart({ events,limit,sliderMarks }) { 
    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        if (events) {
            setTaskList(events);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);
    
    const handleDblClick = (task) => {
        // console.log("On Double Click event Id:" + task.id);
    };

    const handleExpanderClick = (task) => {
        setTaskList(() => taskList.map((t) => (t.id === task.id ? task : t)));
    };


  return (
    <div>
        { taskList && taskList.length && 
            <Gantt
                tasks={
                    taskList?.map(task=>{
                        if(limit?.length>0){     
                            var endDate = (new Date(task?.end)).getTime();
                            var date1 = (new Date(sliderMarks[limit[0]])).getTime();
                            var date2 = (new Date(sliderMarks[limit[1]+1])).getTime();
                            if(task.type==="project" || task.type==="task"){
                                return task;
                            }else if(date1>endDate || date2<endDate){
                                return null
                            } 
                        }
                        return task;
                    })?.filter(f=>f!=null)
                }
                viewMode={ViewMode.Month}
                columnWidth={70}
                rowHeight={30}
                //ganttHeight={500}
                listCellWidth={true ? "500px" : ""}
                onDoubleClick={handleDblClick}
                onExpanderClick={handleExpanderClick}
                // onDateChange={handleTaskChange}
                // onDelete={handleTaskDelete}
                // onProgressChange={handleProgressChange}
                // onClick={handleClick}
                // onSelect={handleSelect}

                projectBackgroundColor={'#59a985'}
                //arrowColor={'#000fff'}
                //TooltipContent= {MyToolTipComponent}
                //locale={"en-IN"}
                // rtl={false}
            />
        }
    </div>
  );
}

export default CompareChart;
