import Keycloak from "keycloak-js";
import anonymousRoutesJson from "../routes/anonymousRoutesJson";
import Cookies from 'universal-cookie';
import http from './services/httpService';

const _kc = new Keycloak('/keycloak.json');
const API_BASE_URL = process.env.REACT_APP_API_URL

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    // onLoad: 'login-required',
    // onLoad: 'check-sso',
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // pkceMethod: 'S256',
  })
    .then((authenticated) => {
      sessionStorage.removeItem("handleRemoveRelationship");
      sessionStorage.setItem("showWelcomeVideo",true);
      if (!authenticated) {
        console.log("user is not authenticated..!");
        handleUnauthenticated();
      }
      onAuthenticatedCallback();
      startTokenRefresh(); 
    })
    .catch(console.error);
};

const handleUnauthenticated = () => {
  let pathname = window.location.pathname;
  pathname = pathname.split("/").filter((f) => f !== '');

  if (!anonymousRoutesJson.includes(pathname[0])) {
    console.log(`Path "${pathname}" requires authentication. Redirecting to login.`);
    doLogin();  // Redirect user to login if not authenticated
  }
};

const doLogin = _kc.login;

const doLogout = () => {
  console.log("Token expired or session invalid. Logging out.");
  localStorage.removeItem("step-intro-enabled")
  _kc.logout();
};

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(60) // Refresh token 60 seconds before expiry
    .then(successCallback)
    .catch(() => {
      console.log("Token refresh failed. Logging out.");
      doLogout();
    });

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUserdetails = () => _kc.tokenParsed;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getUserFullName = () => _kc.tokenParsed?.name;

const getValidToken = () => {
  const cookies = new Cookies();

  let token = _kc.token;
  if(!token){
			token=cookies.get('access_token');

  }
 return token
};

async function saveUserData(formData) {
  const url = `${API_BASE_URL}masterdata/saveUserData`;
  let response = await http.post(url, formData);
  return response;
}

const startTokenRefresh = () => {
  const refreshInterval = 60000; // 1 minute
  setInterval(() => {
    if (isLoggedIn()) {
      updateToken(() => {
        // console.log("Token successfully refreshed.");
      });
    }
  }, refreshInterval); // Periodically check the token
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserFullName,
  getValidToken,
  getUserdetails,
  saveUserData
};

export default UserService;
