import React, { useState, useEffect, useRef } from "react";
import { Form, Spin, Row, Col, Modal, message, Button, Select, Space, Flex, Input, Tooltip, } from "antd";
import { getScenarioType, getScenarioSubType, getInitiatives, } from "../../utils/ScenarioCommon";
import { LoadingOutlined, } from "@ant-design/icons";
import ScenarioServices from "../../services/services/ScenarioServices";
import FormFields from "../../utils/FormFields";
import { useNavigate, useParams } from "react-router";
import { PageTitleHeading, convertObjectToArray, groupedData, } from "../../utils/Common";
import useUserData, { checkRole } from "../../helper/useUserData";
import useScenarioFetch from "../../hooks/useScenarioFetch";
import { useScenarioList } from "../../Context/ScenarioListProvider";
import ScenarioItemsServices from "../../services/services/ScenarioItemsService";
import CopyExistingScenario from "./copyExisting/CopyExistingScenarioItems";
import { SaveRecentlyView_Scenario } from "../../utils/Common";
import { isDisabledRole } from "../../utils/settingCommon";
import UserService from "../../services/UserService";

const initialValues = {
    scenarioName: "",
    scenarioTypeID: "1",
    scenarioSubtypeID: null,
    description: "",
    intiativeId: null,
    visibility: 1,
    copyFrom: 1,
    displayType: "tabular",
};

const ScenarioAddModal = ({open,setOpen,scenarioId,modal=true,heading=true,formView=true,...props}) => {
    const navigate = useNavigate();
    const [newScenarioForm] = Form.useForm();
    const [initiativeForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [scenarioType, setScenarioType] = useState([]);
    const [scenarioSubType, setScenarioSubType] = useState([]);
    const [initiatives, setInitiatives] = useState([]);
    const [openInitiativeModal, setOpenInitiativeModal] = useState(false);
    const userData = useUserData();
    const { id: paramsId = null } = useParams();
    const [scenarioData,setScenarioData] = useState({});

    const { dispatch: scenarioDispatch, state: scenarioState } = useScenarioList();
    const [filterOption, setFilterOption] = useState({
        search: "",
        page: 1,
        limit: 200,
        isScenarioType: true,
        isUser: true,
    });
    const [copyFromExist, setCopyFromExist] = useState([]);
    const [showExistingScenario, setShowExistingScenario] = useState(false);
    const [existingScenarioItems, setExistingScenarioItems] = useState({});
    const [openExScenarioModal, setOpenExScenarioModal] = useState(false);
    const [scenarioItem, setScenarioItem] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [scenarioItemLoading, setScenarioItemLoading] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    // Memoize the result of usePortfolioRelationshipFetch
    const loadingState = useScenarioFetch(filterOption);

    let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
    openedScenarios = JSON.parse(openedScenarios);

    const [recentlyOpenScenarios] = useState(openedScenarios || []);
    const [userDataList, setUserDataList] = useState([]);
    const [userDataSelectOption, setUserDataSelectOption] = useState([]);
    const [collaborateList, setCollaborateList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    //const [defaultCollaborateList, setDefaultCollaborateList] = useState([]);
    //const [defaultApproverList, setdefaultApproverList] = useState([]);

    useEffect(() => {
        getScenarioType(loading, setLoading, setScenarioType);
        getScenarioSubType(loading, setLoading, setScenarioSubType);
        getInitiatives(loading, setLoading, setInitiatives);
        userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(loadingState);
    }, [loadingState]);

    const getScenarioById = async () => {
        try {
            setLoading(true);

            const paramsObj = {
                id: paramsId,
                isScenarioType: true,
                isUser: true,
                isInitiative: true,
            };

            const response = await ScenarioServices.getOneScenarioById(paramsObj);
            if (response && response?.data?.code === 200) {
                const responseData = response?.data?.data || {};

                const {
                    intiativeId,
                    name,
                    visibility,
                    scenarioTypeID,
                    description,
                    user,
                    scenarioType,
                    initiative,
                    approvers,
                    collaborators,
                } = responseData;

                setCollaborateList(collaborators);
                setApproverList(approvers);

                const resolvedInitiativeId =
                    initiative?.id?.toString() === "0" ||
                    intiativeId?.toString() === "0" ? null : initiative?.id?.toString() || intiativeId?.toString();

                const newValues = {
                    scenarioName: name,
                    scenarioTypeID: scenarioType?.id?.toString() || scenarioTypeID?.toString(),
                    intiativeId: resolvedInitiativeId,
                    description: description,
                    visibility: visibility,
                    collaborator: collaborators,
                    approver: approvers,
                };

                setInitialFormValues((prevValues) => ({
                    ...prevValues,
                    ...newValues,
                }));
                props?.setScenarioData(responseData)
                setScenarioData(responseData);
            } else {
                message.error("Scenario not found or deleted.");
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching Scenario :", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (paramsId) {
            getScenarioById();
        } else {
            setInitialFormValues(initialValues);
            setCollaborateList([]);
            setApproverList([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsId]);

    useEffect(() => {
        newScenarioForm?.setFieldsValue({ ...initialFormValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormValues]);

    useEffect(() => {
        if (scenarioState?.data?.length > 0) {
            setCopyFromExist(
                paramsId
                    ? scenarioState?.data?.filter(
                          (item) => item?.id !== paramsId,
                      )
                    : scenarioState?.data || [],
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioState]);

    const userList = async () => {
        const userList = await ScenarioServices.userList();
        if (userList?.data?.code === 200) {
            setUserDataList(userList?.data?.data);

            let options = [];
            userList?.data?.data?.map((item) => {
                options.push({
                    label: item.name,
                    value: item.id,
                });
            });

            setUserDataSelectOption(options);
        } else {
        }
    };

    const onAddInitiativeSubmit = (formData) => {
        setLoading(true);
        let obj = {};
        obj.DisplayName = formData?.name;
        obj.tableName = "initiatives";
        let newInitiativesLength = initiatives?.filter(
            (f) =>
                f.label?.toLowerCase()?.trim() ===
                formData?.name?.toLowerCase()?.trim(),
        )?.length;
        if (newInitiativesLength > 0) {
            setLoading(false);
            return message.error(
                "Initiative " + formData?.name + " already exist",
            );
        }
        ScenarioServices.addInitiative(obj)
            .then((response) => {
                if (response?.data?.code === 200) {
                    getInitiatives(loading, setLoading, setInitiatives);
                    initiativeForm.resetFields();
                    newScenarioForm.setFieldsValue({ intiativeId: "" });
                }
                setLoading(false);
                setOpenInitiativeModal(false);
            })
            .catch((err) => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const setRecentOpenScenario = (scenario) => {
        if (recentlyOpenScenarios?.length === 10) {
            recentlyOpenScenarios.shift();
        }
        let tempScenarios = recentlyOpenScenarios.filter(
            (f) => f?.id?.toString() !== scenario?.id?.toString(),
        );
        tempScenarios.push(scenario);
        tempScenarios = JSON.stringify(tempScenarios);
        localStorage.setItem("recentlyOpenScenarios", tempScenarios);
    };

    const onFormSubmit = async (formData) => {
        try {
            const userID = localStorage.getItem("email");

            setLoading(true);
            const selectedValues = convertObjectToArray(selectedItems);
            if (
                selectedValues?.length === 0 &&
                formData?.copyFrom === 2 &&
                formData?.existingScenarioId
            ) {
                return message.info("Please select at least one portfolio item.");
            }

            const formValues = {
                ...formData,
                selectedScenarioItems: selectedValues,
                createdBy: userData?.email || null,
                collaborateList: formData?.collaborator,
                approverList: formData?.approver,
            };

            //console.log("formValues",formValues);return;

            const response =
                paramsId === null || paramsId === undefined
                    ? await ScenarioServices.createScenarioV2(formValues)
                    : await ScenarioServices.editScenarioV2({
                        ...formValues,
                        id: paramsId,
                    });

            if (response?.data?.code === 200) {
                let responseVal = response?.data?.data;

                setRecentOpenScenario(response?.data?.data);

                message.success(`${paramsId ? "Updated" : "Created"} Successfully.`);
                localStorage.setItem("scenarioName",formData?.scenarioName);

                SaveRecentlyView_Scenario(responseVal?.id, formData?.scenarioName);

                // Update Recent Open
                if (paramsId) {
                    let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
                    openedScenarios = JSON.parse(openedScenarios);
                    openedScenarios = openedScenarios?.map((sce) => {
                        if (paramsId?.toString() === sce?.id?.toString()) {
                            return responseVal;
                        }
                        return sce;
                    });
                    let tempScenarios = JSON.stringify(openedScenarios);
                    sessionStorage.setItem("recentlyOpenScenarios", tempScenarios);
                    getScenarioById();
                }else{
                    setTimeout(async () => {
                        newScenarioForm.resetFields();
                        setLoading(false);
                        setExistingScenarioItems({});
                        setOpenExScenarioModal(false);
                        setScenarioItem([]);
                        return navigate(`/scenario-list-v2/${responseVal?.id}`);
                    }, 500);
                }
                setOpen(false)
            } else {
                message.error( response?.data?.msg || "Failed to submit form. Please try again.");
            }
        } catch (error) {
            console.error(error);
            message.error("Failed to submit form. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleValuesChange = (changedValues, allValues) => {
        if ("copyFrom" in changedValues) {
            setExistingScenarioItems({});
            if (allValues.copyFrom !== 2) {
                setSelectedItems([]);
                newScenarioForm.resetFields(["existingScenarioId"]);
            }
            setShowExistingScenario(allValues.copyFrom === 2);
        }
    };

    const handleSelectChange = (value, name) => {
        const copiedItem =
            copyFromExist?.find(
                (item) => item?.id.toString() === value.toString(),
            ) || {};
        setExistingScenarioItems(copiedItem);
        setScenarioItem([]);
        setSelectedItems({});
        newScenarioForm.setFieldsValue({ [name]: value });
    };

    const getAllScenariosItems = async (scenarioId) => {
        try {
            setScenarioItemLoading(true);
            const response =
                await ScenarioItemsServices.getScenariosItemsByScenarioId(
                    scenarioId,
                );
            if (response && response?.data?.code === 200) {
                const responseData = response?.data?.data || [];
                setScenarioItem(responseData);

                // Initialize selected items with all options selected by default
                if (responseData?.length > 0) {
                    const groupedItems = groupedData(responseData);
                    const initialSelectedItems = {};
                    Object.keys(groupedItems).forEach((name) => {
                        initialSelectedItems[name] = groupedItems[name].map(
                            (item) => item.id,
                        );
                    });
                    setSelectedItems(initialSelectedItems);
                } else {
                    setSelectedItems([]);
                    message.error(
                        `Scenario items not found for ${existingScenarioItems?.name}`,
                    );
                }
            } else {
                setScenarioItem([]);
            }
            setScenarioItemLoading(false);
        } catch (error) {
            console.error("Error fetching Scenario items:", error);
        } finally {
            setScenarioItemLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(existingScenarioItems)?.length > 0) {
            console.log("paramsId", paramsId);
            if (!paramsId) {
                console.log("paramsId-hello", paramsId);
                getAllScenariosItems(existingScenarioItems?.id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingScenarioItems?.id]);

    useEffect(() => {
        if (scenarioItem && existingScenarioItems?.id) {
            setOpenExScenarioModal(scenarioItem?.length > 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioItem]);

    const handleSelectedItemsChange = (newSelectedItems) => {
        setSelectedItems(newSelectedItems);
    };

    const handleChangeApprover = (tags) => {
        setApproverList(tags);
        // setdefaultApproverList(tags);
    };

    const validateUserRight = (collaboratorID, createdBy) => {
        const userEmailID = localStorage.getItem("email") || "";
        if (userDataList && userDataList.length) {
            const CollaboratoremailID = userDataList
                .filter((item) => collaboratorID?.includes(item.id))
                .map((item) => ({ id: item.id, email: item.email }));

            if ( userEmailID===createdBy || CollaboratoremailID?.some(item => item.email === userEmailID) ){ 
                return true;
            } else {
                return false;
            }
        }
    };

    const ScenarioForm = () => {
        return <>
            {heading && <div className="headingbx pt-0">
                <Flex justify="space-between" align="center" gap={4} className="w-100" >
                    <PageTitleHeading text={`${paramsId ? initialFormValues?.scenarioName : "New Scenario"}`} className={`my-0`}/>
                </Flex>
            </div>}
            <Form
                labelCol={{span: 6,}}
                form={newScenarioForm}
                requiredMark={false}
                className="pt-3 form-row-splitter"
                initialValues={initialFormValues}
                onFinish={onFormSubmit}
                onValuesChange={handleValuesChange}
            >
                <Row gutter={[12,12]}>
                    <Col lg={modal ?24:12}>
                        <Form.Item
                            name={`scenarioName`}
                            label={ <> Name <span className="text-danger">*</span> </> }
                            rules={[{ required: true, message: "Please enter scenario name", },]}
                        >
                            <Input
                                type="text"
                                placeholder={`Scenario Name`}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={modal ?24:12}>
                        <Form.Item
                            name={`description`}
                            label={ <> Description <span className="text-danger">*</span> </> }
                            rules={[{ required: true, message: "Please enter scenario description", },]}
                            className="pb-2"
                        >
                            <Input.TextArea
                                type="text"
                                placeholder={`Description`}
                                rows={2}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={modal ?24:12}>
                        <Form.Item
                            name={`intiativeId`}
                            label={ <> Initiative/Project </> }
                            rules={[
                                {
                                    required: false,
                                    message: "Please select initiative",
                                },
                            ]}
                        >
                            <Select
                                showSearch={true}
                                optionFilterProp="label"
                                placeholder={`Please select Initiative/Project`}
                                options={initiatives?.map((e) => {
                                    return {
                                        label: e?.label?.toString(),
                                        value: e?.value?.toString(),
                                    };
                                }).sort((a, b) => a?.label?.localeCompare(
                                    b?.label,
                                    undefined, {
                                        numeric: true,
                                        sensitivity: 'base'
                                    }
                                ))}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={modal ?24:12}>
                        <Form.Item
                            name={`collaborator`}
                            label={ <> Collaborator </> }
                            rules={[
                                {
                                    required: false,
                                    message: "Please select Collaborator",
                                },
                            ]}
                        >
                            <Select
                                showSearch={true}
                                allowClear={true}
                                mode="multiple"
                                optionFilterProp="label"
                                placeholder={`Please select Collaborator`}
                                options={userDataSelectOption?.sort((a, b) => a?.label?.localeCompare(
                                    b?.label,
                                    undefined, {
                                        numeric: true,
                                        sensitivity: 'base'
                                    }
                                ))}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={modal ?24:12}>
                        <Form.Item
                            label={<>Approver</>}
                            name={`approver`}
                            className={`text-start mb-0`}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%", }}
                                placeholder="Please select Approver"
                                value={approverList}
                                onChange={handleChangeApprover}
                                options={userDataSelectOption?.sort((a, b) => a?.label?.localeCompare(
                                    b?.label,
                                    undefined, {
                                        numeric: true,
                                        sensitivity: 'base'
                                    }
                                ))}
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .includes(
                                            input.toLowerCase(),
                                        )
                                }
                            />
                        </Form.Item>
                    </Col>
                    {!paramsId && (
                        <Col lg={modal ?24:12}>
                            <FormFields
                                type={`Radio`}
                                label={
                                    <>
                                        Scenario Options{" "}
                                        <span className="text-danger">*</span>
                                    </>
                                }
                                name={`copyFrom`}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select scenario option",
                                    },
                                ]}
                                formClassName={`text-start mb-1 hide-div`}
                                initialValue={1}
                                options={[
                                    { value: 1, label: `Start from scratch` },
                                    { value: 2, label: `Copy from existing` },
                                ]}
                            />
                        </Col>
                    )}
                    {(showExistingScenario && (
                        <Col lg={modal ?24:12}>
                            <Form.Item
                                label="Pick Existing Scenario"
                                //name={`existingScenarioId`}
                                className={`text-start mb-0`}
                            >
                                <Row gutter={8} className="mx-0 my-0">
                                    <Col span={scenarioItemLoading ? 20 : 24}>
                                        <FormFields
                                            type={`select`}
                                            name={`existingScenarioId`}
                                            placeholder="Select"
                                            rules={[
                                                {
                                                    required: showExistingScenario,
                                                    message: "Please select existing scenario",
                                                },
                                            ]}
                                            formClassName={`text-start mb-0`}
                                            optionFilterProp="label"
                                            options={copyFromExist?.map((e) => {
                                                return {
                                                    label: e?.name,
                                                    value: e?.id,
                                                };
                                            })}
                                            disabled={scenarioItemLoading}
                                            onChange={(value) => {
                                                handleSelectChange(value, "existingScenarioId");
                                            }}
                                        />
                                    </Col>
                                    {scenarioItemLoading && (
                                        <Col span={4}>
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        className="font-[10px]"
                                                        spin
                                                    />
                                                }
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Form.Item>
                        </Col>
                    )) || <></>}
                    
                </Row>
                <Flex
                    justify="end"
                >
                    {
                        ((()=>{
                            if(checkRole("update") && formView && paramsId){
                                const validation = validateUserRight(
                                    scenarioData.collaborators,
                                    scenarioData.createdBy
                                );
                                
                                if (!validation && !UserService?.hasRole(['Admin'])) {
                                    // message.error("you have not right to edit the selected scenario");
                                    return <Tooltip title={`Editing restricted`}>
                                        <Button
                                            type="default"
                                            htmlType="button"
                                            className="pull-left px-3"
                                            size="middle"
                                            loading={loading}
                                            disabled={UserService.hasRole(['Admin'])?false:true}
                                            iconPosition={"start"}
                                        >
                                            <span>{paramsId ? "Update" : "Next"}</span> 
                                        </Button>
                                    </Tooltip>
                                }else{
                                    return <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="pull-left px-3"
                                        size="middle"
                                        loading={loading}
                                        disabled={scenarioItemLoading}
                                        iconPosition={"start"}
                                    >
                                        <span>{paramsId ? "Update" : "Next"}</span> 
                                    </Button>
                                }
                            }else{
                                let disabled = isDisabledRole(["Admin", "Editor"]) ? true : scenarioItemLoading;
                                return <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="pull-left px-3"
                                    size="middle"
                                    loading={loading}
                                    disabled={disabled}
                                    iconPosition={"start"}
                                >
                                    <span>{paramsId ? "Update" : "Next"}</span> 
                                </Button>
                            }
                        })())
                    }
                </Flex>
            </Form>
        </>
    }

    return (
        <>
            {modal ? <Modal
                footer={false}
                destroyOnClose
                closable={true}
                maskClosable={false}
                open={open}
                onCancel={()=>{ setOpen(!open) }}
            >
                <ScenarioForm/>
            </Modal> : <ScenarioForm/>}
            {/* Initiative */}
            <Modal
                title={"Add Initiative"}
                open={openInitiativeModal}
                footer={false}
                destroyOnClose
                closable={true}
                maskClosable={false}
                onCancel={() => setOpenInitiativeModal(false)}
            >
                <Form
                    form={initiativeForm}
                    onFinish={onAddInitiativeSubmit}
                    requiredMark={false}
                    initialValues={{}}
                >
                    <FormFields
                        type={`input`}
                        label={`Name`}
                        name={`name`}
                        rules={[
                            {
                                required: true,
                                message: "Please enter initiative name",
                            },
                        ]}
                        formClassName={`text-start mb-1`}
                        placeholder="Initiative name"
                    />
                    <Form.Item>
                        <Button
                            className="ms-auto d-block pt-2"
                            htmlType="submit"
                            disabled={loading}
                        >
                            {loading ? "Please Wait" : "Save"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Copy existing scenario */}
            <Modal
                title={
                    <span className="ml-3">{`Copy item from "${existingScenarioItems?.name}"`}</span>
                }
                open={openExScenarioModal}
                footer={
                    <div className="mr-3">
                        <Button
                            type="primary"
                            onClick={() => {
                                const selectedValues =
                                    convertObjectToArray(selectedItems);
                                console.log(
                                    "selectedValues",
                                    selectedValues,
                                );
                                if (selectedValues?.length > 0) {
                                    setOpenExScenarioModal(false);
                                } else {
                                    message.info(
                                        "Please select at least one portfolio item.",
                                    );
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                }
                destroyOnClose
                closable={true}
                maskClosable={false}
                width={window.innerWidth / 2.2}
                onCancel={() => {
                    setSelectedItems({});
                    setOpenExScenarioModal(false);
                    setExistingScenarioItems({});
                    newScenarioForm.resetFields(["existingScenarioId"]);
                }}
            >
                <div className="p-3">
                    <CopyExistingScenario
                        setOpenExScenarioModal={setOpenExScenarioModal}
                        openExScenarioModal={openExScenarioModal}
                        data={scenarioItem}
                        scenarioItem={existingScenarioItems}
                        setSelectedItems={handleSelectedItemsChange}
                        selectedItems={selectedItems}
                    />
                </div>
            </Modal>
        </>
    );
};
export default ScenarioAddModal;
