import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import React from 'react';
import { PageTitleHeading } from '../../utils/Common';
import { Flex, Typography } from 'antd';

const rotate90 = {
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    marginTop: '35px',
    // margin:'50px -5px 10px',
}
const FilterButtonToggle = ({setShowFilter,showFilter,text,setSelectedFilters}) => {
    const handleMouseEnter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <Flex 
            vertical={!showFilter} 
            justify='space-between'
            align={`center`}
            className='pointer my-2'
            gap={8}
            onClick={()=>setShowFilter(!showFilter)}
        >
            <div className={`${showFilter?`col p-0`:``}`} style={{width:20}}>{showFilter?<UnlockOutlined style={{ fontSize: "18px", color: "#497aab" }}/>:<LockOutlined style={{ fontSize: "18px", color: "#497aab",}}/>}</div>
            <div className={`${showFilter?`col p-0`:`d-flex align-items-center animatefilter`}`} style={showFilter?{margin:'0px 0px 0px'}:rotate90}><Typography.Text style={{fontSize:18}} className='pull-right mb-0 pb-0 text-primary text-uppercase' strong>{text}</Typography.Text></div> 
            {/* {<PageTitleHeading text={text} size={4} className={`pull-right mb-0 pb-0`}/>} */}
        </Flex>
    );

    return (
        <div 
            className={`w-100 cursor-pointer`} 
            onClick={()=>setShowFilter(!showFilter)}
            // onMouseEnter={()=>handleMouseEnter}
            // onMouseLeave={()=>handleMouseEnter}
        >
            <div className={showFilter?`row align-items-center filterbx`:`pt-3`}>
                <div className={`${showFilter?`col p-0 d-flex justify-content-end `:``}`} style={{width:20}}>{showFilter?<UnlockOutlined style={{ fontSize: "18px", color: "#497aab" }}/>:<LockOutlined style={{ fontSize: "18px", color: "#497aab",}}/>}</div>
                <div className={`${showFilter?`col p-0 text-left`:`d-flex align-items-center animatefilter`}`} style={showFilter?{margin:'0px 0px 0px'}:rotate90}><PageTitleHeading text={text} size={3} className={``}/></div> 
            </div>
        </div>
    )
}

FilterButtonToggle.defaultProps = {
    text:"Filter",
}

export default FilterButtonToggle