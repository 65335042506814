import { Button, Checkbox, Collapse, Divider, Flex, Input, Select } from "antd";
import React, { useState } from "react";
import MultiSelectPopover from "./MultiSelectPopover";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const SearchContent = ({
  handleChangeSearchBy,
  handleSearch,
  handleSearchChange,
  search,
  options,
  handleSelectAll,
  handleClearAll,
  selectedItems,
  activeCollapseProKey,
  isCollapsable,
}) => {
  const [activeKey, setActiveKey] = useState(activeCollapseProKey);
  const handlePanelChange = (key) => {
    setActiveKey(key);
  };
  const handleSelectAllItems = () => {
    handleSelectAll(options.map((option) => option.value));
  };

  const handleClearAllItems = () => {
    handleClearAll([]);
  };

  const handleIconClick = (key) => {
    setActiveKey([key]);
  };

  return (
    <>
      {isCollapsable ? (
        <Collapse
          activeKey={activeKey}
          defaultActiveKey={activeKey}
          expandIconPosition="end"
          className="life-cycle-stages-filter my-2"
          //   expandIcon={({ isActive }) =>
          //     isActive ? (
          //       <DownOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
          //     ) : (
          //       <RightOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
          //     )
          //   }
          onChange={handlePanelChange}
          collapsible="icon"
        >
          <Panel
            showArrow={false}
            header={
              <Flex gap={8} align="center" className="mb-2">
                <Input.Search
                  placeholder="Input search text"
                  onSearch={() => {
                    // setActiveKey(["0"]);
                    handleSearch();
                  }}
                  style={{ width: `100%` }}
                  onChange={(e) => {
                    // setActiveKey(["0"]);
                    handleSearchChange(e.target.value);
                  }}
                  onFocus={() => {
                    // setActiveKey(["0"]);
                  }}
                  onBlur={() => {
                    // setActiveKey(["0"]);
                  }}
                  value={search}
                  name="name"
                />
                <div
                  onClick={() =>
                    handleIconClick(activeKey.includes("1") ? "0" : "1")
                  }
                >
                  {activeKey.includes("1") ? (
                    <DownOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  )}
                </div>
              </Flex>
            }
            className="px-0"
            key="1"
          >
            {options?.length > 0 ? (
              <div className="items-start p-1 m-1">
                <Flex
                  gap={8}
                  justify="space-between"
                  align="center"
                  className="my-2"
                >
                  <Button
                    type="primary"
                    onClick={handleSelectAllItems}
                    size="small"
                    disabled={
                      options?.length === selectedItems?.length ||
                      options?.length === 0
                    }
                  >
                    Select All
                  </Button>
                  <Button
                    type="text"
                    onClick={handleClearAllItems}
                    size="small"
                  >
                    Clear All
                  </Button>
                </Flex>
                <Divider />
                <Checkbox.Group
                  style={{ width: "100%" }}
                  key={`check-box-group`}
                  value={selectedItems}
                  onChange={(values) => handleChangeSearchBy(values)}
                >
                  {options.map((option, i) => (
                    <label
                      className="w-100 d-flex align-items-center"
                      key={option?.id}
                    >
                      <Checkbox value={option?.value} key={Math.random()}>
                        {option?.label}
                      </Checkbox>
                    </label>
                  ))}
                </Checkbox.Group>
              </div>
            ) : (
              <div className="items-start p-1 m-1">No items found.</div>
            )}
          </Panel>
        </Collapse>
      ) : (
        <Flex gap={8} align="center" className="mb-2" vertical>
          {options && options?.length > 0 && (
            <div style={{ width: `100%` }}>
              <MultiSelectPopover
                options={options}
                onSelect={handleChangeSearchBy}
                handleClearAll={handleClearAll}
                handleSelectAll={handleSelectAll}
                selectedItems={selectedItems}
                placement="leftBottom"
                title="Select search items"
                tags={false}
              />
            </div>
          ) || <div>No items found.</div>}
          <Input.Search
            placeholder="Input search text"
            onSearch={handleSearch}
            style={{ width: `100%` }}
            onChange={(e) => handleSearchChange(e.target.value)}
            value={search}
            name="name"
          />
        </Flex>
      )}
    </>
  );
};
SearchContent.defaultProps = {
  options: [],
  selectedItems: [],
  search: "",
  isCollapsable: false,
  handleChangeSearchBy: () => {},
  handleSearch: () => {},
  handleSelectAll: () => {},
  handleClearAll: () => {},
  handleSearchChange: () => {},
};

export default SearchContent;
