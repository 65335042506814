import { RightOutlined, } from "@ant-design/icons";
import UserService from "../../../services/UserService";

import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import { transformText } from "../../../utils/Common";
import { setMenuPortfolio } from "../../../utils/settingCommon";

import HomeSvg from "./../../../assets/header/home.svg";
import PortfolioSvg from "./../../../assets/header/Portfolio.svg";
import ScenarioSvg from "./../../../assets/header/Scenairo.svg";
import BusinessRreportSvg from "./../../../assets/header/reports.svg";
import MyReportsSvg from "./../../../assets/header/my-report.svg";
import SettingsSvg from "./../../../assets/header/setting.svg";

export const portfolioRoute = ({ moduleName }) => {

    // if( moduleName==="business_application")
    // {
    //   return "application-list";
    // }
    // if( moduleName==="business_capability")
    // {
    //   return "business-capability-master";
    // }
    // if( moduleName==="business_processes")
    // {
    //   return "business-process";
    // }
    // if( moduleName=="initiative")
    // {
    //   return "portfolio-initiative"
    // }
    // if( moduleName==="integration")
    // {
    //   return "portfolio-integration"
    // }else{
    //   return "portfolio";
    // }
    return "portfolio";
}

const getPortfolioMenuList = async () => {
  let page = 1;
  let limit = 100;
  let finalMenu = [];
  let menuList = {};
  let SideMenuItems2 = [];

    await PortfolioSettingServices.getList({ page, limit }).then((result) => {
        menuList = result;
        setMenuPortfolio(menuList?.data?.data);
        menuList?.data?.data?.sort((a, b) => a.position - b.position).map((item) => {

            let mm = {
                //key: item.moduleName,
                key: portfolioRoute({ moduleName: item.moduleName }) + '/' + transformText(item.moduleName.replace("_", " "), "pascal"),
                // label: item.name,
                label: <span><span className="navportfolio_indicatecolor" style={{ background: item.color }}></span>{item?.name}</span>,
                icon: <RightOutlined />,
                moduleName: transformText(item.moduleName.replace("_", " "), "pascal"),
                moduleID: item.id,
                color: item.color,
                style: { borderColor: item.color, color: item.color },
                // onmouseover:{color:item.color},
                className: 'left-sidebar-portfolio-submenu'
            };
            finalMenu.push(mm);
        });

        SideMenuItems2 = [
            {
                key: "",
                label: `Home`,
                icon: <img src={HomeSvg} alt={`Home`} className="side-menu-icon" style={{maxWidth:15}}/>,
                className:"home-link-intro",
            },
            {
                key: "portfolio",
                label: `Portfolios`,
                icon: <img src={PortfolioSvg} alt={`Portfolios`} className="side-menu-icon" style={{maxWidth:15}}/>,
                children: finalMenu,
                className: "home-link-portfolio-intro",
            },
            // {
            //     key: "landscape-scenario",
            //     navigate: false,
            //     label: `Landscape`,
            //     icon: (
            //         <img
            //             src={ScenarioSvg}
            //             alt={`Scenarios`}
            //             className="side-menu-icon"
            //         />
            //     ),
            //     children: [
            //         {
            //             key: "add-new-scenario",
            //             navigate: false,
            //             label: `New`,
            //             icon: <RightOutlined />,
            //         },
            //         {
            //             key: "scenario-list",
            //             navigate: false,
            //             label: `List`,
            //             icon: <RightOutlined />,
            //         },
            //         {
            //             key: "compare-scenario",
            //             navigate: false,
            //             label: `Compare`,
            //             icon: <RightOutlined />,
            //         },
            //     ],
            //   },
            {
                key: "list-scenario",
                navigate: false,
                label: `Scenarios`,
                className: "home-link-scenario-intro",
                icon: <img src={ScenarioSvg} alt={`Scenarios`} className="side-menu-icon" style={{maxWidth:15}}/>,
                // children: [
                //     {
                //         key: "start-new-scenario",
                //         navigate: false,
                //         label: `New`,
                //         icon: <RightOutlined />,
                //     },
                //     {
                //         key: "list-scenario",
                //         navigate: false,
                //         label: `List`,
                //         icon: <RightOutlined />,
                //     },
                //     {
                //         key: "compare-scenario-v2",
                //         navigate: false,
                //         label: `Compare`,
                //         icon: <RightOutlined />,
                //     },
                // ],
            },
            {
                key: "reports",
                navigate: false,
                label: `Reports`,
                className: "home-link-reports-intro",
                icon: <img src={BusinessRreportSvg} alt={`Reports`} className="side-menu-icon" style={{maxWidth:15}}/>,
                children: [
                    {
                        key: "reports/application-landscape",
                        navigate: false,
                        label: `Application Portfolio Report`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/business-capability-landscape",
                        navigate: false,
                        label: `Business Capability Portfolio Report`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/business-processes-landscape",
                        navigate: false,
                        label: `Business Process Portfolio Report`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/integration-landscape",
                        navigate: false,
                        label: `Application Integration Landscape`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/technology-landscape",
                        navigate: false,
                        label: `Technology Portfolio Report`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/objectiveokr-landscape",
                        navigate: false,
                        label: `OKRs Realization`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/data-object-landscape",
                        navigate: false,
                        label: `Data Object Report`,
                        icon: <RightOutlined />,
                    },
                    // {
                    //     key: "reports/business-capabilty-landscape",
                    //     navigate: false,
                    //     label: `Business Capability  Portfolio Report`,
                    //     icon: <RightOutlined />,
                    // },
                    // {
                    //     key: "reports/capabilty-report",
                    //     navigate: false,
                    //     label: `Capability Solution `,
                    //     icon: <RightOutlined />,
                    // },
                    {
                        key: "reports/data-completeness-report",
                        navigate: false,
                        label: `Data Quality `,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/global-relation-landscape",
                        navigate: false,
                        label: `Global Relationship `,
                        icon: <RightOutlined />,
                    },
					{
                        key: "portfolio/Technology?techradar=1",
                        navigate: false,
                        label: `Tech Radar `,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "reports/user-activity",
                        navigate: false,
                        label: `User Activity `,
                        icon: <RightOutlined />,
                    },
                ],
            },
            {
                key: "personal",
                navigate: false,
                label: <>My Reports </>, //<mark className="sidemenu-new-blink">new</mark>
                icon: <img src={MyReportsSvg} alt={`My Reports`} className="side-menu-icon" style={{maxWidth:15}}/>,
                children: [
                    {
                        key: "personal/share-with-me",
                        navigate: false,
                        label: `Shared with Me`,
                        icon: <RightOutlined />,
                    },
                    {
                        key: "personal/my-reports",
                        navigate: false,
                        label: `Personal`,
                        icon: <RightOutlined />,
                    },
                ],
            },
            {
                key: "others-settings",
                navigate: false,
                label: `Settings`,
                className: "home-link-settings-intro",
                icon: <img src={SettingsSvg} alt={`Settings`} className="side-menu-icon" style={{maxWidth:15}}/>,
            },
            // {
            //     key: "others",
            //     navigate: false,
            //     label: `Others`,
            //     icon: <img src={OthersSvg} alt={`Others`} className="side-menu-icon" />,
            //     children: [
            //         // {
            //         //     key: "landscape-scenario",
            //         //     navigate: false,
            //         //     label: `Landscape`,
            //         //     icon: (
            //         //         <img
            //         //             src={ScenarioSvg}
            //         //             alt={`Scenarios`}
            //         //             className="side-menu-icon"
            //         //         />
            //         //     ),
            //         //     children: [
            //         //         {
            //         //             key: "add-new-scenario",
            //         //             navigate: false,
            //         //             label: `New`,
            //         //             icon: <RightOutlined />,
            //         //         },
            //         //         {
            //         //             key: "scenario-list",
            //         //             navigate: false,
            //         //             label: `List`,
            //         //             icon: <RightOutlined />,
            //         //         },
            //         //         {
            //         //             key: "compare-scenario",
            //         //             navigate: false,
            //         //             label: `Compare`,
            //         //             icon: <RightOutlined />,
            //         //         },
            //         //     ],
            //         // },
            //         // {
            //         //     key: "others-settings",
            //         //     navigate: false,
            //         //     label: `Settings`,
            //         //     icon: <RightOutlined />,
            //         // },
            //         {
            //             key: "others-surveys",
            //             navigate: false,
            //             label: `Surveys`,
            //             icon: <RightOutlined />,
            //         },
            //         {
            //             key: "others-help",
            //             navigate: false,
            //             label: `Help`,
            //             icon: <RightOutlined />,
            //         },
            //     ],
            // },
        ];
    });

  return SideMenuItems2;
};

const SideMenu = async () => {

    const SideMenuItems2 = await getPortfolioMenuList();

    if (!UserService.hasRole(["Admin"])) {
        SideMenuItems2.forEach(async (item, index, object) => {
            if (item.key === "data-import") {
                object.splice(index, 1);
            } else {
                if (item?.children) {
                    item?.children.forEach((item1, index1, object1) => {
                        if (item1.key === "start-new-scenario") {
                            object1.splice(index1, 1);
                        }
                    });
                }


                if (item?.key === "others") {
                    await item?.children.forEach(async (item1, index1, object1) => {
                        if (item1.key === "others-surveys") {
                            await object1.splice(index1, 1);
                        } else if (item1.key === "others-settings") {
                            await object1.splice(index1, 1);
                        } if (item1.key === "others-help") {
                            await object1.splice(index1, 1);
                        } else if (item1.key === "landscape-scenario") {
                            item1?.children.forEach(async (item2, index2, object2) => {
                                if (item2.key === "add-new-scenario") {
                                    await object2.splice(index2, 1);
                                }
                            });
                        }
                    });
                }
            }
        });
    }
    return SideMenuItems2;
};

export default SideMenu;
