import React, { useEffect, useState } from 'react';
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import { useNavigate } from 'react-router';


const LayoutSteps = () => {
    const navigate = useNavigate();
    const [stepEnabled, setStepEnabled] = useState(false);

    useEffect(()=>{
        if(sessionStorage?.getItem('step-intro-enabled')){
            setStepEnabled(false);
            sessionStorage.setItem("step-intro-enabled",false)
        }else{
            setStepEnabled(true);
            sessionStorage.setItem("step-intro-enabled",true)
        }
    },[])

    const onExit = () => {
        setStepEnabled(false);
        sessionStorage.setItem("step-intro-enabled",false)
    };

    const options = {
        showProgress: true,
        showBullets: true,
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: 'Next',
        prevLabel: 'Prev',
        // skipLabel: 'Skip',
        hidePrev: true,
        doneLabel: 'Done',
        overlayOpacity: 0.5,
        overlayColor: '#000',
        showStepNumbers: true,
        keyboardNavigation: true,
        scrollToElement: true,
        helperElementPadding: 10,
        showButtons: true
    };

    return (
        <>
            <Steps
                // @ts-ignore
                enabled={stepEnabled}
                steps={hints}
                initialStep={0}
                onExit={onExit}
                options={options}
                onChange={(nextStepIndex, nextElement)=>{
                    let hint = hints[nextStepIndex];
                    if(hint?.link){
                        navigate(hint?.link)
                    }else if(nextStepIndex===0){
                        navigate(hint?.link)
                    }
                }}
            />
        </>
  )
}

export default LayoutSteps;

const hints = [
    {
        element: '.intro-welcome-text',
        intro: "Welcome to Connected Architecture",
    },
    {
        element: '.homePortfolioSummary',
        intro: 'Portfolio Summary',
    },
    {
        element: '.home-notifications-intro',
        intro: 'Notifications',
    },
    {
        element: '.home-my-reports-intro',
        intro: 'My Reports',
    },
    {
        element: '.home-application-status-summary-intro',
        intro: 'Application Status Summary',
    },
    {
        element: '.home-scenarios-status-summary-intro',
        intro: 'Scenarios Status Summary',
    },
    {
        element: '.home-quick-links-intro',
        intro: 'Quick Links',
    },
    {
        element: '.home-user-guide-library-intro',
        intro: 'User Guide Library',
    },
    {
        element: '.home-recently-viewed-intro',
        intro: 'Recently Viewed',
    },
    {
        element: '.home-link-intro',
        intro: 'Home',
        type: 'menu',
    },
    {
        element: '.home-link-portfolio-intro',
        intro: 'Portfolio',
        type: 'menu',
    },
    {
        element: '.home-link-scenario-intro',
        intro: 'Scenarios',
        type: 'menu',
    },
    {
        element: '.home-link-reports-intro',
        intro: 'Reports',
        type: 'menu',
    },
    {
        element: '.home-link-settings-intro',
        intro: 'Settings',
        type: 'menu',
    },
    {
        element: '.home-link-application-intro',
        intro: 'Portfolio > Application',
        type: 'portfolio',
        link: '/portfolio/BusinessApplication'
    },
    {
        element: '.home-link-scenario-intro',
        intro: 'Scenario > Scenario List',
        link: '/list-scenario'
    },
    {
        element: '.home-link-settings-intro',
        intro: 'Settings > Settings',
        link: '/others-settings'
    },
];
