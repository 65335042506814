import { Card, Col, Flex, Row, Typography, Modal, message } from 'antd';
import manufacturingJpg from "../assets/industry/manufacturing.jpg";
import logisticsSupplyChainJpg from "../assets/industry/logistics-supply-chain.jpg";
import renewableEnergyJpg from "../assets/industry/renewable-energy.jpg";
import pharmaceuticalsBiotechnologyJpg from "../assets/industry/pharmaceuticals-biotechnology.jpg";
import maritimeIndustryJpg from "../assets/industry/maritime-industry.jpg";
import fishingAquacultureJpg from "../assets/industry/fishing-aquaculture.jpg";
import technologyTelecommunicationsJpg from "../assets/industry/technology-telecommunications.jpg";
import oilGasJpg from "../assets/industry/oil-gas.jpg";
import retailEcommerceJpg from "../assets/industry/retail-ecommerce.jpg";
import educationJpg from "../assets/industry/education.jpg";
import healthcareJpg from "../assets/industry/healthcare.webp";
import bankingJpg from "../assets/industry/banking.jpg";
import constructionJpg from "../assets/industry/construction.avif";
import industryService from "../services/services/IndustryTypeServices";
import { ExclamationCircleFilled } from '@ant-design/icons';

import React, { useState } from 'react';
import { useNavigate } from 'react-router';

let options = [
    {label:"Manufacturing",value:"manufacturing",image:manufacturingJpg},
    {label:"Logistics & Supply Chain",value:"logistics",image:logisticsSupplyChainJpg},
    {label:"Energy",value:"energy",image:renewableEnergyJpg},
    {label:"Pharmaceuticals and Biotechnology",value:"pharmaceuticals",image:pharmaceuticalsBiotechnologyJpg},
    {label:"Maritime Industry",value:"maritime",image:maritimeIndustryJpg},
    // {label:"Fishing and Aquaculture",value:"fishing-aquaculture",image:fishingAquacultureJpg},
    {label:"Technology and Telecommunications",value:"telecom",image:technologyTelecommunicationsJpg},
    {label:"Oil & Gas",value:"oil",image:oilGasJpg},
    {label:"Retail & Ecommerce",value:"retail",image:retailEcommerceJpg},
    {label:"Education",value:"education",image:educationJpg},
    {label:"Healthcare",value:"healthcare",image:healthcareJpg},
    {label:"Banking",value:"banking",image:bankingJpg},
    {label:"Construction",value:"construction",image:constructionJpg},
]

const IndustryTypePage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Function to handle industry selection
    const handleIndustrySelect = (industry) => {
        // Show confirmation modal
        Modal.confirm({
            title: 'Are you sure you want to switch the industry?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    setLoading(true);
                    const data = { industry };
                    const response = await industryService.selectedIndustry(data);
                    
                    if (response?.data?.success) {
                        localStorage.removeItem("recent_viewed_portfolio");
						if(response?.data?.message === 'This industry is already selected. Please select another one!') {
							message.info(response?.data?.message);
						} else {
							message.success(response?.data?.message);
							setTimeout(() => {
								navigate('/');
							}, 500);
						}
                    } else {
                        message.error('Failed to connect to the database. Please try again.');
                    }
                } catch (error) {
                    console.error("Error connecting to the database:", error);
                    message.error('An error occurred. Please try again.');
                } finally {
                    setLoading(false);
                }
            },
            onCancel() {
                console.log('Industry selection cancelled');
            },
        });
    };

    return (
        <React.Fragment>
            <div className="container text-center">
                <Flex justify="center" align="center" className="p-2 ant-flex-wrap-wrap" gap={8} style={{ minHeight: window?.innerHeight - 50 }}>
                    <Row gutter={[(window?.innerWidth > 1000 ? 24 : 16), { xs: 8, sm: 16, md: 24, lg: 24 }]} justify="center">
                        <Col span={24}><h1 className="text-dark fs-2">Select Your Industry</h1></Col>
                        {options.map(({ label, value, image }) => {
                            return (
                                <React.Fragment key={value}>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                                        <Card
                                            hoverable
                                            size="small"
                                            style={{ backgroundImage: `url(${image})`, backgroundSize: `cover` }}
                                            onClick={() => handleIndustrySelect(value)}
                                        >
                                            <Flex style={{ height: 150 }} align="center" justify="center">
                                                <Typography.Text
                                                    ellipsis={false}
                                                    title={label}
                                                    className="ant-card-meta-title p-2 rounded"
                                                    style={{ whiteSpace: `normal`, backgroundColor: `#ffffffdb` }}
                                                >
                                                    {label}
                                                </Typography.Text>
                                            </Flex>
                                        </Card>
                                    </Col>
                                </React.Fragment>
                            );
                        })}
                    </Row>
                </Flex>
            </div>
        </React.Fragment>
    );
};

export default IndustryTypePage;
