import { DrawIoEmbed } from 'react-drawio';
import './Diagram.scss';
import { useEffect, useState, useRef  } from 'react';
import { Spin, message } from 'antd';
import diagramServices from '../../services/services/diagramServices';
import ScenarionServices from '../../services/services/ScenarioServices';
import { PageTitleHeading, pascalToSnake } from '../../utils/Common';
import { useLocation, useNavigate } from 'react-router';
import { GoBack } from '../../utils/GoBackHistory';
import pako from 'pako';

const DrawioLayout = ({diagramId, refreshTab, setrefreshTab}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [updateDiagram,setUpdateDiagram] = useState(false);
  const [diagramData,setDiagramData] = useState({});
  const [xml,setXml] = useState("");
  const [svg,setSvg] = useState("");
  const [itemname,setItemname] = useState("");
  const [previousPath,setPreviousPath] = useState("");
  const [moduleData,setModuleData] = useState({}); 
  const [shown,setshown] = useState(true);
  const svgRef = useRef("");

  useEffect(() => {
    if (!loading) {
        const timeout = setTimeout(() => {
            setshown(false);
            window.scrollTo(0, 0);
        }, 500);

        // Clean up the timeout
        return () => clearTimeout(timeout);
    }
}, [loading]);


  useEffect(() => {
    if(diagramId){
      getDiagram();
      if(location?.state?.previousPath){
        sessionStorage.setItem("drawioPreviousPath", location?.state?.previousPath || "");
        sessionStorage.setItem("drawioPreviousModuleData",JSON.stringify( location?.state) || {});
        setPreviousPath(location?.state?.previousPath);
        setModuleData(location?.state)
      }else{
        setPreviousPath(sessionStorage.getItem("drawioPreviousPath"));
        setModuleData(JSON.parse(sessionStorage.getItem("drawioPreviousModuleData")))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[diagramId])

  useEffect(() => {
    svgRef.current = svg;
  }, [svg]);

  useEffect(() => {
    if(updateDiagram){
      let obj = {
        "id": diagramId,
        "name": diagramData?.name || "",
        "description": diagramData?.description || "",
        "moduleName": diagramData?.moduleName || "",
        "moduleID":diagramData?.moduleID || "",
        "xml": xml,
        "svg": svg,
        "item_names": itemname,
      };
      saveDigramData(obj)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateDiagram])

  const getDiagram = async () => {
    try {
      setLoading(true);
      const response = await diagramServices.getDiagram(diagramId);
      setDiagramData(response?.data || {})
      setTimeout(() => {
        setLoading(false);     
           
    }, 2000);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
 
  const saveDigramData =async (obj) => {
    try {
      setLoading(true);
      if (obj && typeof obj === 'object') {
        obj.UserId = localStorage?.email;
      }
      const response = await diagramServices.updatediagram(obj);
      const newData = response?.data || null;

      let modifiedModuleName = obj?.moduleName;
      if (modifiedModuleName === 'BusinessProcess') {
			  modifiedModuleName = 'BusinessProcesses';
			}
			else if (modifiedModuleName === 'ApqcsProcess') {
			  modifiedModuleName = 'ApqcsProcesses';
			}
      const objdata = {
        source_id: obj?.moduleID,
        modulename: pascalToSnake(modifiedModuleName),
        tab_name: "Diagram",
      };
      await ScenarionServices.checkscenariomodifyrecord(objdata);

      // Handle success response
      message.success("Updated successfully");
      setTimeout(() => {
          setLoading(false);
          setUpdateDiagram(false)
          getDiagram();
          setrefreshTab(!refreshTab)
      }, 1000);
    } catch (error) {
        console.log("catcheEror",error)
        setLoading(false);
        setUpdateDiagram(false)
    }
  }

  const handleExport = async (data) => {
    const xmlContent = data?.xml || '';
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
    const diagramElement = xmlDoc.getElementsByTagName("diagram")[0];
    if (!diagramElement) {
      console.error('No <diagram> element found in XML.');
      return;
    }

    const diagramContent = diagramElement.textContent;
		const binaryData = base64ToUint8Array(diagramContent);

    try {
      const decompressedData = pako.inflateRaw(binaryData, { to: 'string' });
      const decompressedXmlDoc = parser.parseFromString(decodeURIComponent(decompressedData), "text/xml");

      const cells = decompressedXmlDoc.getElementsByTagName("mxCell");
      const displaynames = new Set();
      const process_names = [];
      for (let cell of cells) {
        const parentElementCheck = cell.parentNode;
        const styleCheck = cell.getAttribute("style");

        if(styleCheck)
        {
          const attributesCheck = parseStyleAttributes(styleCheck);
          let value = cell.getAttribute("value");
          if (parentElementCheck.nodeName === "UserObject") {
            value = parentElementCheck.getAttribute("label");
          }
          if(value) process_names.push(value);

          if ((attributesCheck.shape === "mxgraph.bpmn.task" || attributesCheck.shape === "mxgraph.bpmn.task2") && attributesCheck.bpmnShapeType !== "call") {
            if (value) {
              displaynames.add(value);
            }
          }
        }
      }

      if(process_names?.length > 0)
      {
        const combinedProcesses = process_names.join('||') || '';
        setItemname(combinedProcesses.replace(/<[^>]*>/g, ''));
      }
      
      if (displaynames.size === 0) {
        const serializer = new XMLSerializer();
        const modifiedXmlString = serializer.serializeToString(decompressedXmlDoc);
        setXml(modifiedXmlString);
        setUpdateDiagram(true);
        return;
      }

      const response = await diagramServices.getBusinessProcess([...displaynames]);
      const businessProcesses = response?.data?.data || [];

      const currentUrl = window.location.href;
      const urlObject = new URL(currentUrl);
      const baseUrl = `${urlObject.protocol}//${urlObject.host}`;
      const displaynameToLink = {};
      const linkedCellIds = {};
      if(businessProcesses != '')
      {
        for (const process of businessProcesses) {
          displaynameToLink[process.displayname.toLowerCase()] = `${baseUrl}/portfolio/BusinessProcesses/${process.id}`;
        }
      }

      //XML Modified
      for (let cell of cells) {
        const parentElement = cell.parentNode;
        let value = cell.getAttribute("value");
        let id = cell.getAttribute("id");
        const style = cell.getAttribute("style");
        if (parentElement.nodeName === "UserObject") {
          id = parentElement.getAttribute("id");
          value = parentElement.getAttribute("label");
        }

        if (style) {
          const attributes = parseStyleAttributes(style);
          if ((attributes.shape === "mxgraph.bpmn.task" || attributes.shape === "mxgraph.bpmn.task2") && attributes.bpmnShapeType !== "call") {
            const link = displaynameToLink[value.toLowerCase()];
            if (link) {
              if (parentElement.nodeName === "UserObject") {
                  parentElement.setAttribute("link", link);
                  parentElement.setAttribute("linkTarget", "_blank");
                  linkedCellIds[id] = link;
              } else {
                  const userObject = decompressedXmlDoc.createElement("UserObject");
                  userObject.setAttribute("label", value);
                  userObject.setAttribute("link", link);
                  userObject.setAttribute("id", id);
                  userObject.setAttribute("linkTarget", "_blank");

                  cell.removeAttribute("value");
                  cell.removeAttribute("id");
    
                  const parent = cell.parentNode;
                  parent.replaceChild(userObject, cell);
                  userObject.appendChild(cell);
                  linkedCellIds[id] = link;
              }
            }
          }
        }
      }
      const serializer = new XMLSerializer();
      const modifiedXmlString = serializer.serializeToString(decompressedXmlDoc);
      setXml(modifiedXmlString);
      
      //SVG Modified
      const svgData = svgRef.current;
      const svgParser = new DOMParser();
      const svgDoc = svgParser.parseFromString(atob(svgData.split(',')[1]), "image/svg+xml");

      const gElements = svgDoc.querySelectorAll('g[data-cell-id]');
      gElements.forEach(gElement => {
          const cellId = gElement.getAttribute('data-cell-id');
          const link = linkedCellIds[cellId];

          if (link) {
            const anchor = svgDoc.createElementNS('http://www.w3.org/2000/svg', 'a');
            anchor.setAttribute('xlink:href', link);
            anchor.setAttribute('target', '_blank');
            const newGElement = svgDoc.createElementNS('http://www.w3.org/2000/svg', 'g');
            while (gElement.firstChild) {
                newGElement.appendChild(gElement.firstChild);
            }
            anchor.appendChild(newGElement);
            gElement.parentNode.replaceChild(anchor, gElement);
          }
          
      });
      const serializerSvg = new XMLSerializer();
      const modifiedSvgString = serializerSvg.serializeToString(svgDoc);
      setSvg(`data:image/svg+xml;base64,${btoa(modifiedSvgString)}`);

      setUpdateDiagram(true);
    } catch (error) {
      console.error("Error during decompression:", error);
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const parseStyleAttributes = (style) => {
    const attributes = {};
    const pairs = style.split(';');
    for (let pair of pairs) {
      const [key, val] = pair.split('=');
      if (key && val !== undefined) {
        attributes[key.trim()] = val.trim();
      }
    }
    return attributes;
  };

  const getTitleData = () =>{
    let mName= "";
    if(diagramData?.moduleName==="BusinessApplication"){
      mName = "Application";
    }else{
      mName = diagramData?.moduleName?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }


    return <div>
        <div className='mt-3 mb-2 h2'>{mName}: {moduleData?.titleName}</div>
        <div className='h4'>Diagram: {diagramData?.name}</div>
    </div>
  }

 

  
  return (
    <>
      {shown && <div style={{position:'absolute',zIndex:1, height:'130vh',width:window?.innerWidth-300,backgroundColor:'white'}}></div>}
      <Spin className="loading_bx" size="small" spinning={loading}>
        { 
          diagramData?.id && 
          <div className='d-flex justify-content-between align-items-center'>
            {getTitleData()}
            <div className="mt-3 mb-0">
              <GoBack path={previousPath || -1} state={{activeTabKey:'tab_diagram'}} />
            </div>
          </div>
        }
        <DrawIoEmbed 
          className="mt-0"
          xml={diagramData?.xml || ""}
          urlParameters={{
            ui: 'kennedy',
            spin: true,
            libraries: true,
            saveAndExit: false,
            noSaveBtn: false,
            noExitBtn: true,
          }}
          onSave={ (data) => { setSvg(data?.xml || null);} } 
          onExport={(data) => {
            handleExport(data);
            //console.log(data);
            //setXml(data?.xml || null); 
            //setUpdateDiagram(true)
          }}
        />
      </Spin>
      </>
  );
}

export default DrawioLayout;