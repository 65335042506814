import { InfoCircleOutlined } from "@ant-design/icons";
import { Flex, Popover } from "antd";
import React from "react";

const ReportsInfo = ({moduleName="",info="",heading=""}) => {
    const caseInfo = (moduleName || info);

    switch (caseInfo) {
        case "BusinessCapability": return <BusinessCapabilityInfo/>;
        case "BusinessApplication": return <BusinessApplicationInfo/>;
        case "Technology": return <TechnologyInfo/>;
        case "BusinessProcesses": return <BusinessProcessesInfo/>;
        case "Integration": return <IntegrationInfo/>;
        case "ObjectiveOkr": return <ObjectiveOkrInfo/>;
        case "Dataobject": return <DataObjectInfo/>;
        case "DataCompletenessReport": return <DataCompletenessReport/>;
        case "GlobalRelationLandscape": return <GlobalRelationLandscapeReport/>;
        case "TechRadar": return <TechRadarReport/>;
        case "ActivityLog": return <ActivityLogReport/>;
        default: return <>
            <Flex gap={8} vertical>
                <h4 className="text-dark pb-0 fs-5">Welcome to the {heading}!</h4>
            </Flex>
        </>
    }
}
export default ReportsInfo;

export const HeadingInfo = ({heading="",moduleName="",info=""}) => {
    
    const content = <div style={{maxWidth:window?.innerWidth/2,maxHeight:window?.innerHeight/2+100,minHeight:50,overflowY:'auto'}}>
        <ReportsInfo moduleName={moduleName} info={info} heading={heading}/>
    </div>
  
    return <>
        {heading}&nbsp;
        <Popover placement="bottomLeft" content={content} >
            <InfoCircleOutlined className="pointer" />
        </Popover>
        
    </>
}

export const BusinessCapabilityInfo = ()=>{
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Business Capability Portfolio Report!</h4>
        <p  className="text-dark pb-0">This report helps you gain valuable insights into business capability realization within your organization. Explore and answer critical questions like:</p>
        <ul style={{all:'revert'}} className="mt-1">
            <li><span className="text-dark h4">Organizational Overview:</span> What are the core business capabilities of my organization?</li>
            <li><span className="text-dark h4">Strategic Alignment:</span> Which business capabilities are required or impacted to achieve strategic objectives?</li>
            <li><span className="text-dark h4">Data and Information Needs:</span> What data or information is essential to execute a business capability effectively?</li>
            <li><span className="text-dark h4">Departmental Ownership:</span> Which departments or teams are responsible for managing specific business capabilities?</li>
            <li><span className="text-dark h4">Processes and Workflows:</span> What processes are in place to support and perform business capabilities?</li>
            <li><span className="text-dark h4">Initiatives and Impact:</span> Which initiatives are driving changes or impacting specific business capabilities?</li>
            <li><span className="text-dark h4">Interconnectivity:</span> How are different business capabilities interconnected?</li>
            <li><span className="text-dark h4">Capability Maturity:</span> What is the current maturity level of critical business capabilities? How prepared are we to realize these capabilities?</li>
            <li><span className="text-dark h4">Investment and Improvement Areas:</span> What areas of the organization require investment or improvement to strengthen capabilities?</li>
            <li><span className="text-dark h4">Technical Support:</span> What technical capabilities are critical for efficient business capability realization?</li>
            <li><span className="text-dark h4">Cost Insights:</span> What are the most expensive business capabilities, and how can they be optimized?</li>
            <li className="text-dark h4">What is the complexity of my business capability realization?</li>
        </ul>
    </Flex>
}

export const BusinessApplicationInfo = ()=>{
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Application Portfolio Report!</h4>
        <p className="text-dark">Gain valuable insights into the realization and management of your organization's applications. Use this report to explore and answer critical questions, ensuring effective decision-making and strategic alignment.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Application Lifecycle Management</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">Who owns the critical systems in the organization?</li>
                    <li className="text-dark">What applications are being retired or implemented?</li>
                    <li className="text-dark">Are there plans and roadmaps for replacements or migrations? (Scenario Planning)</li>
                    <li className="text-dark">How does my application landscape evolve over time? (Scenario Planning)</li>
                    <li className="text-dark">Who are the primary person responsible for my set of applications.?</li>
                    <li className="text-dark">What technologies does my application use?</li>
                    <li className="text-dark">Which applications are associated with specific business capabilities?</li>
                    <li className="text-dark">Identify redundant applications that can be consolidated?</li>
                </ul>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Application Hosting</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What infrastructure supports my application?</li>
                    <li className="text-dark">Where are my applications hosted?</li>
                    <li className="text-dark">Footprint of on-premise vs. cloud infrastructure services.</li>
                </ul>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Application Integration Management</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What is the impact of changes to the application landscape?</li>
                    <li className="text-dark">What data flows through systems?</li>
                    <li className="text-dark">What integration technologies are used across the organization?</li>
                </ul>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Application Rationalization</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What overlapping functions and applications exist in the landscape?</li>
                    <li className="text-dark">What is the impact of consolidating overlapping applications?(Scenario Planning)</li>
                    <li className="text-dark">Assess and compare options for improving application efficiency and cost-effectiveness.(Scenario Planning)</li>
                </ul>
            </div>
        </Flex>
    </Flex>
}

export const TechnologyInfo = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Technology  Portfolio Report!</h4>
        <p className="text-dark">Discover insights into your organization's Technology Portfolio Report, enabling strategic decision-making and effective management of resources.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Technology Realization:</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What technologies are associated with an initiative?</li>
                    <li className="text-dark">What technologies are linked to a business capability?</li>
                    <li className="text-dark">Which technologies are connected to an application?</li>
                </ul>
            </div>
            <div>
                <div className="h4 text-dark pb-0">Infrastructure Technology Lifecycle Management:</div>
                <ul style={{all:'revert'}}>
                    
                    <li className="text-dark">Are there plans for retirement or migrations?(Scenario Planning)</li>
                    <li className="text-dark">How will the infrastructure landscape evolve over time?(Scenario Planning)</li>
                    <li className="text-dark">Where are technology components located?</li>
                </ul>
            </div>
        </Flex>
    </Flex>
}

export const BusinessProcessesInfo = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Business Process Portfolio Report!</h4>
        <p className="text-dark">Discover insights into your organization's Business Process Portfolio Report, enabling seamless operations, process optimization, and strategic alignment with organizational goals.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What are the key business processes in the organization?</li>
                    <li className="text-dark">Which processes are critical for achieving strategic objectives?</li>
                    <li className="text-dark">Are there any processes that overlap and can be streamlined?</li>
                    <li className="text-dark">What is the impact of changing my business process?</li>
                    <li className="text-dark">Which teams or departments are responsible for specific processes?</li>
                    <li className="text-dark">How are business processes interconnected?</li>
                    <li className="text-dark">What Information/Data Object/Applications are dependent on my business process?</li>
                </ul>
            </div>
        </Flex>
    </Flex>
}

export const IntegrationInfo = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Application Integration Landscape Report!</h4>
        <p className="text-dark">Gain a comprehensive understanding of your organization's Application Integration Landscape, ensuring seamless data flow, enhanced scalability, and reduced complexity in your IT ecosystem.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What systems and applications are integrated across the organization?</li>
                    <li className="text-dark">How do integrations support business capabilities?</li>
                    <li className="text-dark">What integration technologies and tools are used in the organization?</li>
                    <li className="text-dark">What integrations need to be updated or replaced?(Scenario Planning)</li>
                </ul>
            </div>
        </Flex>
    </Flex>
}

export const ObjectiveOkrInfo = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the OKRs Realization Report!</h4>
        <p className="text-dark">Discover insights into your organization's objectives and key results (OKRs), fostering strategic alignment, goal tracking, and measurable progress toward success.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What are the strategic objectives of the organization?</li>
                    <li className="text-dark">How are objectives aligned with business capabilities and processes?</li>
                    <li className="text-dark">What are the measurable outcomes for each objective?</li>
                    <li className="text-dark">Are there any risks to achieving the objectives?</li>
                    <li className="text-dark">How are OKRs interconnected across departments or teams?</li>
                    <li className="text-dark">Are there conflicts or overlaps in objectives and key results?</li>
                    <li className="text-dark">Which objectives require additional focus or resources?</li>
                    <li className="text-dark">What actions can improve the likelihood of achieving key results?</li>
                    <li className="text-dark">How are OKRs contributing to overall strategic goals?</li>
                </ul>
            </div>
        </Flex>
    
    </Flex>
}

export const DataObjectInfo = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Data Object Landscape Report!</h4>
        {/* <p className="text-dark">Discover insights into your organization's objectives and key results (OKRs), fostering strategic alignment, goal tracking, and measurable progress toward success.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">What are the strategic objectives of the organization?</li>
                    <li className="text-dark">How are objectives aligned with business capabilities and processes?</li>
                    <li className="text-dark">What are the measurable outcomes for each objective?</li>
                    <li className="text-dark">Are there any risks to achieving the objectives?</li>
                    <li className="text-dark">How are OKRs interconnected across departments or teams?</li>
                    <li className="text-dark">Are there conflicts or overlaps in objectives and key results?</li>
                    <li className="text-dark">Which objectives require additional focus or resources?</li>
                    <li className="text-dark">What actions can improve the likelihood of achieving key results?</li>
                    <li className="text-dark">How are OKRs contributing to overall strategic goals?</li>
                </ul>
            </div>
        </Flex> */}
    
    </Flex>
}

export const DataCompletenessReport = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Data Quality Report!</h4>
        <p className="text-dark">Gain insights into your organization's data integrity and completeness to ensure reliable decision-making and operational efficiency.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark"><span className="h4 text-dark">Data Completeness:</span> Measure the percentage of complete and incomplete records in your portfolio.</li>
                    <li className="text-dark"><span className="h4 text-dark">Data Integrity:</span> Identify inaccuracies, duplicates, and inconsistent records.</li>
                    <li className="text-dark"><span className="h4 text-dark">Portfolio Coverage:</span> Highlight areas affected by data gaps and assess their impact.</li>
                    <li className="text-dark"><span className="h4 text-dark">Compliance:</span> Detect violations of governance policies and regulatory standards.</li>
                    <li className="text-dark"><span className="h4 text-dark">Trends and Impact:</span> Track improvements in data quality and analyze its effect on business outcomes.</li>
                </ul>
            </div>
            <div>
                <div className="h3 text-dark pb-0">Features</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark"><span className="h4 text-dark">Interactive Dashboards:</span> Visualize completeness, integrity, and data gaps.</li>
                    <li className="text-dark"><span className="h4 text-dark">Search and Export:</span> Filter specific data and generate reports in multiple formats.</li>
                </ul>
            </div>
            <div>
                This report empowers users to identify issues, prioritize fixes, and maintain a high-quality data portfolio.
            </div>
        </Flex>
    
    </Flex>
}

export const GlobalRelationLandscapeReport = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Global Relationship Report!</h4>
        <p className="text-dark">Gain a clear, end-to-end visualization of your organization’s interconnected architecture. The Global Relationship Report provides insights into the relationships among various portfolio objects, such as organizations, processes, applications, information, technologies, and business capability. </p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">This report enables users to:</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark">Understand how different elements in the portfolio interact to achieve strategic goals.</li>
                    <li className="text-dark">Identify dependencies and opportunities for optimization across business capabilities.</li>
                    <li className="text-dark">Make informed decisions by leveraging a comprehensive view of interconnected systems and processes.</li>
                </ul>
            </div>
        </Flex>
        <p className="text-dark">Use this report to align resources, improve operational efficiency, and ensure the organization’s architecture supports its strategic objectives effectively.</p>
    </Flex>
}

export const TechRadarReport = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the Tech Radar Report!</h4>
        <div>
            <ul style={{all:'revert'}}>
                <li className="text-dark"><span className="h4 text-dark">Adopt: </span>Technologies that are mature, stable, and widely recommended for immediate implementation in your systems. These have proven their value and are ready for integration into your operations.</li>
                <li className="text-dark"><span className="h4 text-dark">Trial: </span>Technologies that are ready for use but may still have some uncertainties or limitations. They are worth experimenting with, but might not yet be as fully optimized or proven as the ones in the "Adopt" category.</li>
                <li className="text-dark"><span className="h4 text-dark">Assess: </span>Technologies that require further evaluation. These may show potential but are not yet ready for widespread use. You should consider testing them if they align well with your needs, but be cautious about full implementation.</li>
                <li className="text-dark"><span className="h4 text-dark">Hold: </span>Technologies that are still in early stages or have known issues. Proceed with caution as they may pose risks or not deliver the expected value.</li>
            </ul>
        </div>
        <p className="text-dark">Gain insights into your organization’s evolving Technology Portfolio Report. Leverage this report to drive innovation, evaluate trends, and align technology with strategic goals.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">Key Questions and Insights</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark"><span className="h4 text-dark">Lifecycle Management:</span> Retire outdated technologies and strengthen the roadmap with emerging tools.</li>
                    <li className="text-dark"><span className="h4 text-dark">Innovation:</span> Identify gaps and align with industry trends.</li>
                    <li className="text-dark">What technology components are being retired and implemented?</li>
                </ul>
            </div>
        </Flex>
    </Flex>
}

export const ActivityLogReport = () => {
    return <Flex gap={8} vertical>
        <h4 className="text-dark pb-0 fs-5">Welcome to the User Activity Log!</h4>
        <p className="text-dark">This report provides a comprehensive overview of user activities within a system, highlighting key interactions like data changes, who made them, and when. It tracks changes to critical data fields, displaying both old and new values for full transparency.</p>
        <Flex gap={8} vertical>
            <div>
                <div className="h3 text-dark pb-0">The key features include:</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark"><span className="h4 text-dark">Activity Tracking:</span> Monitoring data changes with audit details like "Modified By" and timestamps.</li>
                    <li className="text-dark"><span className="h4 text-dark">Custom Filters:</span> Options to filter by specific users or timeframes for focused analysis.</li>
                    <li className="text-dark"><span className="h4 text-dark">Export Options:</span> Customizable formats for easy sharing and further analysis.</li>
                </ul>
            </div>
            <div>
                <div className="h3 text-dark pb-0">The benefits are multi-fold:</div>
                <ul style={{all:'revert'}}>
                    <li className="text-dark"><span className="h4 text-dark">Behavior Analysis:</span> Helps identify patterns, successful users, and areas needing attention.</li>
                    <li className="text-dark"><span className="h4 text-dark">Enhanced Security:</span> Tracks sensitive data changes for security and compliance.</li>
                    <li className="text-dark"><span className="h4 text-dark">Operational Efficiency:</span> Spot areas for improvement and reduce bottlenecks.</li>
                </ul>
            </div>
            <div>
                This report helps businesses make informed decisions, improve performance, and ensure the integrity and security of data in the system.
            </div>
        </Flex>
    </Flex>
}