import {
  Button,
  Card,
  Flex,
  Form,
  Layout,
  message,
  Pagination,
  Popover,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import BusinessProcessHeader from "../businessProcess/BusinessProcessHeader";
import {
  getAllMenuPortfolio,
  getMenuPortfolio,
} from "../../utils/settingCommon";
import CardItems from "./business-capability/CardItems";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import FilterRightSideBar from "../applicationlist/filter/FilterRightSideBar";
import {
  debounceTimeOut,
  default_limit,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
} from "../../utils/Common";
import useDebounce from "../../helper/useDebounce";
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import Delayed from "../../utils/Delayed";
import RelationCardItems from "./global-relations/RelationCardItems";
import { HeadingInfo } from "./ReportsInfo";

const selectDefaultProps = {
  showSearch: true,
  style: { minWidth: "150px" },
  size: "medium",
  allowClear: true,
};
const lifeCycleStagesColors = {
  active: "#FFB381",
  phaseIn: "#FFF280",
  phaseOut: "#FC819E",
  endOfLife: "#B2B377",
  color5: "#B5C0D0",
  color6: "#BFEF82",
  color7: "#90DDF9",
};

const tempBusinessCapability2 = [
  {
    title: "Manufacturing",
    level: 1,
    childrens: [
      {
        title: "Hercules",
        level: 3,
      },
      {
        title: "ARO",
        level: 3,
      },
      {
        title: "BOP",
        level: 3,
      },
      {
        title: "Capacity Planning",
        level: 3,
      },
      {
        title: "Forecast Production Volumes",
        level: 3,
      },
    ],
  },
  {
    title: "Logistics",
    level: 1,
    childrens: [
      {
        title: "Inbound Logistics",
        level: 2,
      },
      {
        title: "In-Plant Logistics",
        level: 2,
      },
      {
        title: "Outbound Logistics",
        level: 2,
      },
      {
        title: "Customs",
        level: 2,
      },
    ],
  },
  {
    title: "Strategy and Planning",
    level: 1,
    childrens: [
      {
        title: "Strategy Development",
        level: 2,
      },
      {
        title: "Business Development",
        level: 2,
      },
    ],
  },
];

const GlobalRelationLandscape = ({ routeModuleName, ...props }) => {
  const loadingBarRef = useRef(null);
  const [filterForm] = Form.useForm();
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [portfolioOption, setPortfolioOption] = useState("");
  const [cardColor, setCardColor] = useState("");
  const [selectedPortfolioSections, setSelectedPortfolioSections] = useState(
    []
  );
  const [portfolioRelationOptions, setPortfolioRelationOption] = useState([]);
  const [reportFilter, setReportFilter] = useState({});

  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [filterSubmit, setFilterSubmit] = useState(false);
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
  const [swimLaneSelectedFilters, setSwimLaneSelectedFilters] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_limit);

  const [list, setList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [relationItems, setRelationItems] = useState([]);

  const [filterLoading, setFilterLoading] = useState(false);
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

  useEffect(() => {
    //API call
    if (filterSubmit) {
      const currentFilters = removeEmptyArrObjOrval(debouncedFilter);
      fetchData(currentFilters);
    }
  }, [filterSubmit, debouncedFilter, page, limit]);


  const addColorAndRelationName = (array) => {
    const data = array?.length > 0 ?  array?.sort((x,y)=> Object.keys(y.relations).length - Object.keys(x.relations).length): [];
    return data?.map((item) => {
      const updatedRelations = {};
      const allMenusItems = getAllMenuPortfolio();
      const selectedPortfolio = allMenusItems?.find((portfolio)=>portfolio?.moduleName === portfolioOption);
      const route = item?.id ? `${selectedPortfolio?.route}/${item?.id}` : "";
      

      Object.keys(item?.relations)?.forEach((relationName) => {
        updatedRelations[relationName] = item.relations[relationName].map(
          (relationItem) => {
            const findMenu = allMenusItems?.find((menu)=> menu?.routeModuleName === relationName);
            return {
              ...relationItem,
              color: findMenu?.color || "#FFB381",
              relationName: findMenu?.name || relationName,
              route: relationItem?.id ? `${findMenu?.route}/${relationItem?.id}` : ""
            }
          }
        );
      });

      return {
        ...item,
        route,
        relations: updatedRelations,
      };
    });
  };

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      setDisabled(true);
      // Call your API here
      const { portfolio, relations } = filterForm.getFieldsValue();
      if (portfolio && relations?.length > 0) {
        const allMenus = getAllMenuPortfolio();
        const tableName = allMenus?.find((item)=> item?.moduleName === portfolio)?.routeModuleName;
        let updatedFilter = {
          moduleName: portfolio || "",
          relations: relations,
          tableName,
          ...filter,
        };

        updatedFilter = removeEmptyKeys(updatedFilter);

        const response = await PortfolioSettingServices?.getAllRelationReportFilteredPortfolios(
          updatedFilter,
          page,
          limit
        );

        if (!response?.data?.data?.length) {
          message.warning("No records found.");
        }

        setTotalRecords(response?.data?.totalCount);
        setList(addColorAndRelationName(response?.data?.data) || []);
        
        setRelationItems(
          relations?.length > 0
            ? portfolioRelationOptions?.filter((relation) =>
                relations?.includes(relation.routeModuleName)
              )
            : []
        );

        const timer = setTimeout(() => {
          setFilterSubmit(false);
        }, 500);
        return () => clearTimeout(timer);
      } else {
        setFilterSubmit(false);
        return message.warning("Please select portfolio and relationship.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return message.error(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          "Error while fetching relations records."
      );
    } finally {
      setFilterSubmit(false);
      setLoading(false);
      setDisabled(false);
    }
  };

  const handleChangePortFolio = async (value) => {
    setFilterLoading(true);
    setPortfolioRelationOption([]);
    
    let portfolios = sessionStorage.getItem("menu-portfolios");
    portfolios = JSON.parse(portfolios);

    const selectedOption = getAllMenuPortfolio()?.find(
      (item) => item?.moduleName === value
    );
    await portfolioSettingDispatch({
      type: "SET_PORTFOLIO_SETTINGS",
      payload: portfolios,
    });

    if (!selectedOption) {
      setFilterLoading(false);
      setPage(1);
      setList([]);
      setTotalRecords(0);
      // return message.error("Portfolio not found.");
    }
    const selectedPortfolioProperties = getSelectProperties(
      selectedOption?.portfolioSections,
      true
    );

    setPortfolioOption(value);
    setCardColor(selectedOption?.color);
    setSelectedPortfolioSections(
      selectedOption ? selectedPortfolioProperties : []
    );
    filterForm.setFieldsValue({ relations: [] });
    let connectedLinks = selectedOption?.portfolioConnected || [];
    if (selectedOption?.moduleName === "integration") {
      const getApplication = getAllMenuPortfolio()?.find(
        (item) => item?.moduleName === "business_application"
      );
      connectedLinks = [...connectedLinks,getApplication ]
    }

    setPortfolioRelationOption(connectedLinks || []);

    // Update tag options
    if (selectedOption?.moduleName) {
      dispatchTag({ type: "EMPTY_TAGS" });
      dispatchTag({
        type: "SET_MODULE_NAME",
        payload: selectedOption?.moduleName,
      });
    }

    setSelectedFilters({});
    setSwimLaneSelectedFilters({});
    setSelectedRelationFilters({});
    setFilterSubmit(false);
    setPage(1);
    setTotalRecords(0)
    setList([])

    setTimeout(() => {
      setFilterLoading(false);
    }, 300);
  };

  const onFinish = async (formData) => {
    await fetchData({});
  };

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <Layout className="applicationlist-page container-fluid pr-0">
        <Layout.Content className="business-capability-landscape-report">
          <section>
            <BusinessProcessHeader
              activePage={0}
              setActivePage={() => {}}
              heading={<HeadingInfo heading={props?.heading} info="GlobalRelationLandscape"/>}
              matrix={false}
              grid={false}
              list={false}
              addBtn={false}
              bulk_delete={false}
              showActionBtn={false}
            />
          </section>
          <section>
            <Form
              form={filterForm}
              layout="inline"
              style={{ maxWidth: "none" }}
              className="mt-2"
              initialValues={{ portfolio: "", relations: [] }}
              onFinish={onFinish}
              requiredMark={false}
            >
              <Form.Item
                label={<>Portfolio <span className="text-danger">*</span></>}
                name="portfolio"
                rules={[{ required: true, message: "Please Select Portfolio" }]}
              >
                <Select
                  {...selectDefaultProps}
                  allowClear={true}
                  placeholder={"Select Portfolio"}
                  defaultValue={""}
                  onChange={handleChangePortFolio}
                  value={portfolioOption}
                  disabled={disabled}
                  options={getAllMenuPortfolio()?.map((item) => {
                    return {
                      label: item?.name,
                      value: item?.moduleName,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                label={<>Show Relationship <span className="text-danger">*</span></>}
                name="relations"
                rules={[{ required: true, message: "Please Select Relations" }]}
              >
                <Select
                  {...selectDefaultProps}
                  mode="tags"
                  placeholder={"Show Relationship"}
                  allowClear={true}
                  disabled={disabled}
                >
                  {portfolioRelationOptions?.map((item) => {
                    return (
                      <Select.Option value={item.routeModuleName} key={Math.random()}>
                        <span
                          className="portfolio-option-color"
                          style={{ "--color": item?.color }}
                        ></span>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit" className="px-4">
                Run
              </Button>
            </Form>
          </section>
          <section>
            {
              totalRecords > 0 && 
              <Spin spinning={loading} size="small" className="loading_bx">
                <Card className="mt-2">
                  <Flex gap={8} className="overflow-x pb-2">
                    <>
                      {(list?.length > 0 && <RelationCardItems items={list} extra={false} cardColor={cardColor} relationItems={relationItems}/>) || (
                        <Delayed waitBeforeShow={2000}>
                          <div
                            className={`text-base font-bold mt-4 ${
                              loading ? "d-none" : ""
                            }`}
                          >
                            No items found.
                          </div>
                        </Delayed>
                      )}
                    </>
                  </Flex>
                  {totalRecords > 0 && (
                    <Pagination
                      className="mt-2 text-right"
                      total={totalRecords}
                      showTotal={(total) => `Total ${total} items`}
                      defaultPageSize={default_limit}
                      defaultCurrent={1}
                      pageSizeOptions={default_pageSizeOptions}
                      showSizeChanger={false}
                      onChange={(p, ps) => {
                        setLimit(ps);
                        setPage(p);
                        setFilterSubmit(true);
                      }}
                    />
                  )}
                </Card>
              </Spin>
            }
          </section>
        </Layout.Content>
        <div className="right_pnlbx">
          <Spin
            className="loading_bx_absolute"
            spinning={filterLoading}
            size="small"
            fullscreen={false}
          >
            {portfolioOption && selectedPortfolioSections?.length > 0 && (
              <FilterRightSideBar
                activeGridStyle={1}
                lifeCycleStagesColors={lifeCycleStagesColors}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                portfolioData={selectedPortfolioSections}
                selectedFilters={selectedFilters}
                setSelectedFilters={(value) => {
                  setPage(1);
                  setSelectedFilters(value);
                }}
                optionalLevel={false}
                setSwimLaneSelectedFilters={setSwimLaneSelectedFilters}
                SidebarHeight={window?.innerHeight - 50}
                filterSubmit={filterSubmit}
                setFilterSubmit={(value) => {
                  setPage(1);
                  setFilterSubmit(value);
                }}
                selectedRelationFilters={selectedRelationFilters}
                setSelectedRelationFilters={setSelectedRelationFilters}
                showSortBy={false}
              />
            )}
          </Spin>
        </div>
      </Layout>
    </React.Fragment>
  );
};

GlobalRelationLandscape.defaultProps = {
  routeModuleName: "BusinessCapability",
};

export default GlobalRelationLandscape;
