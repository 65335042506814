import React, { useEffect, useState } from 'react'
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import PlantGrid from '../plant/PlantGrid';
import PlantList from '../plant/PlantList';
import {getMenuPortfolio} from '../../../utils/settingCommon'
import "./Resource.scss";
import { useLocation } from 'react-router';
import { ListSelectAllCheckboxComponent, showViewType } from '../common/PortfolioCommon';

const Resource = ({moduleName}) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(0);
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});   
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectAllChecked,setSelectAllChecked] = useState(false);

    // useEffect(()=>{
    //     setActivePage(location?.state?.defaultActiveKey || 1)
    // },[location]);
    
    useEffect(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1);
    },[location]);

    useEffect(()=>{
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        setLoading(true);
        setSelectAllChecked(false);
        setLoading(false);
    },[lists])

    const onCheckAllChange = (checked) => {
        setLoading(true);
        setTimeout(() => {
            if(checked){
                setBulkUpdateIds([...bulkUpdateIds,...lists?.map(v=>v.id)])
            }else{
                setBulkUpdateIds([])
            }
            setSelectAllChecked(checked);
            setLoading(false);
        }, lists?.length*5);
    }

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={(moduleData?.name || moduleName)}
        addBtnTooltip={`Add New`}
        grid={false}
        list={false}
        filterOptions={filterOptions}              
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
        Buttons={<ListSelectAllCheckboxComponent onCheckAllChange={onCheckAllChange} selectAllChecked={selectAllChecked}/>}
    />
    
    return (
        <div className='applicationlist-page'>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <PlantGrid 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <PlantList 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                    staticModel = {moduleData?.name}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}                                        
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                    bulkUpdateIds={bulkUpdateIds}
                    setBulkUpdateIds={setBulkUpdateIds}
                    setLists={setLists}
                    loading={loading}
                />
            }
        </div>
    )
}

export default Resource